import { Navigate, useLocation } from 'react-router-dom'
import { usePermissionValues } from '../../redux/selectors/content';
import { useEffect, useState } from 'react';

interface IProtectedRoute {
  isSignedIn: boolean
  children: JSX.Element
  havePermission?: boolean;
}

const ProtectedRoute = ({ isSignedIn, children, havePermission }: IProtectedRoute) => {

  const location = useLocation();
  const permissionValues: any = usePermissionValues();

  const [hasPermission, setHasPermission] = useState<boolean | null>(null);

  const url: any = location.pathname.split('/'); // Get the last part of the URL
  const base = url[1] || 'home' ;  // "past_tickets"

  const checkPermission = (url: any) => {    
    const formattedUrl = `view_${url}`;
    return permissionValues.includes(formattedUrl);
  };

  useEffect(() => {
    // Check permissions when the component mounts
    setHasPermission(checkPermission(base));
  }, [permissionValues, base]);
  
  if (hasPermission === null) {
    return null; // Optionally render a loading spinner or something similar
  }

  if (!isSignedIn) {
    const authUrl = `${process.env.REACT_APP_WIZR_B2C_AUTH_ENDPOINT}?p=${process.env.REACT_APP_WIZR_B2C_P}&client_id=${process.env.REACT_APP_WIZR_B2C_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_WIZR_B2C_REDIRECT_URI}&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login`
    // Redirect to Azure login page if not authenticated
    window.location.href = authUrl;
    return null;
  }

  if (!hasPermission) {
    // return <Navigate to='/error' replace />;
  }
  return children
}

export default ProtectedRoute
