const DownArrowColored = (): JSX.Element => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="16" height="16" rx="8" fill="url(#paint0_linear_9938_49413)"/>
        <path d="M4.6665 6.66797L7.99984 10.0013L11.3332 6.66797" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_9938_49413" x1="0.0106395" y1="8.0272" x2="31.2287" y2="19.9588" gradientUnits="userSpaceOnUse">
        <stop offset="0.0384067" stop-color="#02F5DB"/>
        <stop offset="0.4207" stop-color="#00BDFC"/>
        <stop offset="0.713" stop-color="#568FFE"/>
        <stop offset="0.9907" stop-color="#A36BFE"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

export default DownArrowColored