import React from 'react'

interface colour {
  stroke?: string
}

const SparkelsIcon = ({ stroke = '#999B9F' }: colour): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='22' viewBox='0 0 17 22' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.66659 16.3333C6.44909 13.4828 4.18383 11.2175 1.33325 11C4.18383 10.7825 6.44909 8.51723 6.66659 5.66666C6.88409 8.51723 9.14935 10.7825 11.9999 11C9.14935 11.2175 6.88409 13.4828 6.66659 16.3333V16.3333Z'
        stroke={stroke}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13.3332 20.3333C13.2241 18.9082 12.0917 17.7757 10.6665 17.6667C12.0917 17.5576 13.2241 16.4251 13.3332 15C13.4422 16.4251 14.5747 17.5576 15.9998 17.6667C14.5747 17.7757 13.4422 18.9082 13.3332 20.3333Z'
        stroke={stroke}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M13.3332 1.66666C13.2241 3.09182 12.0917 4.2243 10.6665 4.33332C12.0917 4.44235 13.2241 5.57483 13.3332 6.99999C13.4422 5.57483 14.5747 4.44235 15.9998 4.33332C14.5747 4.2243 13.4422 3.09182 13.3332 1.66666Z'
        stroke={stroke}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default SparkelsIcon
