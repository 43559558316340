import { Layout } from 'antd'
import { styled } from 'styled-components'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrHeadingText from '../WizrHeadingText'
import WizrText from '../WizrText'
import WizrIcon from '../WizrIcon'
import LogonHeader from '../../assets/icons/LogonHeader'
import { useLocation, useParams } from 'react-router-dom'

const { Header, Content, Footer } = Layout

const LoginFooter = styled(Footer)`
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4px;
  display: flex;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: white;
`

const LoginHeader = styled(Header)`
  font-family: Montserrat;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.25px;
  background: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 2;
`

const LoginContentArea = styled(Content)`
  flex: 5;
  display: flex;
`

const LoginLayout = ({ children }: { children: JSX.Element }): JSX.Element => {
  const location = useLocation()
  const isSharableApp = location?.pathname?.includes('appshare')
  const login = location?.pathname?.includes('/login')
  const errorPage = location?.pathname?.includes('./error')

  return (
    <WizrFlexSpacing direction='vertical' background='#FFF'>
      {!isSharableApp || !login || errorPage ? <LoginHeader >
        
      </LoginHeader>:<></>}
      <WizrFlexLayout flex={6} background='white'>

        <LoginContentArea>{children}</LoginContentArea>
      </WizrFlexLayout>
      {!isSharableApp || !login? <LoginFooter>
        <WizrText type='body3'>
          ©2023 Wizr.ai. All rights reserved. All trademarks acknowledged
        </WizrText>
      </LoginFooter>:<></>}
    </WizrFlexSpacing>
  )
}

export default LoginLayout
