const FileIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.308 4.00001H14.829C14.8863 4.00001 14.9433 4.00367 15 4.01101C17.2473 4.16817 18.9924 6.0332 19 8.28601V14.715C18.9917 17.0871 17.0641 19.0044 14.692 19H9.308C6.93551 19.0044 5.00772 17.0865 5 14.714V8.28601C5.00772 5.91353 6.93551 3.99558 9.308 4.00001Z'
        stroke='#1D1F22'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 9.03599C19.4142 9.03599 19.75 8.7002 19.75 8.28599C19.75 7.87177 19.4142 7.53599 19 7.53599V9.03599ZM15 8.28599H14.25C14.25 8.7002 14.5858 9.03599 15 9.03599V8.28599ZM15.75 4.01099C15.75 3.59677 15.4142 3.26099 15 3.26099C14.5858 3.26099 14.25 3.59677 14.25 4.01099H15.75ZM11 15.75C11.4142 15.75 11.75 15.4142 11.75 15C11.75 14.5858 11.4142 14.25 11 14.25V15.75ZM8 14.25C7.58579 14.25 7.25 14.5858 7.25 15C7.25 15.4142 7.58579 15.75 8 15.75V14.25ZM15 12.75C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25V12.75ZM8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75V11.25ZM19 7.53599H15V9.03599H19V7.53599ZM15.75 8.28599V4.01099H14.25V8.28599H15.75ZM11 14.25H8V15.75H11V14.25ZM15 11.25H8V12.75H15V11.25Z'
        fill='#1D1F22'
      />
    </svg>
  )
}

export default FileIcon
