import { Input, InputProps } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

const { TextArea } = Input

interface IWizrTextArea extends InputProps {
  placeholder?: string
  onChange?: any
  value?: any
  background?: string
  minRows?: number
  maxRows?: number
  border?: any
  onSelect?: any
}

const StyledTextArea = styled(TextArea)<any>`
  border-radius: 16px;
  resize: none !important;
  background: ${(p) => (p.background ? p.background : '#FFF')};
  border: ${(p) => (p.border ? p.border : '1px solid var(--grayscale-800, #1d1f22)')};
`

const WizrTextArea = ({
  placeholder = '',
  onChange,
  value = '',
  background,
  minRows = 5,
  maxRows = 8,
  border,
  onSelect,
  disabled
}: IWizrTextArea): JSX.Element => {
  const [valueData, setValueData] = useState<string>('')

  return (
    <StyledTextArea
      placeholder={placeholder}
      background={background}
      border={border}
      onChange={(ev: any) => {
        onChange(ev?.target?.value)
        setValueData(ev?.target?.value)
      }}
      rows={4}
      value={value || valueData}
      autoSize={{ minRows: minRows, maxRows: maxRows }}
      onSelect={onSelect}
      disabled={disabled}
    />
  )
}

export default WizrTextArea
