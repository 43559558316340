import React from 'react'
import { Switch } from 'antd'
import { styled } from 'styled-components'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'

interface IWizrToggle {
  onChange?: any
  isEnabled?: boolean
  disabled?: boolean
}

const StyledSwitch = styled(Switch)`
  .ant-switch-checked {
    background-color: #1890ff; /* Replace with your desired color */
  }

  /* Change the thumb color */
  .ant-switch-checked::after {
    background-color: white; /* Replace with your desired color */
  }
`

const WizrToggleButton = ({ isEnabled = false, onChange,disabled }: IWizrToggle) => {
  return (
    <>
      <StyledSwitch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={onChange}
        checked={isEnabled}
        disabled={disabled}
      />
    </>
  )
}

export default WizrToggleButton
