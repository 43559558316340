import { Form } from 'antd'
import { useEffect, useState } from 'react'
import WizrInput from '../WizrInput'
import WizrTextArea from '../WizrTextArea'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { styled } from 'styled-components'
import WizrToggleButton from '../WizrToggleButton'
import WizrTagInput from '../WizrTagInput'
import WizrText from '../WizrText'
import { useTagsSelector, useCurrentAppDetailsSelector, useContentLoadingSelector } from '../../redux/selectors/content'
import { dispatchGetUserDetails, dispatchTags, dispatchUpdateUserDetails } from '../../redux/actions/content'
import WizrDropDown from '../WizrDropDown'
import jwtDecode from 'jwt-decode'

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`
const StyledWizrInput = styled(WizrInput)`
  min-width: 25vw;
`
const InputContainer = styled(WizrFlexLayout)`
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  background-color: #fff;
`
const StyledDropDown = styled(WizrDropDown)`
 height:50px;
.ant-select-selector{
    height:50px !important;
    background-color:#F2F4F9 !important;
   .ant-select-selection-placeholder{
    margin-top:10px;
   }
}
 `

interface IEditUserDetails {
    profileDetails?: { [key: string]: any }
  onCancel: () => void
}

const EditUserDetails = ({ profileDetails,onCancel }: IEditUserDetails): JSX.Element => {
const loading =useContentLoadingSelector() 
  const [form] = Form.useForm()
  const userRoles = [
    { value:"Super Admin",label:"Super Admin"},
   { value:"Wizr Manager",label:"Wizr Manager"},
    {value:"Tenant Admin",label:"Tenant Admin"},
    {value:"Wizr User",label:"Wizr User"},
    {value:"Tenant Manager",label:"Tenant Manager"},
    {value:"Tenant User",label:"Tenant User"},

  ]
  
  const [userDetails,setUserDetails] = useState(profileDetails?profileDetails:{
    id:"",
    displayName: "",
    mail: null,
    mobilePhone: [],
    createdDateTime: "",
    accountEnabled: null,
    extension_2422f8c615d444ee898955a9dd646513_UserRole:""
})
  
  const setDetails= (value:any,key:any)=>{
    
    setUserDetails({
      ...userDetails,
      [key]:value
    })
  }
  const OnFormSubmission = async ()=>{
    
        const payload = {        
          user_id: userDetails?.id,
          user_data: {
              name: userDetails?.displayName,
              phone_no: userDetails?.mobilePhone,
              user_role: userDetails?.role
      }    
      }
      
      await dispatchUpdateUserDetails(payload)
    
      await dispatchGetUserDetails(userDetails?.id)
        onCancel()
  }
  return (
    <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={()=>{OnFormSubmission()}}
        onFinishFailed={() => null}
        autoComplete='off'
      >
        <Form.Item name='name'>
          <InputContainer>
            <WizrText type='body2'>Name</WizrText>
           <WizrInput placeholder='Enter Name ' background={"#F2F4F9"} value={userDetails?.displayName} onChange={(value:any)=>{setDetails(value,"displayName")}}></WizrInput>

          </InputContainer>
        </Form.Item>
        <Form.Item name='role'>
          <InputContainer>
        <WizrText type='body2'>Role</WizrText>
        <WizrInput placeholder='Role' background={"#F2F4F9"} value={userDetails?.role} disabled={true}></WizrInput>
</InputContainer>
</Form.Item>

        <Form.Item name='mail' rules={[{ required: false, whitespace: true }]}>
          <InputContainer>
            <WizrText type='body2'>Email</WizrText>
           <WizrInput placeholder='Enter Email ' background={"#F2F4F9"} value={userDetails?.mail?userDetails?.mail:"NA"} onChange={(value:any)=>{setDetails(value,"mail")}} disabled={true}></WizrInput>

          </InputContainer>
        </Form.Item>
        <Form.Item name='phone' rules={[{ required: false, whitespace: true }]}>
          <InputContainer>
            <WizrText type='body2'>Phone Number</WizrText>
           <WizrInput placeholder='Enter MobileNumber ' background={"#F2F4F9"} value={userDetails?.mobilePhone} onChange={(value:any)=>{setDetails(value,"mobilePhone")}}></WizrInput>

          </InputContainer>
        </Form.Item>

        <Form.Item>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text='Save' disabled={loading} style={{borderRadius: '20px'}}/>
          </WizrFlexSpacing>
        </Form.Item>
      </Form>
    </StyledContainer>
  )
}

export default EditUserDetails
