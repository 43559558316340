import { styled } from 'styled-components'
import WizrText from '../WizrText'
import WizrButton from '../WizrButton'
import WizrFlexSpacing from '../WizrFlexSpacing'

interface IDeleteFileForm {
  fileName: string
  onDeleteConfirm?: () => void
  onCancel?: () => void
}

const TextContainer = styled.div`
  padding: 20px 0px;
  background: #fff;
`
const DeleteFileForm = ({ fileName, onDeleteConfirm, onCancel }: IDeleteFileForm): JSX.Element => {
  return (
    <WizrFlexSpacing background='#FFF'>
      <TextContainer>
        <WizrText type='body1'>Are you sure you want to delete {fileName} ?</WizrText>
      </TextContainer>
      <WizrFlexSpacing flexDirection='row' size='large' justifyContent='flex-end' background='#FFF'>
        <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
        <WizrButton text='Delete' onClick={onDeleteConfirm} style={{borderRadius: '20px'}}/>
      </WizrFlexSpacing>
    </WizrFlexSpacing>
  )
}

export default DeleteFileForm
