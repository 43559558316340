const PlayIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='14' viewBox='0 0 12 14' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.21497 1.36694L9.89997 5.66394C10.2719 5.92889 10.4927 6.35732 10.4927 6.81394C10.4927 7.27056 10.2719 7.699 9.89997 7.96394L4.20997 12.6639C3.72964 13.0453 3.07934 13.1339 2.51451 12.8948C1.94969 12.6558 1.56056 12.1273 1.49997 11.5169V2.51694C1.55839 1.90422 1.94796 1.37281 2.51472 1.13275C3.08148 0.892689 3.73422 0.982597 4.21497 1.36694Z'
        stroke='#1D1F22'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default PlayIcon
