const CircleCancel = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.44995 8.14277C1.45019 4.80326 3.8094 1.92897 7.08478 1.27768C10.3602 0.626396 13.6395 2.3795 14.9173 5.46488C16.1951 8.55027 15.1154 12.1086 12.3386 13.9638C9.56181 15.8189 5.86122 15.4543 3.49995 13.0928C2.18723 11.7799 1.44982 9.99933 1.44995 8.14277Z'
        stroke='#000000'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13.4 3.19287L3.5 13.0929'
        stroke='#000000'
        stroke-width='1.5'
        stroke-linecap='round'
      />
    </svg>
  )
}
export default CircleCancel
