import { promises } from 'dns'
import {
  WizrAPIInstance
} from '../axios'
import {
  promptManagerAPIUrls, systemConfigUrls, contentFolderApiUrls,
  fileManagerAPIUrls, appManagerAPIUrls, orchestratorApiUrls, fileUrls, userManagementUrls, tenantManagement, accessManagement, rolesManagement, packageManagement, productManagement, integrationManagement, featureManagement, AutotagListing, ControlroomApi,
  chatBotApi,
  autoSolveApis,
  widgetManagement
} from '../url'
import FeatureSettings from '../../pages/components/CXSettingsComponents/AutoTag'

const createFolder = (payload: any = {}): Promise<any> => WizrAPIInstance.post(contentFolderApiUrls.createFolder, payload)

const listFolders = (type: string): Promise<any> => WizrAPIInstance.get(contentFolderApiUrls.listFolders(type))

const deleteFolder = (folderId: string): Promise<any> =>
  WizrAPIInstance.delete(contentFolderApiUrls.deleteFolder(folderId))

const updateFolder = (payload: any = {}): Promise<any> =>
  WizrAPIInstance.put(contentFolderApiUrls.updateFolder, payload)

const listFiles = (fileId: string): Promise<any> => WizrAPIInstance.get(fileManagerAPIUrls.listFiles(fileId))

const updateFile = (payload: any = {}): Promise<any> =>
  WizrAPIInstance.put(fileManagerAPIUrls.updateFile, payload)

const uploadFile = (payload: any = {}): Promise<any> =>
  WizrAPIInstance.post(fileUrls.uploadFile, payload)

const deleteFile = (fileId: string): Promise<any> => WizrAPIInstance.delete(fileManagerAPIUrls.deleteFile(fileId))

const listTemplates = (): Promise<any> => WizrAPIInstance.get(promptManagerAPIUrls.listPromptTemplates)

const listPublishedPrompts = (): Promise<any> =>
  WizrAPIInstance.get(promptManagerAPIUrls.listPromptsPublished)
const listDraftPrompts = (): Promise<any> =>
  WizrAPIInstance.get(promptManagerAPIUrls.listPromptsDraft)

const managePrompts = (payload: any): Promise<any> =>
  WizrAPIInstance.post(promptManagerAPIUrls.managePrompts, payload)

const addComments = (payload: any): Promise<any> =>
  WizrAPIInstance.post(promptManagerAPIUrls.addPromptFeedback, payload)

const listPromptHistory = (promptId: string): Promise<any> =>
  WizrAPIInstance.get(promptManagerAPIUrls.listPromptHistory(promptId))

const publishPrompt = (payload: any = {}): Promise<any> =>
  WizrAPIInstance.post(promptManagerAPIUrls.publishPrompt, payload)

const testPrompt = (payload: any): Promise<any> =>
  WizrAPIInstance.post(orchestratorApiUrls.promptChat, payload)

const listLLMModels = (): Promise<any> =>
  WizrAPIInstance.get(systemConfigUrls.getLLMModels)
const listVectorDb = (): Promise<any> =>
  WizrAPIInstance.get(systemConfigUrls.getVectorDb)
const listVoiceModels = (): Promise<any> =>
  WizrAPIInstance.get(systemConfigUrls.getVoiceModels)
const listVisionModels = (): Promise<any> =>
  WizrAPIInstance.get(systemConfigUrls.getVisionModels)
const listModels = (): Promise<any> =>
  WizrAPIInstance.get(systemConfigUrls.getModels)

const getPromptDetails = (promptId: string): Promise<any> =>
  WizrAPIInstance.get(promptManagerAPIUrls.getPromptDetails(promptId))

const getPromptTemplateDetails = (promptId: string): Promise<any> =>
  WizrAPIInstance.get(promptManagerAPIUrls.getPromptTemplateDetails(promptId))

const getPromptTags = (): Promise<any> => WizrAPIInstance.get(promptManagerAPIUrls.getPromptTags)

const listAppTemplates = (payload: any = {}): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.getAppTemplateDetails, payload)

const listAppPublished = (payload: any = {}): Promise<any> =>
  WizrAPIInstance.post(appManagerAPIUrls.listAppPublished, payload)
const listAppDraft = (payload: any = {}): Promise<any> =>
  WizrAPIInstance.post(appManagerAPIUrls.listAppDraft, payload)


const createApp = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.insertApp, payload)

const updateApp = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.updateApp, payload)

const publishApp = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.publishApp, payload)
const setAsDefault = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.setAsDefault, payload)
const setAsTemplate = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.setAsTemplate, payload)
const getAppDetails = (appId: string): Promise<any> =>
  WizrAPIInstance.get(appManagerAPIUrls.getAppDetails(appId))
const getPublishedAppDetails = (appId: string, type: any): Promise<any> =>
  WizrAPIInstance.get(appManagerAPIUrls.getPublishedAppDetails(appId, type))
const getAppDetailsTemplate = (appId: string): Promise<any> =>
  WizrAPIInstance.get(appManagerAPIUrls.getAppDetailsTemplate(appId))
const addCommentsApp = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.addAppFeedback, payload)

const deleteApp = (appId: any): Promise<any> => WizrAPIInstance.delete(appManagerAPIUrls.deleteApp(appId))
const testAppTool = (payload: any): Promise<any> => WizrAPIInstance.post(orchestratorApiUrls.appChat, payload)
const listAppHistory = (appId: any, payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.listAppHistory(appId), payload)
const listAppVersions = (appId: string, type: string): Promise<any> => WizrAPIInstance.get(appManagerAPIUrls.listAppVersions(appId, type))
const listTemplateVersion = (appId: string): Promise<any> => WizrAPIInstance.get(appManagerAPIUrls.listTemplateVersions(appId))

const getBulkTestStatus = (appId: string, execId: any): Promise<any> => WizrAPIInstance.get(appManagerAPIUrls.bulkRunStatus(appId, execId))
const listRunHistory = (appId: string, payload:any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.listRunHistory(appId),payload)
const testAppshareTool = (payload: any, appId: any): Promise<any> => WizrAPIInstance.appTestPost(appManagerAPIUrls.appShareTest(appId), payload)
const getAppShareDetails = (appId: any): Promise<any> => WizrAPIInstance.appshareget(appManagerAPIUrls.getAppShareDetails(appId))
const listTags = (): Promise<any> => WizrAPIInstance.get(systemConfigUrls.getTags)
const appShareLinkGeneration = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.appShareLinkGeneration, payload)
const appShareLinkValidation = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.appShareLinkValidation, payload)
const bulkrunStatusApp = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.bulkrunStatusApp, payload)
const uploadFilesusingLink = (payload: any): Promise<any> => WizrAPIInstance.formDatapost(fileUrls.uploadFileUsingLink, payload)
const generateSASurl = (payload: any): Promise<any> => WizrAPIInstance.post(fileUrls.uploadFileSASgenration, payload)
const terminateBulkrun = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.bulkrunTerminateRun, payload)
const executeBulkrun = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.bulkrunExecuteBulkrun, payload)
const appApiIntegrate = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.appApiIntegate, payload)
const enableDisableUser = (payload: any): Promise<any> => WizrAPIInstance.post(userManagementUrls.enableDisableUser, payload)
const listTenantUsers = (tenantType: any): Promise<any> => WizrAPIInstance.get(userManagementUrls.listUsers(tenantType))
const updateUserDetails = (payload: any): Promise<any> => WizrAPIInstance.post(userManagementUrls.updateUserDeatils, payload)
const createUser = (payload: any): Promise<any> => WizrAPIInstance.post(userManagementUrls.createUser, payload)
const deleteUser = (userId: any): Promise<any> => WizrAPIInstance.delete(userManagementUrls.deleteUser(userId))
const getUserDetails = (userId: any): Promise<any> => WizrAPIInstance.get(userManagementUrls.getUserDetails(userId))
const getTenantList = (): Promise<any> => WizrAPIInstance.get(tenantManagement.listTenants)
const createTenants = (payload: any): Promise<any> => WizrAPIInstance.post(tenantManagement.createTenants, payload)
const getAccessList = (): Promise<any> => WizrAPIInstance.get(accessManagement.listAccess)
const getRoleList = (): Promise<any> => WizrAPIInstance.get(rolesManagement.listRoles)
const deleteAccess = (access_id: any): Promise<any> => WizrAPIInstance.delete(accessManagement.deleteAccess(access_id))
const createRole = (payload: any): Promise<any> => WizrAPIInstance.put(rolesManagement.createRole, payload)
const updateRole = (payload: any): Promise<any> => WizrAPIInstance.post(rolesManagement.updateRole, payload)
const deleteRole = (roleId: any): Promise<any> => WizrAPIInstance.delete(rolesManagement.deleteRole(roleId))
const getTenantDetails = (): Promise<any> => WizrAPIInstance.get(tenantManagement.getTenantDetails)
const listPackages = (): Promise<any> => WizrAPIInstance.get(packageManagement.listPackages)
const listProducts = (): Promise<any> => WizrAPIInstance.get(productManagement.listProducts)
const updateProducts = (payload: any): Promise<any> => WizrAPIInstance.post(productManagement.updateProducts, payload)
const createPackage = (payload: any): Promise<any> => WizrAPIInstance.put(packageManagement.createPackages, payload)
const updatePackages = (payload: any): Promise<any> => WizrAPIInstance.post(packageManagement.updatePackages, payload)
const updateTenants = (payload: any): Promise<any> => WizrAPIInstance.post(tenantManagement.updateTenants, payload)
const deletePackages = (packageId: any): Promise<any> => WizrAPIInstance.delete(packageManagement.deletePackages(packageId))
const deleteTenants = (Id: any): Promise<any> => WizrAPIInstance.delete(tenantManagement.deleteTenant(Id))
const disableTenant = (Id: any): Promise<any> => WizrAPIInstance.post(tenantManagement.disableTenant(Id), {})
const enableTenant = (Id: any): Promise<any> => WizrAPIInstance.post(tenantManagement.enableTenant(Id), {})
const listIntegrations = (): Promise<any> => WizrAPIInstance.get(integrationManagement.listIntegrations)
const masterTenantIntegrations = (): Promise<any> => WizrAPIInstance.get(integrationManagement.masterTenantIntegrations)
const deleteIntegration = (integrationId: any): Promise<any> => WizrAPIInstance.delete(integrationManagement.deleteIntegration(integrationId))
const updateIntegration = (payload: any): Promise<any> => WizrAPIInstance.post(integrationManagement.updateIntegration, payload)
const createIntegration = (payload: any): Promise<any> => WizrAPIInstance.put(integrationManagement.createIntegration, payload)
const listAppForPackage = (payload: any): Promise<any> => WizrAPIInstance.post(packageManagement.listAppForPackage, payload)
const listAppGeneral = (payload: any): Promise<any> => WizrAPIInstance.post(packageManagement.listAppGeneral, payload)
const getFeatureSettings = (): Promise<any> => WizrAPIInstance.get(featureManagement.featureSetings)
const updateFetaureSettingsStatus = (payload: any): Promise<any> => WizrAPIInstance.post(featureManagement.updateStatus, payload)
const createFeatureIntegrationSettings = (payload: any): Promise<any> => WizrAPIInstance.post(featureManagement.createFeatureIntegration, payload)
const createGroupFolder = (payload: any, type: any): Promise<any> => WizrAPIInstance.post(featureManagement.addFolderGroup(type), payload)
const getAutoTagList = (type: any): Promise<any> => WizrAPIInstance.get(AutotagListing.listAutoTag(type))
const updateTicketType = (payload: any, type: any): Promise<any> => WizrAPIInstance.post(featureManagement.updateTicketType(type), payload)
const updateCompanyInfo = (payload: any): Promise<any> => WizrAPIInstance.post(featureManagement.updateCompanyInfo, payload)
const updatePersona = (payload: any): Promise<any> => WizrAPIInstance.post(featureManagement.updatePersona, payload)
const getFeatureAppDetails = (appId: any): Promise<any> => WizrAPIInstance.get(featureManagement.getFeatureAppDetails(appId))
const getPacakageDetails = (type: any): Promise<any> => WizrAPIInstance.get(featureManagement.getPacakageDetails(type))
const updateFeatureSettings = (payload: any): Promise<any> => WizrAPIInstance.post(featureManagement.updateFeatureSettings, payload)
const getEndPointUrl = (payload: any): Promise<any> => WizrAPIInstance.post(featureManagement.getEndPointUrl, payload)
const getTenantGroups = (Id: any): Promise<any> => WizrAPIInstance.get(tenantManagement.getTenantGroups(Id))
const saveTenantGroups = (payload: any): Promise<any> => WizrAPIInstance.put(tenantManagement.saveTenantGroups, payload)
const getGroupRowDetails = (type: any, folderId: any): Promise<any> => WizrAPIInstance.get(fileManagerAPIUrls.listRowContents(type, folderId))
const deleteGroupRowDetails = (type: any, rowId: any): Promise<any> => WizrAPIInstance.delete(fileManagerAPIUrls.deleteRowContent(type, rowId))
const deleteGroupsFaqPastTickets = (type: any, folderId: any): Promise<any> => WizrAPIInstance.delete(contentFolderApiUrls.deleteFaqPtGroup(type, folderId))
const getIntegrationToolsForTenant = (): Promise<any> => WizrAPIInstance.get(contentFolderApiUrls.getIntegrationsToolsForTenants)
const getIntegrationsForSelectedTool = (tool: any): Promise<any> => WizrAPIInstance.get(contentFolderApiUrls.getIntegrationsForTools(tool))
const listEmbeddingModels = (): Promise<any> => WizrAPIInstance.get(systemConfigUrls.getEmbeddingModels)
const importPastTickets = (payload: any): Promise<any> => WizrAPIInstance.post(contentFolderApiUrls.importPastTickets, payload)

const controlRoomApi = (payload: any): Promise<any> => WizrAPIInstance.post(ControlroomApi.sourcetreeData, payload)
const getSubscriptionDetails = (): Promise<any> => WizrAPIInstance.get(tenantManagement.getSubscriptionDetails)
const deleteTicketsFaqPt = (rowId: any, type: any): Promise<any> => WizrAPIInstance.delete(fileManagerAPIUrls.deleteTicketsFaqPt(rowId, type))
const testChatBot = (payload: any): Promise<any> => WizrAPIInstance.post(chatBotApi.chatBotTest, payload)
const testChatBotAdithyaBirla = (payload: any): Promise<any> => WizrAPIInstance.post(chatBotApi.chatBotTestAdithyaBirla, payload)
const appInsights = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.appInsights, payload)
const appInsightsWhole = (payload: any): Promise<any> => WizrAPIInstance.post(appManagerAPIUrls.appInsightsWhole, payload)
const getBotDetails = (botId: string): Promise<any> => WizrAPIInstance.get(autoSolveApis.getBotDetails(botId))
const updateBot = (payload: any): Promise<any> => WizrAPIInstance.post(autoSolveApis.updateBot, payload)
const testBot = (payload: any): Promise<any> => WizrAPIInstance.post(autoSolveApis.testBot, payload)
const listWidgets = (): Promise<any> => WizrAPIInstance.get(widgetManagement.listWidgets)
const getToolsList = (): Promise<any> => WizrAPIInstance.get(widgetManagement.getToolsList)
const listWidgetTypes = (): Promise<any> => WizrAPIInstance.get(widgetManagement.listWidgetTypes)
const createWidget = (payload: any): Promise<any> => WizrAPIInstance.post(widgetManagement.createWidgets, payload)
const updateWidget = (payload: any): Promise<any> => WizrAPIInstance.post(widgetManagement.updateWidgets, payload)
const testWidget = (payload: any): Promise<any> => WizrAPIInstance.post(widgetManagement.testWidgets, payload)
const getAgentList = (tenant_feature_id: any, app_feature_id: any): Promise<any> => WizrAPIInstance.get(autoSolveApis.getAgentList(tenant_feature_id, app_feature_id))
const getInstallationList = (): Promise<any> => WizrAPIInstance.get(autoSolveApis.getInstallationList)
const createAgent = (payload: any): Promise<any> => WizrAPIInstance.post(autoSolveApis.createAgent, payload)
const createBot = (payload: any): Promise<any> => WizrAPIInstance.post(autoSolveApis.createBot, payload)
const updateAgentApi = (payload: any): Promise<any> => WizrAPIInstance.post(autoSolveApis.updateAgent, payload)
const deleteAgent = (payload: any): Promise<any> => WizrAPIInstance.delete(autoSolveApis.deleteAgent(payload))
const deleteWidget = (payload: any): Promise<any> => WizrAPIInstance.delete(autoSolveApis.deleteWidget(payload))
const getWidgetDetails = (tool_id: any): Promise<any> => WizrAPIInstance.get(autoSolveApis.getWidgetDetails(tool_id))
const getBotRunHistoryDetails = (botId: string): Promise<any> => WizrAPIInstance.get(autoSolveApis.getBotRunHistory(botId))
const getBotRunHistoryDetailsOfACoversation = (bot_id: any, conversationId: any): Promise<any> => WizrAPIInstance.get(autoSolveApis.getBotRunHistoryOfConversation(bot_id, conversationId))
const listAgentConversations = (payload: any): Promise<any> => WizrAPIInstance.post(autoSolveApis.getAgentAssistConversationList, payload)
const getAgentConversationDetail = (conversationId: any): Promise<any> => WizrAPIInstance.get(autoSolveApis.getAgentAssistConversationDetail(conversationId))
const generateCode = (payload: any): Promise<any> => WizrAPIInstance.post(autoSolveApis.generateCode, payload)
const getAutosolveConversations = (payload: any): Promise<any> => WizrAPIInstance.post(autoSolveApis.getAutosolveConversations, payload)
const getBotTestHistory = (botId: any,conversationId:any): Promise<any> => WizrAPIInstance.get(autoSolveApis.getBotTestHistory(botId, conversationId))
const saveFeedBackAutoSolve = (payload: any): Promise<any> => WizrAPIInstance.post(autoSolveApis.saveFeedBackAutoSolve, payload)
const getWidgetHistoryDetails = (payload: any,tool_id:any): Promise<any> => WizrAPIInstance.post(widgetManagement.widgetHistory(tool_id), payload)
const downloadCsvReport = (payload: any): Promise<any> => WizrAPIInstance.post(autoSolveApis.downloadCsvReport, payload)
const getUserMappedRoles = (role:any): Promise<any> => WizrAPIInstance.get(rolesManagement.getUserMappedRoles(role))

export {
  createFolder,
  listFolders,
  deleteFolder,
  updateFolder,
  listFiles,
  updateFile,
  uploadFile,
  deleteFile,
  listTemplates,
  listPublishedPrompts,
  listDraftPrompts,
  managePrompts,
  addComments,
  listPromptHistory,
  publishPrompt,
  testPrompt,
  listLLMModels,
  getPromptDetails,
  listAppTemplates,
  getAppDetails,
  getPublishedAppDetails,
  createApp,
  updateApp,
  testAppTool,
  deleteApp,
  publishApp,
  listAppHistory,
  listAppVersions,
  getBulkTestStatus,
  testAppshareTool,
  listRunHistory,
  getPromptTags,
  listTags,
  getAppShareDetails,
  appShareLinkGeneration,
  appShareLinkValidation,
  bulkrunStatusApp,
  uploadFilesusingLink,
  generateSASurl,
  terminateBulkrun,
  executeBulkrun,
  appApiIntegrate,
  getPromptTemplateDetails,
  getAppDetailsTemplate,
  listAppPublished,
  listAppDraft,
  enableDisableUser,
  listTenantUsers,
  updateUserDetails,
  createUser,
  deleteUser,
  getUserDetails,
  getTenantList,
  createTenants,
  getAccessList,
  getRoleList,
  deleteAccess,
  createRole,
  updateRole,
  deleteRole,
  getTenantDetails,
  listPackages,
  listProducts,
  updateProducts,
  createPackage,
  updatePackages,
  updateTenants,
  deletePackages,
  deleteTenants,
  disableTenant,
  enableTenant,
  listVectorDb,
  listVoiceModels,
  listVisionModels,
  listModels,
  listIntegrations,
  masterTenantIntegrations,
  deleteIntegration,
  updateIntegration,
  createIntegration,
  listAppForPackage,
  listAppGeneral,
  setAsDefault,
  getFeatureSettings,
  updateFetaureSettingsStatus,
  createFeatureIntegrationSettings,
  createGroupFolder,
  setAsTemplate,
  getAutoTagList,
  updateTicketType,
  updateCompanyInfo,
  updatePersona,
  getFeatureAppDetails,
  getPacakageDetails,
  updateFeatureSettings,
  getEndPointUrl,
  getTenantGroups,
  saveTenantGroups,
  getGroupRowDetails,
  deleteGroupRowDetails,
  deleteGroupsFaqPastTickets,
  getIntegrationToolsForTenant,
  getIntegrationsForSelectedTool,
  listEmbeddingModels,
  importPastTickets,
  controlRoomApi,
  getSubscriptionDetails,
  deleteTicketsFaqPt,
  listTemplateVersion,
  testChatBot,
  testChatBotAdithyaBirla,
  appInsights,
  getBotDetails,
  updateBot,
  testBot,
  listWidgets,
  listWidgetTypes,
  createWidget,
  testWidget,
  getAgentList,
  getInstallationList,
  getToolsList,
  createAgent,
  createBot,
  updateAgentApi,
  deleteAgent,
  getWidgetDetails,
  updateWidget,
  deleteWidget,
  getBotRunHistoryDetails,
  getBotRunHistoryDetailsOfACoversation,
  listAgentConversations,
  getAgentConversationDetail,
  generateCode,
  getAutosolveConversations,
  appInsightsWhole,
  addCommentsApp,
  getBotTestHistory,
  saveFeedBackAutoSolve,
  getWidgetHistoryDetails,
  downloadCsvReport,
  getUserMappedRoles
}
