import { styled } from 'styled-components'
import WizrText from '../WizrText'
import WizrButton from '../WizrButton'
import WizrFlexSpacing from '../WizrFlexSpacing'

interface IConfirmationForm {
  text: string
  buttontext: string
  onConfirm?: () => void
  onCancel?: () => void
}

const TextContainer = styled.div`
  padding: 20px 0px;
  background: #fff;
`
const ConfirmationForm = ({
  text,
  buttontext,
  onConfirm,
  onCancel,
}: IConfirmationForm): JSX.Element => {
  return (
    <WizrFlexSpacing background='#FFF'>
      <TextContainer>
        <WizrText type='body1'>{text}</WizrText>
      </TextContainer>
      <WizrFlexSpacing flexDirection='row' size='large' justifyContent='flex-end' background='#FFF'>
        <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
        <WizrButton text={buttontext} onClick={onConfirm} style={{borderRadius: '20px'}}/>
      </WizrFlexSpacing>
    </WizrFlexSpacing>
  )
}

export default ConfirmationForm
