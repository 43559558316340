const ArrowDown = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='10' height='12' viewBox='0 0 10 12' fill='none'>
      <path
        d='M8.87646 7.68423C9.16212 7.38429 9.15054 6.90955 8.85059 6.62389C8.55065 6.33822 8.07591 6.3498 7.79025 6.64975L8.87646 7.68423ZM4.45692 10.1498C4.17125 10.4497 4.18283 10.9244 4.48278 11.2101C4.78273 11.4958 5.25746 11.4842 5.54312 11.1842L4.45692 10.1498ZM4.45692 11.1842C4.74258 11.4842 5.21731 11.4958 5.51726 11.2101C5.81721 10.9244 5.82879 10.4497 5.54312 10.1498L4.45692 11.1842ZM2.20979 6.64975C1.92413 6.3498 1.44939 6.33822 1.14945 6.62389C0.849498 6.90955 0.837919 7.38429 1.12358 7.68423L2.20979 6.64975ZM4.25002 10.667C4.25002 11.0812 4.58581 11.417 5.00002 11.417C5.41423 11.417 5.75002 11.0812 5.75002 10.667H4.25002ZM5.75002 1.33366C5.75002 0.919445 5.41423 0.583658 5.00002 0.583658C4.58581 0.583658 4.25002 0.919445 4.25002 1.33366H5.75002ZM7.79025 6.64975L4.45692 10.1498L5.54312 11.1842L8.87646 7.68423L7.79025 6.64975ZM5.54312 10.1498L2.20979 6.64975L1.12358 7.68423L4.45692 11.1842L5.54312 10.1498ZM5.75002 10.667L5.75002 1.33366H4.25002V10.667H5.75002Z'
        fill='#999B9F'
      />
    </svg>
  )
}

export default ArrowDown
