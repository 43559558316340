const UsernameAvatar = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 7.5C14 8.60457 13.1046 9.5 12 9.5C10.8954 9.5 10 8.60457 10 7.5C10 6.39543 10.8954 5.5 12 5.5C13.1046 5.5 14 6.39543 14 7.5Z'
        stroke='#999B9F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17 16C17 17.933 14.761 19.5 12 19.5C9.239 19.5 7 17.933 7 16C7 14.067 9.239 12.5 12 12.5C14.761 12.5 17 14.067 17 16Z'
        stroke='#999B9F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default UsernameAvatar
