import { Layout, Menu, Popover } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import WizrText from './WizrText'
import WizrFlexLayout from './WizrFlexLayout'
import WizrIcon from './WizrIcon'
import WizrAvatar from './WizrAvatar'
import WizrButton from './WizrButton'
import jwt_decode from 'jwt-decode'
import { MODAL_TYPES, useWizrModalContext } from '../providers/modal/WizrModalProvider'
import { useAuth } from '../authContext';
import { dispatchCurrentModule, dispatchTenantDetails, dispatchupdateUserView } from '../redux/actions/content'
import { useGetCurrentModule, useGetTenantDetails } from '../redux/selectors/content'

const { Sider } = Layout

const StyledSider = styled(Sider)`
  .ant-layout-sider-children {
    background: #fff;
    display: flex;
    ul {
      text-align: left;
      background: #fff;
      li {
        svg {
          height: 20px;
          width: 20px;
        }
        span {
          margin-left: 12px;
        }
      }
    }
    .ant-layout-sider-children .ant-layout {
      align-items: flex-start
    }

    .sampleIcon {
      height: 20px;
      width: 20px;
      background: #ccc;
      border-radius: 50px;
      margin-right: 5px;
      padding-left:6px;
    }

    .ant-avatar-circle {
      background-color: black;
      margin-left: 8px;
      height: 30px;
      width: 30px;
      span {
        margin-left: 0px;
      }
    }
  }
  padding: 20px 0px;
  background: #fff !important;
`
const StyledMenu = styled(Menu)`
  .ant-menu-submenu-selected .ant-menu-submenu-title{
    border-radius: 0px !important;
    border-right: 3px solid #000;
    span {
      font-weight: 700;
    }
    svg {
      path {
        stroke: #1D1F22; /* Default stroke color */
        transition: stroke 0.3s ease; /* Smooth transition for color change */
      }
    }
  }
  .ant-menu-item-selected {
    background: transparent !important;
    border-right: 3px solid #000;
    font-weight: 700;
    span {
      font-weight: 700;
    }
    svg {
      path {
        stroke: #1D1F22; /* Default stroke color */
        transition: stroke 0.3s ease; /* Smooth transition for color change */
      }
    }
  }
  .ant-menu-item {
    border-radius: 0 !important;
    white-space: normal !important;
  }
  .ant-menu-title-content {
    white-space: normal !important;
  }
`
const StyledWizrButton = styled(WizrButton)`
  width: 30px;
  height: 40px;
`

const StyledDiv = styled.div`
  padding: 0px 20px;
  background: #fff;
`

const WizrUserSideBar = (): JSX.Element => {

  const { filteredMenuItems } = useAuth()
  const navigate = useNavigate()
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(true)
  const { showModal, hideModal } = useWizrModalContext()
  const [active,setactive]=useState('')
  const doNavigateToSection = (value: any) => {
  //  setactive(value.key)
  if (value.key === "cxcontrol") {
    console.log("cxcontrol")  
  }else{
   dispatchCurrentModule(value.key)
    const originalEvent = value.domEvent;
    // Check if Ctrl key is pressed
    const isCtrlClick = originalEvent.ctrlKey;
    if(!isCtrlClick)   {
      navigate(value?.key)
    } else{
      const newPath = `/${value?.key}`;
      const newUrl = `${window.location.origin}${newPath}`
      window.open(newUrl, '_blank');
    }
    dispatchupdateUserView({ })
  }
  }
  useEffect(()=>{
    const login = location?.pathname?.includes('/login')
    const extension = location?.pathname?.includes('/extension')
    !login && !extension && dispatchTenantDetails()
  },[])
  const tenantDetails = useGetTenantDetails()  
  const doSideMenuActions = (value: any) => {
    if(value.key === 'Help'){
      navigate('help')
    }
    
  }
  const showLogoutConfirm = () => {
    showModal(MODAL_TYPES.LOGOUT, {
      onLogoutConfirm: () => {
        localStorage.clear()
        const authUrl = `${process.env.REACT_APP_WIZR_B2C_AUTH_ENDPOINT}?p=${process.env.REACT_APP_WIZR_B2C_P}&client_id=${process.env.REACT_APP_WIZR_B2C_CLIENT_ID}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_WIZR_B2C_REDIRECT_URI}&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login`
        window.location.href = authUrl
        hideModal()
      },
      onCancel: hideModal,
    })
  }
  const data = jwt_decode(localStorage.id_token)

  const getBottomMenuItems = () => {
    return [
      {
        key: 'Help',
        label:( <WizrFlexLayout flexDirection='row' alignItems='center' background='transparent'>
        <WizrText type='body2'>Help&Support</WizrText>
        {/* <WizrText type='captionItalic' textColor='#999B9F'> Coming Soon</WizrText> */}
        </WizrFlexLayout>),
        icon: <WizrIcon name='question' />,
      },
      {
        key: 'notification',
        label: (<WizrFlexLayout flexDirection='row' alignItems='center' background='transparent'>
        <WizrText type='body2'>Notification</WizrText>
        <WizrText type='captionItalic' textColor='#999B9F'> Coming Soon</WizrText>
        </WizrFlexLayout>),
        icon: <WizrIcon name='bell' />,
      },
      {
        key: 'logout',
        label: <WizrText type='body2'>{(data as { name: string }).name}</WizrText>,
        icon: (
          <Popover
            trigger='click'
            content={<WizrButton text='Logout' onClick={showLogoutConfirm} type='text' />}
          >
            <StyledWizrButton
              type='text'
              text={
                <WizrAvatar
                  name={(data as { name: string }).name}
                  background='transparent'
                  showText={false}
                  justifyContent='left'
                />
              }
            />
          </Popover>
        ),
      },
    ]
  }
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys:any) => {
    setOpenKeys(keys.slice(-1));
  };

  const key = useGetCurrentModule()
  useEffect(() => {
    setactive(key)
  }, [key])
  
  return (
    <StyledSider
      onMouseOver={() => setIsSiderCollapsed(false)}
      onMouseOut={() => setIsSiderCollapsed(true)}
      trigger={null}
      collapsible
      collapsed={isSiderCollapsed}
      //collapsed={false}
      onCollapse={(value) => setIsSiderCollapsed(value)}
      width={291}
      
    >
      <WizrFlexLayout justifyContent='space-between' background='#FFF' alignItems='flex-start'>
        <WizrFlexLayout background='#FFF' alignItems='flex-start'>
          <StyledDiv>
            {isSiderCollapsed? <WizrIcon name='wizrlogo' />:<WizrIcon name='wizrlogoText'/>}
          </StyledDiv>
        <br />
        <StyledMenu
        openKeys={openKeys}
         onOpenChange={onOpenChange}
          theme='light'
          mode='inline'
          items={filteredMenuItems}
          selectable
          onClick={doNavigateToSection}
          selectedKeys={[active]}
        />
        <WizrFlexLayout justifyContent='flex-end' background='#FFF'>
          <StyledMenu
            theme='light'
            mode='inline'
            items={getBottomMenuItems()}
            selectable
            onClick={doSideMenuActions}
          />
        </WizrFlexLayout>
        </WizrFlexLayout>
      </WizrFlexLayout>
    </StyledSider>
  )
}

export default WizrUserSideBar