import { styled } from 'styled-components'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrInput from '../WizrInput'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'

import { Form } from 'antd'
import WizrText from '../WizrText'
import InfoIcon from '../../assets/icons/Info'

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`

interface IUploadUrlForm {
  onFormSubmission: any
  onCancel: any
}

const UploadUrlForm = ({ onFormSubmission, onCancel }: IUploadUrlForm): JSX.Element => {
  const [form] = Form.useForm()
  return (
    <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFormSubmission}
        onFinishFailed={() => null}
        autoComplete='off'
      >
        <Form.Item
          name='url'
          rules={[{ required: true, message: 'Please Enter a url', whitespace: true }]}
        >
          <WizrInput
            placeholder='Enter URL'
            onChange={(value: string) => form.setFieldsValue({ url: value })}
          />
        </Form.Item>
        <Form.Item>
          <WizrText type='caption'>
            {' '}
            <InfoIcon /> You can incorporate multiple URLs, with comma separation.
          </WizrText>
        </Form.Item>
        <Form.Item>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text='Upload' style={{borderRadius: '20px'}}/>
          </WizrFlexSpacing>
        </Form.Item>
      </Form>
    </StyledContainer>
  )
}

export default UploadUrlForm
