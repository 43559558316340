import WizrFlexLayout from '../WizrFlexLayout'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'

const FilePreviewer = ({ fileUrl, onClose }: any): JSX.Element => {
  return (
    <WizrFlexSpacing background='#FFF' style={{height:"900px"}}>
      <iframe
        src={fileUrl}
        frameBorder={0}
        style={{
          border: 0,
          top: '0px',
          left: '0px',
          bottom: '0px',
          right: '0px',
          width: '100%',
          height: '100%',
        }}
        allowFullScreen={true}
      ></iframe>
      <WizrFlexSpacing flexDirection='row' size='large' justifyContent='flex-end' background='#FFF'>
        <WizrButton text='Ok' onClick={onClose} />
      </WizrFlexSpacing>
    </WizrFlexSpacing>
  )
}

export default FilePreviewer
