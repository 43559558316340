import styled from 'styled-components'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrInput from '../WizrInput'
import { Form } from 'antd'
import WizrText from '../WizrText'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'
import WizrDropDown from '../WizrDropDown'
import { useGetMasterIntegrationList } from '../../redux/selectors/content'
import { useEffect, useState } from 'react'
import { dispatchMasterIntegrationList } from '../../redux/actions/content'
import { set } from 'react-hook-form'
import CreateNew from '../../assets/icons/CreateNew'
import ZendeskIcon from '../../assets/icons/ZendeskIcon'

import Freshdesk from '../../assets/icons/FreshdeskIcon'

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`
const StyledWizrInput = styled(WizrInput)`
  min-width: 25vw;
`
const InputContainer = styled(WizrFlexLayout)`
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  background-color: #fff;
`

const StyledDropDown = styled(WizrDropDown)`
  height: 50px;
  .ant-select-selector {
    padding-top: 10px !important;
    height: 50px !important;
    background-color: #f2f4f9 !important;
    .ant-select-selection-placeholder {
      margin-top: 10px;
    }
  }
`

interface IIntegeationFormForm {
  integrationData?: { [key: string]: any }
  onFormSubmission: (params: { [key: string]: any }) => void
  onCancel: () => void
}

const AddIntegrations = ({
  integrationData,
  onFormSubmission,
  onCancel,
}: IIntegeationFormForm): JSX.Element => {
  const [currentIntegration, setCurrentIntegration] = useState(
    integrationData ? integrationData : { display_name: '', integration_key: '', config_data: [],integration_display_name:'' ,registered_emailid:''},
  )

  const [currentConfig, setCurrentConfig] = useState(
    integrationData?.config_data ? integrationData?.config_data : [],
  )
  const [form] = Form.useForm()

  const masterIntegrations = useGetMasterIntegrationList()
  useEffect(() => {
    dispatchMasterIntegrationList()
  }, [])

  const getIcon = (item:any) =>{
    switch(item?.integration_key){
      case 'zendesk':
        return <ZendeskIcon/>
      case 'freshdesk':
        return <Freshdesk/> 
      default:
        return null
    
    }
  }

  const dropDownConverter = (value: string[]) => {

    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: (
          <div style={{alignItems:"center",display:'flex',gap:'10px'}}>
            <div>
            {getIcon(item)}{/* Render the icon component */}</div>
            <div> {item.display_name} {/* Render the label text */}</div>
          </div>
        ),       
         value: item.integration_key,
      }))
    return result
  }


  const onToolSelection = (value: any,label:any) => {
  

    masterIntegrations.map((item: any) => {
      if (item?.integration_key === value) {
        setCurrentConfig(item?.config_data)
        form.setFieldsValue({ integration_key:value,
          integration_display_name:item?.display_name})  
            setCurrentIntegration({ ...currentIntegration, integration_key: value,integration_display_name:item?.display_name})
      }
    })
    
  }
  const setCurrentConfigValue = (value: any, item: any) => {

   const index = currentConfig.findIndex((currentConfig:any) => currentConfig.key_name === item.key_name);
    
    // If the item is found in the array
    if (index !== -1) {
        // Create a copy of the currentConfig array
        const updatedConfig = [...currentConfig];
        
        // Update the value of the found item
        updatedConfig[index] = { ...item, value: value };
        
        // Update the state with the modified array
        setCurrentConfig(updatedConfig);
        form.setFieldsValue({ config_data: updatedConfig })
    }
    //setCurrentConfig({...currentConfig,config:{...item,value:value}})
  }

  useEffect(()=>{
    if(integrationData){
     form.setFieldsValue({ integration_key: integrationData?.integration_key,
      display_name:integrationData?.display_name,
        config_data:integrationData?.config_data,
        integration_display_name:integrationData?.integration_display_name,
        registered_emailid:integrationData?.registered_emailid})
    }
    integrationData?.config_data?.map((item:any)=>{
      form.setFieldsValue({ [item.key_name]: item.value })
    })
 },[integrationData])
  return (
    <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFormSubmission}
        onFinishFailed={() => null}
        autoComplete='off'
      >
        <Form.Item name='integration_key' >
          <InputContainer>
            <WizrText type='body2'>Select Tool</WizrText>
            <StyledDropDown
              value={currentIntegration?.integration_key}
              options={dropDownConverter(masterIntegrations)}
              onChange={(value: any,label:any) => {onToolSelection(value,label)}}
            />
          </InputContainer>
        </Form.Item>
        <Form.Item name='display_name'>
          <InputContainer>
            <WizrText type='body2'>Name</WizrText>
            {/* <StyledDropDown options={dropDownConverter(masterIntegrations)} onChange={(value:any)=>onToolSelection(value)}/> */}
            <WizrInput
              background={'#F2F4F9'}
              value={currentIntegration?.display_name}
              onChange={(value: any) =>
                setCurrentIntegration({ ...currentIntegration, display_name: value })
              }
            ></WizrInput>
          </InputContainer>
        </Form.Item>

        <Form.Item
        name='registered_emailid'
        rules={[
          {
            pattern: new RegExp(/^\S+@\S+\.\S+$/), // Regular expression for email validation
            message: 'Enter a valid email address!',
          },
        ]}
      >
          <InputContainer>
            <WizrText type='body2'>Email ID</WizrText>
            {/* <StyledDropDown options={dropDownConverter(masterIntegrations)} onChange={(value:any)=>onToolSelection(value)}/> */}
            <WizrInput
              background={'#F2F4F9'}
              value={currentIntegration?.registered_emailid}
              onChange={(value: any) =>
                setCurrentIntegration({ ...currentIntegration, registered_emailid: value })
              }
            ></WizrInput>
          </InputContainer>
        </Form.Item>

        <Form.Item name='config_data'>
          {currentConfig.map((item: any, index: any) => {
            return (
              <Form.Item name={item?.key_name} key={index}>
                <InputContainer>
                  <WizrText type='body2'>{item?.display_name}</WizrText>
                  {/* <StyledDropDown options={dropDownConverter(masterIntegrations)} onChange={(value:any)=>onToolSelection(value)}/> */}
                  <WizrInput
                    background={'#F2F4F9'}
                    value={item?.value}
                    onChange={(value: any) => setCurrentConfigValue(value, item)}
                  ></WizrInput>
                </InputContainer>
              </Form.Item>
            )
          })}
        </Form.Item>

        <Form.Item>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <Form.Item name='integration_display_name' >
       
       </Form.Item>
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text='Save' style={{borderRadius: '20px'}}/>
          </WizrFlexSpacing>
        </Form.Item>
      </Form>
    </StyledContainer>
  )
}

export default AddIntegrations
