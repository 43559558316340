import { Button, Checkbox, Dropdown, Form, Menu } from 'antd'
import { useEffect, useState } from 'react'
import WizrInput from '../WizrInput'
import WizrTextArea from '../WizrTextArea'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { styled } from 'styled-components'
import WizrToggleButton from '../WizrToggleButton'
import WizrTagInput from '../WizrTagInput'
import WizrText from '../WizrText'
import { useTagsSelector, useCurrentAppDetailsSelector, useContentLoadingSelector, useLLMModelSelector, useListAccessSelectors } from '../../redux/selectors/content'
import { dispatchAccessList, dispatchGetUserDetails, dispatchLLMModels, dispatchProuctsList, dispatchTags, dispatchUpdateProduct, dispatchUpdateUserDetails } from '../../redux/actions/content'
import WizrDropDown from '../WizrDropDown'
import jwtDecode from 'jwt-decode'
import InfoIcon from '../../assets/icons/Info'
import { DownOutlined } from '@ant-design/icons'

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`
const StyledWizrInput = styled(WizrInput)`
  min-width: 25vw;
`
const InputContainer = styled(WizrFlexLayout)`
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  background-color: #fff;
`
const StyledDropDown = styled(WizrDropDown)`
 height:50px;
.ant-select-selector{
    height:50px !important;
    background-color:#F2F4F9 !important;
   .ant-select-selection-placeholder{
    margin-top:10px;
   }
}
 `

interface IProductUpdate {
  productDetails :any
  onFormSubmission: (params: { [key: string]: any }) => void
  onCancel: () => void
}

const ProductUpdate = ({ productDetails,onCancel }: IProductUpdate): JSX.Element => { 
const loading =useContentLoadingSelector() 
const [productInputState,setProductInputState]=useState(productDetails?.product)
const [llmModelNames, setLlmModelNames] = useState<string[]>([]);
const [selectedLlmModel,setSelectedLlmModel] = useState<string[]>([])
const [visible, setVisible] = useState(false);
const [selectedItems, setSelectedItems] = useState<string[]>(productDetails?.product?.access_privileges?productDetails?.product?.access_privileges:[]);


  const [form] = Form.useForm()
  useEffect(() => {
    const data = productInputState?.product_llm_models
    setSelectedLlmModel(data)
  }, [])

  
const llmModels =useLLMModelSelector()
useEffect(() => {
  
  const filteredData = llmModels.map((item:any)=>item?.display_name)    
  setLlmModelNames(filteredData)
}, [llmModels])
// useEffect(() => {
//   const filteredData = llmModels.map((item:any)=>item?.display_name)  
//   setLlmModelNames(filteredData)
// }, [])
  const doUpdateFields = (name:any,value:any)=>{
setProductInputState({
  ...productInputState,
  [name]:value
})
  }
 const updateProducts=async()=>{
  
  const {llm_model_details,...payload } = productInputState;
  const finalPayload = {...payload,access_privileges:selectedItems}
  onCancel()
 await dispatchUpdateProduct(finalPayload)
 await dispatchProuctsList()
 }
 const dropDownConverter = (value: string[]) => {
  const result =
    value?.length > 0 &&
    value?.map((item: any, key: number) => ({
      label: item?.display_name,
      value: item?.id
    }))
  return result
}
const renderMenu = () => (
  <Menu>
    {sortedData.map((categoryData:any) => (
      <Menu.SubMenu key={categoryData.category} title={categoryData.category}>
        {categoryData.items.map((item:any) => (
          <Menu.Item key={item}>
              <div style={{overflow:"auto"}}>
            <Checkbox
              checked={selectedItems.includes(item)}
              onChange={() => toggleItemSelection(item)}
            >
              {item}
            </Checkbox></div>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    ))}
  </Menu>
);
const handleVisibleChange = (flag: boolean) => {
  setVisible(flag);
};
const toggleItemSelection = (item: string) => {
  const selectedIndex = selectedItems.indexOf(item);
  const newSelectedItems = [...selectedItems];
  if (selectedIndex === -1) {
    newSelectedItems.push(item);
  } else {
    newSelectedItems.splice(selectedIndex, 1);
  }  
  setSelectedItems(newSelectedItems);
};
const accessList =  useListAccessSelectors()
    const data = accessList.reduce((acc:any, curr:any) => {
        const existingCategory = acc.find((item:any) => item.category === curr.access_group);
        if (existingCategory) {
          existingCategory.items.push(curr.privilege_key);
        } else {
          acc.push({ category: curr.access_group, items: [curr.privilege_key] });
        }
        return acc;
      }, []);
      const sortedData = [
        ...data.filter((category:any) => category.category !== 'app'), // Filter out 'app' category
        ...data.filter((category:any) => category.category === 'app'), // Add 'app' category at the end
      ];
  return (
    <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={()=>{updateProducts()}}
        onFinishFailed={() => null}
        autoComplete='off'
      >
        <Form.Item name='name'>
          <InputContainer>
          <div style={{gap:"5px",display:"flex"}}>
            <WizrText type='body2'>Name</WizrText><InfoIcon/></div>
            <WizrInput placeholder='Select Tier' background={"#F2F4F9"} value = {productInputState?.product_name}  onChange={(value:any)=>{doUpdateFields("product_name",value)}}/>
          </InputContainer>
        </Form.Item>
        <Form.Item name='models'>
          <InputContainer>
          <div style={{gap:"5px",display:"flex"}}>
            <WizrText type='body2'>AI Models</WizrText><InfoIcon/></div>   
                 <WizrTagInput  options={dropDownConverter(llmModels)} value={selectedLlmModel} onChange={(value:any)=>{doUpdateFields("product_llm_models",value)}}></WizrTagInput>
</InputContainer>
</Form.Item>

        <Form.Item name='number' rules={[{ required: false, whitespace: true }]}>
          <InputContainer>
          <div style={{gap:"5px",display:"flex"}}>
            <WizrText type='body2'>Number of calls</WizrText><InfoIcon/></div>
           <WizrInput placeholder='Enter number of calls ' background={"#F2F4F9"} value={productInputState?.calls_count} onChange={(value:any)=>{doUpdateFields("calls_count",value)}} ></WizrInput>

          </InputContainer>
        </Form.Item>
        <Form.Item name='phone' rules={[{ required: false, whitespace: true }]}>
          <InputContainer>
          <div style={{gap:"5px",display:"flex"}}>
            <WizrText type='body2'>Access</WizrText><InfoIcon/></div>   
            <Dropdown
      overlay={renderMenu()}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      trigger={["click"]}
    >
      <Button style={{backgroundColor:"#F2F4F9",textAlign:"left",height:"50px",width:"470px"}} >
        
        <WizrText type='body2' textColor="#707276">Select items</WizrText> <DownOutlined />
        
      </Button>
    </Dropdown>                
          </InputContainer>
        </Form.Item>

        <Form.Item>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={()=>onCancel()} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text='Save' disabled={loading} style={{borderRadius: '20px'}}/>
          </WizrFlexSpacing>
        </Form.Item>
      </Form>
    </StyledContainer>
  )
}

export default ProductUpdate
