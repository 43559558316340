import { createModel } from '@rematch/core'
import { RootModel } from '..'

interface ICommonModel {
  notificationState: { [key: string]: string | null }
}
const intialState: ICommonModel = {
  notificationState: {
    message: null,
    description: null,
    type: null,
  },
}

export const commonModel = createModel<RootModel>()({
  state: intialState as ICommonModel,
  reducers: {
    updateNotificationState: (state, payload: { [key: string]: string | null }) => {
      return {
        ...state,
        notificationState: payload,
      }
    },
  },
})
