const Hamburger = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='11' viewBox='0 0 15 11' fill='none'>
      <path
        d='M1.5 4.75C1.08579 4.75 0.75 5.08579 0.75 5.5C0.75 5.91421 1.08579 6.25 1.5 6.25V4.75ZM13.5 6.25C13.9142 6.25 14.25 5.91421 14.25 5.5C14.25 5.08579 13.9142 4.75 13.5 4.75V6.25ZM1.5 8.75C1.08579 8.75 0.75 9.08579 0.75 9.5C0.75 9.91421 1.08579 10.25 1.5 10.25V8.75ZM13.5 10.25C13.9142 10.25 14.25 9.91421 14.25 9.5C14.25 9.08579 13.9142 8.75 13.5 8.75V10.25ZM1.5 0.75C1.08579 0.75 0.75 1.08579 0.75 1.5C0.75 1.91421 1.08579 2.25 1.5 2.25V0.75ZM13.5 2.25C13.9142 2.25 14.25 1.91421 14.25 1.5C14.25 1.08579 13.9142 0.75 13.5 0.75V2.25ZM1.5 6.25H13.5V4.75H1.5V6.25ZM1.5 10.25H13.5V8.75H1.5V10.25ZM1.5 2.25H13.5V0.75H1.5V2.25Z'
        fill='#999B9F'
      />
    </svg>
  )
}
export default Hamburger
