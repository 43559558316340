import ArrowDown from '../assets/icons/ArrowDown'
import BellIcon from '../assets/icons/Bell'
import BreadcrumbArrow from '../assets/icons/BreadcrumbArrow'
import Check from '../assets/icons/Check'
import CircleCancel from '../assets/icons/CircleCancel'
import CloseIcon from '../assets/icons/Close'
import Copy from '../assets/icons/Copy'
import CreateNew from '../assets/icons/CreateNew'
import DeleteIcon from '../assets/icons/DeleteIcon'
import DislikeIcon from '../assets/icons/DislikeIcon'
import DoneIcon from '../assets/icons/Done'
import EditIcon from '../assets/icons/EditIcon'
import FileIcon from '../assets/icons/FileIcon'
import FolderIcon from '../assets/icons/Folder'
import FolderCreate from '../assets/icons/FolderCreate'
import Hamburger from '../assets/icons/Hamburger'
import HomeIcon from '../assets/icons/Home'
import InfoIcon from '../assets/icons/Info'
import LikeIcon from '../assets/icons/LikeIcon'
import MoreIcon from '../assets/icons/More'
import NotesIcon from '../assets/icons/Notes-lines'
import Packages from '../assets/icons/Packages'
import PasswordAvatar from '../assets/icons/PasswordAvatar'
import PlayIcon from '../assets/icons/PlayButton'
import QuestionIcon from '../assets/icons/Question'
import RepeatIcon from '../assets/icons/Repeat'
import Search from '../assets/icons/Search'
import SearchSetting from '../assets/icons/SearchSetting'
import SettingsIcon from '../assets/icons/Setting'
import SparkelsIcon from '../assets/icons/Sparkels'
import StartIcon from '../assets/icons/StarIcon'
import TrendUpThin from '../assets/icons/trendUpThin'
import TrendDownGreen from '../assets/icons/tredDownGreen'
import UploadIcon from '../assets/icons/Upload'
import UrlIcon from '../assets/icons/UrlIcon'
import UsernameAvatar from '../assets/icons/UsernameAvatar'
import WizrLogoText from '../assets/icons/WizrLogoText'
import RobotIcon from '../assets/icons/robot'
import SentIcon from '../assets/icons/sent'
import wizrlogo from '../assets/icons/wizrlogo'
import DownArrowColored from '../assets/icons/Down_Arrow_Colored'
import Envelope from '../assets/icons/Envelope'
import Ticket from '../assets/icons/Ticket'
import Receipt from '../assets/icons/Receipt'

type IconType =
  | 'arrowDown'
  | 'breakCrumbArrow'
  | 'check'
  | 'circleCancel'
  | 'close'
  | 'copy'
  | 'createNew'
  | 'delete'
  | 'dislike'
  | 'done'
  | 'edit'
  | 'file'
  | 'folder'
  | 'folderCreate'
  | 'hamburger'
  | 'home'
  | 'info'
  | 'like'
  | 'more'
  | 'notes'
  | 'passwordAvatar'
  | 'play'
  | 'repeat'
  | 'robot'
  | 'search'
  | 'searchSetting'
  | 'sent'
  | 'settings'
  | 'sparkels'
  | 'star'
  | 'upload'
  | 'url'
  | 'userNameAvatar'
  | 'question'
  | 'bell'
  | 'wizrlogo'
  | 'wizrlogoText'
  | 'Packages'
  | 'trendUpRed'
  | 'trendDownGreen'
  | 'downArrowColored'
  | 'envelope'
  | 'ticket'
  | 'receipt'

const iconMapping: { [key: string]: any } = {
  arrowDown: ArrowDown,
  breakCrumbArrow: BreadcrumbArrow,
  check: Check,
  circleCancel: CircleCancel,
  close: CloseIcon,
  copy: Copy,
  createNew: CreateNew,
  delete: DeleteIcon,
  dislike: DislikeIcon,
  done: DoneIcon,
  edit: EditIcon,
  file: FileIcon,
  folder: FolderIcon,
  folderCreate: FolderCreate,
  hamburger: Hamburger,
  home: HomeIcon,
  info: InfoIcon,
  like: LikeIcon,
  more: MoreIcon,
  notes: NotesIcon,
  passwordAvatar: PasswordAvatar,
  play: PlayIcon,
  repeat: RepeatIcon,
  robot: RobotIcon,
  search: Search,
  searchSetting: SearchSetting,
  sent: SentIcon,
  settings: SettingsIcon,
  sparkels: SparkelsIcon,
  star: StartIcon,
  upload: UploadIcon,
  url: UrlIcon,
  userNameAvatar: UsernameAvatar,
  question: QuestionIcon,
  bell: BellIcon,
  wizrlogo:wizrlogo,
  wizrlogoText:WizrLogoText,
  Packages:Packages,
  trendUpRed: TrendUpThin,
  trendDownGreen: TrendDownGreen,
  downArrowColored: DownArrowColored,
  envelope: Envelope,
  ticket: Ticket,
  receipt: Receipt
}

interface IWizrIcon {
  name: IconType
  selected?: boolean
  stroke?: string
}

const WizrIcon = ({ name, selected, stroke }: IWizrIcon): JSX.Element => {
  const IconComponent = iconMapping[name]
  return <IconComponent stroke={stroke}/>
}

export default WizrIcon
