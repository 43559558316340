import { Layout, Input, InputProps } from 'antd'
import { useState } from 'react'
import { styled } from 'styled-components'

interface IWizrInput extends InputProps {
  placeholder?: string
  preComponent?: any
  postComponent?: any
  customStyle?: any
  onChange?: any
  value?: any
  onPressEnter?: any
  background?: any
  onSelect?: any
}
const StyledInput = styled(Input)<any>`
  border-radius: 10px;
  height: 52px;
  padding: 14px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.25px;
  background: ${(p) => (p.background ? p.background : '#FFF')};
`

const WizrInput = ({
  placeholder = '',
  preComponent = null,
  postComponent = null,
  customStyle = null,
  onChange,
  value = '',
  onPressEnter,
  background,
  onSelect,

  ...rest
}: IWizrInput): JSX.Element => {
  return (
    <StyledInput
      style={customStyle}
      className='wizr-input'
      placeholder={placeholder}
      prefix={preComponent}
      suffix={postComponent}
      background={background}
      {...rest}
      onChange={(ev: any) => {
        onChange(ev?.target?.value)
      }}
      value={value}
      onPressEnter={onPressEnter}
      required={true}
      onSelect={onSelect}

    />
  )
}

export default WizrInput
