import { Tag } from 'antd';
import { styled } from 'styled-components';
import { useState } from 'react';
import WizrText from './WizrText';
import { text } from 'stream/consumers';

interface IWizrTag {
  data: string[];
  checkable?: boolean;
  onClick?: any;
  background?: any;
  type?:any;
  borderradius?:any
  textColor?: any
  marginTop?: any
  textType?:any
  padding?:any
  checkedValue?:any
  style?:any
}

const { CheckableTag } = Tag;

const StyledTags = styled(CheckableTag)<{ background?: any,borderradius?:any, marginTop?: any ,padding?:any, checkable?: any}>`
  border-radius:${(p) => (p.borderradius ? p.borderradius : '16px')};
  border: 0px solid #000;
  padding:${(p) => (p.padding ? p.padding : '5px')};
  margin-top: ${(p) => (p.marginTop ? p.marginTop : '5px')};
  background-color: ${(p) => (p.background ? p.background : p.checked ? 'black' :  '#000000')};
  &:hover {
    background-color: ${(p) => (p.background ? p.background : p.checked ? 'black' : '#000000')};
  }
`;

const WizrTag = ({ data, checkable, onClick, background ,type,borderradius, textColor, marginTop,textType='caption',padding='3px 11px',checkedValue,style}: IWizrTag,): JSX.Element => {
  const [checked, setChecked] = useState<any>(checkedValue?checkedValue:"");
  const colors=["#DCFCE7","#FEF3C7","#FEE2E2"]
  //const colorIndex=0

  const handleTagChange = (key: number) => {    
    if(checkable){
      setChecked(key);
    }
    if (onClick) {
      onClick(data[key]);
    }
  };  

  
  

  return (
    <>
    
      {data &&
        data.length > 0 &&
        data.map((item, key) => {          
          const colorIndex = key % colors.length;  
          return (
            <StyledTags
              key={key}
              checked={type === "colorfull" ? false : checkable ? checked === key : true}
              onClick={() => handleTagChange(key)}
              background={type ==="colorfull" ? colors[colorIndex] : (checked === key) ? '#000' : background}
              borderradius={borderradius}
              marginTop={marginTop}
              padding={padding}
              style={style}
            >
              <WizrText type={textType} textColor={type === "colorfull" ? "#1D1F22" : !checkable ? textColor ? textColor : '#FFF' : checked === key ? '#FFF' : '#000' }>
                {item}
              </WizrText>
            </StyledTags>
          );
        })}
    </>
  );
};

export default WizrTag;
