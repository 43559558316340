const DeleteIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='16' viewBox='0 0 12 16' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.1958 5.00031H1.86181C1.40176 5.00031 1.02881 5.37325 1.02881 5.83331V12.5003C1.02881 13.881 2.1481 15.0003 3.52881 15.0003H8.52881C9.19185 15.0003 9.82773 14.7369 10.2966 14.2681C10.7654 13.7992 11.0288 13.1633 11.0288 12.5003V5.83331C11.0288 5.37325 10.6559 5.00031 10.1958 5.00031Z'
        stroke='#1D1F22'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M9.02881 3.00027L8.92281 2.78927C8.37471 1.69282 7.25412 1.00018 6.02831 1.00018C4.80249 1.00018 3.68191 1.69282 3.13381 2.78927L3.02881 3.00027H9.02881Z'
        stroke='#1D1F22'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.11181 8.33331C5.11181 7.91909 4.77602 7.58331 4.36181 7.58331C3.94759 7.58331 3.61181 7.91909 3.61181 8.33331H5.11181ZM3.61181 11.6663C3.61181 12.0805 3.94759 12.4163 4.36181 12.4163C4.77602 12.4163 5.11181 12.0805 5.11181 11.6663H3.61181ZM8.44581 8.33331C8.44581 7.91909 8.11002 7.58331 7.69581 7.58331C7.28159 7.58331 6.94581 7.91909 6.94581 8.33331H8.44581ZM6.94581 11.6663C6.94581 12.0805 7.28159 12.4163 7.69581 12.4163C8.11002 12.4163 8.44581 12.0805 8.44581 11.6663H6.94581ZM9.02881 2.25031C8.61459 2.25031 8.27881 2.58609 8.27881 3.00031C8.27881 3.41452 8.61459 3.75031 9.02881 3.75031V2.25031ZM11.0288 3.75031C11.443 3.75031 11.7788 3.41452 11.7788 3.00031C11.7788 2.58609 11.443 2.25031 11.0288 2.25031V3.75031ZM3.02881 3.75031C3.44302 3.75031 3.77881 3.41452 3.77881 3.00031C3.77881 2.58609 3.44302 2.25031 3.02881 2.25031V3.75031ZM1.02881 2.25031C0.614595 2.25031 0.278809 2.58609 0.278809 3.00031C0.278809 3.41452 0.614595 3.75031 1.02881 3.75031V2.25031ZM3.61181 8.33331V11.6663H5.11181V8.33331H3.61181ZM6.94581 8.33331V11.6663H8.44581V8.33331H6.94581ZM9.02881 3.75031H11.0288V2.25031H9.02881V3.75031ZM3.02881 2.25031H1.02881V3.75031H3.02881V2.25031Z'
        fill='#1D1F22'
      />
    </svg>
    // <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    //   <path
    //     fillRule='evenodd'
    //     clipRule='evenodd'
    //     d='M16.167 9.00012H7.833C7.37295 9.00012 7 9.37307 7 9.83312V16.5001C7 17.8808 8.11929 19.0001 9.5 19.0001H14.5C15.163 19.0001 15.7989 18.7367 16.2678 18.2679C16.7366 17.799 17 17.1632 17 16.5001V9.83312C17 9.37307 16.6271 9.00012 16.167 9.00012Z'
    //     stroke='#999B9F'
    //     strokeWidth='1.5'
    //     strokeLinecap='round'
    //     strokeLinejoin='round'
    //   />
    //   <path
    //     fillRule='evenodd'
    //     clipRule='evenodd'
    //     d='M15 7.00008L14.894 6.78908C14.3459 5.69263 13.2253 5 11.9995 5C10.7737 5 9.6531 5.69263 9.105 6.78908L9 7.00008H15Z'
    //     stroke='#999B9F'
    //     strokeWidth='1.5'
    //     strokeLinecap='round'
    //     strokeLinejoin='round'
    //   />
    //   <path
    //     d='M11.083 12.3331C11.083 11.9189 10.7472 11.5831 10.333 11.5831C9.91879 11.5831 9.583 11.9189 9.583 12.3331H11.083ZM9.583 15.6661C9.583 16.0803 9.91879 16.4161 10.333 16.4161C10.7472 16.4161 11.083 16.0803 11.083 15.6661H9.583ZM14.417 12.3331C14.417 11.9189 14.0812 11.5831 13.667 11.5831C13.2528 11.5831 12.917 11.9189 12.917 12.3331H14.417ZM12.917 15.6661C12.917 16.0803 13.2528 16.4161 13.667 16.4161C14.0812 16.4161 14.417 16.0803 14.417 15.6661H12.917ZM15 6.25012C14.5858 6.25012 14.25 6.58591 14.25 7.00012C14.25 7.41434 14.5858 7.75012 15 7.75012V6.25012ZM17 7.75012C17.4142 7.75012 17.75 7.41434 17.75 7.00012C17.75 6.58591 17.4142 6.25012 17 6.25012V7.75012ZM9 7.75012C9.41421 7.75012 9.75 7.41434 9.75 7.00012C9.75 6.58591 9.41421 6.25012 9 6.25012V7.75012ZM7 6.25012C6.58579 6.25012 6.25 6.58591 6.25 7.00012C6.25 7.41434 6.58579 7.75012 7 7.75012V6.25012ZM9.583 12.3331V15.6661H11.083V12.3331H9.583ZM12.917 12.3331V15.6661H14.417V12.3331H12.917ZM15 7.75012H17V6.25012H15V7.75012ZM9 6.25012H7V7.75012H9V6.25012Z'
    //     fill='#999B9F'
    //   />
    // </svg>
  )
}

export default DeleteIcon
