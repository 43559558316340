import React from 'react'

const HomeIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.66796 9.62302V15.8163C1.61132 18.2529 3.53942 20.2747 5.97596 20.3337H16.0266C18.4632 20.2747 20.3913 18.2529 20.3346 15.8163V9.62302C20.3374 7.79565 19.4848 6.07228 18.0306 4.96568L13.9506 2.49235C12.138 1.39143 9.86323 1.39143 8.05063 2.49235L3.97196 4.96568C2.51776 6.07228 1.6652 7.79565 1.66796 9.62302Z'
        stroke='#999B9F'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13.8734 15.2444C13.2002 16.1557 12.1344 16.6934 11.0014 16.6934C9.86838 16.6934 8.80261 16.1557 8.12939 15.2444'
        stroke='#999B9F'
        stroke-width='1.5'
        stroke-linecap='round'
      />
    </svg>
  )
}

export default HomeIcon
