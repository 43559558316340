const Packages = ():JSX.Element=>{
    return (<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4959_24044)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3617 2.32124C14.1242 2.17873 13.8275 2.17873 13.59 2.32124L8.58996 5.32124C8.36405 5.45678 8.22583 5.70091 8.22583 5.96436L8.22598 5.97929L8.22583 5.99436V11.0397L3.60174 13.8142C3.56189 13.8371 3.52435 13.8637 3.48955 13.8933C3.46058 13.918 3.43368 13.9447 3.40902 13.9732C3.29489 14.1048 3.22583 14.2765 3.22583 14.4644V19.9644C3.22583 20.2278 3.36405 20.4719 3.58996 20.6075L8.58996 23.6075C8.75193 23.7047 8.93585 23.7336 9.10822 23.7026C9.14747 23.6956 9.18628 23.6854 9.22425 23.672C9.2768 23.6536 9.32741 23.6294 9.37501 23.5995L13.9758 20.839L18.59 23.6075C18.8275 23.75 19.1242 23.75 19.3617 23.6075L24.3617 20.6075C24.5876 20.4719 24.7258 20.2278 24.7258 19.9644V14.4644C24.7258 14.2009 24.5876 13.9568 24.3617 13.8212L19.7258 11.0397V5.96436C19.7258 5.70091 19.5876 5.45678 19.3617 5.32124L14.3617 2.32124ZM14.7258 19.5397L18.2258 21.6397V17.889L14.7258 15.789V19.5397ZM13.2258 13.1397V9.389L9.72583 7.289V11.0397L13.2258 13.1397ZM12.5359 14.475L9.01122 16.6109L5.43357 14.4644L8.97583 12.339L12.5359 14.475ZM9.72583 17.9318L13.2258 15.8108V19.5397L9.72583 21.6397V17.9318ZM8.22583 21.6397V17.889L4.72583 15.789V19.5397L8.22583 21.6397ZM13.9758 8.08971L10.4336 5.96436L13.9758 3.839L17.5181 5.96436L13.9758 8.08971ZM14.7258 9.389L18.2258 7.289V11.0397L14.7258 13.1397V9.389ZM15.4336 14.4644L18.9758 12.339L22.5181 14.4644L18.9758 16.5897L15.4336 14.4644ZM19.7258 17.889L23.2258 15.789V19.5397L19.7258 21.6397V17.889Z" fill="#999B9F"/>
    </g>
    <defs>
    <clipPath id="clip0_4959_24044">
    <rect width="24" height="24" fill="white" transform="translate(0.97583 0.964355)"/>
    </clipPath>
    </defs>
    </svg>
    
    )
}
export default Packages