const EndUser = ({stroke="#999B9F"  }:any):JSX.Element =>{
return(
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3223 3.375C13.3223 4.68668 12.2589 5.75 10.9473 5.75C9.63559 5.75 8.57227 4.68668 8.57227 3.375C8.57227 2.06332 9.63559 1 10.9473 1C12.2589 1 13.3223 2.06332 13.3223 3.375Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6973 11.7748C15.6973 13.5748 13.5703 15.0418 10.9473 15.0418C8.32427 15.0418 6.19727 13.5788 6.19727 11.7748C6.19727 9.97079 8.32427 8.50879 10.9473 8.50879C13.5703 8.50879 15.6973 9.97079 15.6973 11.7748Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3474 5.55288C18.3575 6.13141 18.0169 6.65864 17.4853 6.8871C16.9537 7.11556 16.3367 6.9999 15.9239 6.59439C15.5112 6.18888 15.3846 5.57405 15.6036 5.03849C15.8227 4.50293 16.3438 4.15297 16.9224 4.15288C17.7024 4.14623 18.3402 4.77289 18.3474 5.55288V5.55288Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.54734 5.55288C3.53719 6.13141 3.87787 6.65864 4.40947 6.8871C4.94107 7.11556 5.55804 6.9999 5.97079 6.59439C6.38354 6.18888 6.51011 5.57405 6.29109 5.03849C6.07207 4.50293 5.55095 4.15297 4.97234 4.15288C4.19234 4.14623 3.55449 4.77289 3.54734 5.55288Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17.6642 13.3618C17.2516 13.3248 16.8872 13.6294 16.8503 14.0419C16.8133 14.4545 17.1178 14.8189 17.5304 14.8558L17.6642 13.3618ZM20.4473 11.7748L21.1928 11.8565C21.1988 11.8021 21.1988 11.7472 21.1928 11.6928L20.4473 11.7748ZM17.5303 8.69479C17.1178 8.73175 16.8133 9.09616 16.8503 9.50872C16.8872 9.92128 17.2516 10.2258 17.6642 10.1888L17.5303 8.69479ZM4.36415 14.8558C4.77672 14.8189 5.08122 14.4545 5.04428 14.0419C5.00733 13.6294 4.64294 13.3248 4.23038 13.3618L4.36415 14.8558ZM1.44727 11.7748L0.701757 11.6928C0.695779 11.7472 0.695768 11.8021 0.701726 11.8565L1.44727 11.7748ZM4.23035 10.1888C4.64291 10.2258 5.00731 9.92128 5.04427 9.50872C5.08123 9.09616 4.77675 8.73175 4.36419 8.69479L4.23035 10.1888ZM17.5304 14.8558C19.3642 15.02 20.9923 13.6867 21.1928 11.8565L19.7017 11.6931C19.5902 12.7114 18.6844 13.4531 17.6642 13.3618L17.5304 14.8558ZM21.1928 11.6928C20.9916 9.86314 19.3637 8.53055 17.5303 8.69479L17.6642 10.1888C18.6842 10.0974 19.5898 10.8388 19.7018 11.8568L21.1928 11.6928ZM4.23038 13.3618C3.21013 13.4531 2.30435 12.7114 2.19281 11.6931L0.701726 11.8565C0.902229 13.6867 2.53032 15.02 4.36415 14.8558L4.23038 13.3618ZM2.19277 11.8568C2.30468 10.8388 3.21035 10.0974 4.23035 10.1888L4.36419 8.69479C2.5308 8.53055 0.902905 9.86314 0.701757 11.6928L2.19277 11.8568Z" fill={stroke}/>
</svg>
)
}
export default EndUser