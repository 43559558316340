import { Select } from 'antd'
import { useState, useRef, useEffect } from 'react'
import WizrFlexLayout from './WizrFlexLayout'
import styled from 'styled-components'
import { PlusOutlined, DownOutlined } from '@ant-design/icons'

interface IWizrTagInput {
  onChange?: any
  placeHolder?: string
  open?: boolean
  options?: any
  value?: any
  showSearch?: boolean
  maxTagCount?: number
}

const StyledSelect = styled(Select)`
  /* Add your custom styles here */
  .ant-select-selection-placeholder {
    text-align: left;
  }
  width: 100%;
  .ant-select-selector {
    min-height: 48px; /* Adjust the height as needed */
  }
  overflow: auto; /* Hide any overflow content */
`

const WizrTagInput = ({
  value,
  onChange,
  placeHolder = 'Enter tags',
  open,
  options,
  showSearch = false,
  maxTagCount
}: IWizrTagInput) => {
  const [selectedTags, setSelectedTags] = useState([])

  const handleTagChange = (value: any) => {
    setSelectedTags(value)
    onChange(value)
  }

  useEffect(() => {
    setSelectedTags(value)
  }, [value])

  const selectRef = useRef<any>(null)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent the default behavior of the Enter key
      selectRef.current?.focus() // Set focus back to the Select component
    }
  }

  return (
    <StyledSelect
      showSearch={showSearch}
      mode={'tags'}
      ref={selectRef}
      onKeyDown={handleKeyDown}
      placeholder={placeHolder}
      value={selectedTags}
      onChange={handleTagChange}
      defaultValue={selectedTags}
      open={open}
      options={options}
      size='large'
      tokenSeparators={[',']}
      suffixIcon={''}
      maxTagCount={maxTagCount}
      
    />
  )
}

export default WizrTagInput
