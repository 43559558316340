import { Avatar } from 'antd'
import WizrFlexSpacing from './WizrFlexSpacing'
import WizrText from './WizrText'

interface IWizrAvatar {
  name: string
  background?: string
  gapSize?: number
  showText?: boolean
  justifyContent?: string
}

const WizrAvatar = ({
  name,
  background,
  gapSize = 16,
  showText = true,
  justifyContent = 'flex-start',
}: IWizrAvatar): JSX.Element => {
  return (
    <WizrFlexSpacing
      size={gapSize}
      flexDirection='row'
      justifyContent={justifyContent}
      alignContent='flex-start'
      background={background}
      flex='none'
    >
      <Avatar style={{ backgroundColor: 'black' }}>{ name ? name?.split('')?.[0]?.toUpperCase() : 'NA'}</Avatar>
      <WizrText type='body2'>{showText ? name : null}</WizrText>
    </WizrFlexSpacing>
  )
}

export default WizrAvatar
