import { styled } from 'styled-components'
import WizrText from '../WizrText'
import WizrButton from '../WizrButton'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrTextArea from '../WizrTextArea'
import { useState } from 'react'

interface IAddCommentsForm {
  fileName?: string
  onSubmit?: any
  onCancel?: () => void
}

const StyledWizrFlexSpacing = styled(WizrFlexSpacing)`
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
`

const AddCommentsForm = ({ onCancel, onSubmit }: IAddCommentsForm): JSX.Element => {
  const [comment, setComment] = useState<string>('')

  return (
    <WizrFlexLayout
      flexDirection='coloumn'
      background='#FFF'
      justifyContent='space-evenly'
      alignItems='flex-start'
    >
      <WizrTextArea value={comment} onChange={(value: any) => setComment(value)} />
      <StyledWizrFlexSpacing
        justifyContent='flex-end'
        flexDirection='row'
        size='large'
        background='#FFF'
      >
        <WizrButton type='text' text='Skip' onClick={onCancel} />
        <WizrButton text='Proceed' onClick={() => onSubmit(comment)} />
      </StyledWizrFlexSpacing>
    </WizrFlexLayout>
  )
}

export default AddCommentsForm
