import React from 'react'

const BreadcrumbArrow = (): JSX.Element => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 7L15 12L10 17'
        stroke='#999B9F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default BreadcrumbArrow
