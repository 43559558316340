const ZendeskIcon =():JSX.Element=> {
    return (
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_7179_22856)">
<path d="M13.7734 3.06445V16.4505L24.8594 3.06545L13.7734 3.06445ZM0.859375 3.06645C0.859375 6.12645 3.34238 8.61045 6.40238 8.61045C9.46238 8.61045 11.9444 6.12645 11.9454 3.06645H0.859375ZM11.9454 7.87345L0.859375 21.2565H11.9454V7.87345ZM19.3154 15.7135C18.5874 15.7132 17.8666 15.8564 17.194 16.1349C16.5214 16.4134 15.9103 16.8217 15.3956 17.3364C14.881 17.8512 14.4728 18.4624 14.1944 19.135C13.9161 19.8076 13.773 20.5285 13.7734 21.2565H24.8594C24.8594 18.1965 22.3794 15.7135 19.3164 15.7135H19.3154Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_7179_22856">
<rect width="24" height="24" fill="white" transform="translate(0.859375 0.160156)"/>
</clipPath>
</defs>
</svg>
    )
}
export default ZendeskIcon