import { Space, SpaceProps } from 'antd'
import { styled } from 'styled-components'

interface IStyledSpace {
  justifyContent?: string
  alignItems?: string
  alignContent?: string
  flexDirection?: string
  background?: string
  textAlign?: string
  flex?: number | string
  alignSelf?: string
  overflow?: string
}

interface IWizrFlexSpace extends SpaceProps, IStyledSpace {
  children: JSX.Element | JSX.Element[]
}

const StyledSpace = styled(Space)<IStyledSpace>`
  flex: ${(p) => (p.flex ? p.flex : 1)};
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : 'space-between')};
  align-content: ${(p) => (p.alignContent ? p.alignContent : 'space-between')};
  align-items: ${(p) => (p.alignItems ? p.alignItems : 'space-between')};
  flex-direction: ${(p) => (p.flexDirection ? p.flexDirection : 'column')};
  background: ${(p) => (p.background ? p.background : '#F2F4F9')};
  text-align: ${(p) => (p.textAlign ? p.textAlign : 'center')};
  align-self: ${(p) => (p.alignSelf ? p.alignSelf : 'stretch')};
  width: 100%;
  overflow: ${(p) => (p.overflow ? p.overflow : 'hidden')};
  .ant-space-item {
    display: flex !important;
  }
`

const WizrFlexSpacing = ({ children, ...props }: IWizrFlexSpace): JSX.Element => {
  return <StyledSpace {...props}>{children}</StyledSpace>
}

export default WizrFlexSpacing
