import styled from "styled-components"
import WizrDropDown from "../WizrDropDown"
import WizrFlexLayout from "../WizrFlexLayout"
import WizrText from "../WizrText"
import InfoIcon from "../../assets/icons/Info"
import WizrButton from "../WizrButton"
import WizrInput from "../WizrInput"
import { useContentLoadingSelector, useListAccessSelectors } from "../../redux/selectors/content"
import { Button, Checkbox, Dropdown, Menu } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { useState } from "react"
import { dispatchAccessList, dispatchCreateRole, dispatchRolesList, dispatchUpdateRole } from "../../redux/actions/content"
const StyledDropDown = styled(WizrDropDown)`
 height:50px;
.ant-select-selector{
    height:50px !important;
    background-color:#F2F4F9 !important;
   .ant-select-selection-placeholder{
    margin-top:10px;
   }
}
 `

const AddRoles= ({roleDetails,onCancel}:any):JSX.Element=>{
const loading = useContentLoadingSelector()    
    const [roleName,setRoleName]=useState(roleDetails?.role_name?roleDetails?.role_name:'')
    const [visible, setVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>(roleDetails?.access_privileges?roleDetails?.access_privileges:[]);

  // Function to toggle item selection
  const toggleItemSelection = (item: string) => {
    const selectedIndex = selectedItems.indexOf(item);
    const newSelectedItems = [...selectedItems];
    if (selectedIndex === -1) {
      newSelectedItems.push(item);
    } else {
      newSelectedItems.splice(selectedIndex, 1);
    }
    setSelectedItems(newSelectedItems);
  };
    const accessList =  useListAccessSelectors()
    const data = accessList.reduce((acc:any, curr:any) => {
        const existingCategory = acc.find((item:any) => item.category === curr.access_group);
        if (existingCategory) {
          existingCategory.items.push(curr.privilege_key);
        } else {
          acc.push({ category: curr.access_group, items: [curr.privilege_key] });
        }
        return acc;
      }, []);
      const sortedData = [
        ...data.filter((category:any) => category.category !== 'app'), // Filter out 'app' category
        ...data.filter((category:any) => category.category === 'app'), // Add 'app' category at the end
      ];
      const renderMenu = () => (
        <Menu>
          {sortedData.map((categoryData:any) => (
            <Menu.SubMenu key={categoryData.category} title={categoryData.category}>
              {categoryData.items.map((item:any) => (
                <Menu.Item key={item}>
                    <div style={{overflow:"auto"}}>
                  <Checkbox
                    checked={selectedItems.includes(item)}
                    onChange={() => toggleItemSelection(item)}
                  >
                    {item}
                  </Checkbox></div>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}
        </Menu>
      );
    
      const handleVisibleChange = (flag: boolean) => {
        setVisible(flag);
      };
const createRole = async()=>{
    if (roleDetails?.id){
        const key = roleName.replace(/\s+/g, '').toLowerCase();
    

        const payload = {  
            ...roleDetails,          
                role_key: key,
                role_name: roleName,
                access_privileges: selectedItems,
                    }
       await dispatchUpdateRole(payload)
    }
    else{
    const key = roleName.replace(/\s+/g, '').toLowerCase();
    

    const payload = {
        
            role_key: key,
            role_name: roleName,
            role_description: "",
            access_privileges: selectedItems,
            tenant_id: "WIZR-BASIC-TENANT",
            status:"active"
        
    }
    await dispatchCreateRole(payload)}
    await dispatchRolesList()
    onCancel()

}
    
    return(
        <WizrFlexLayout background="white" style={{padding:"10px 30px",gap:"20px"}}>
            <WizrFlexLayout background="white" style={{gap:"15px"}}>
<div style={{gap:"5px",display:"flex"}}>
            <WizrText type='body2'>Role</WizrText><InfoIcon/></div>   
            <WizrInput placeholder='Enter Roles ' background={"#F2F4F9"} value={roleName} onChange={(value:any)=>{setRoleName(value)}} ></WizrInput>
            </WizrFlexLayout>
            <WizrFlexLayout background="white" style={{gap:"15px"}}>
<div style={{gap:"5px",display:"flex"}}>
            <WizrText type='body2'>Access</WizrText><InfoIcon/></div>   
            {/* <StyledDropDown options={[]}  value = {""} placeHolder='Select Tier' onChange={(value:any)=>{}}/> */}
            <Dropdown
      overlay={renderMenu()}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      trigger={["click"]}
    >
      <Button style={{backgroundColor:"#F2F4F9",textAlign:"left",height:"50px"}} >
        
        <WizrText type='body2' textColor="#707276">Select items</WizrText> <DownOutlined />
        
      </Button>
    </Dropdown>
            </WizrFlexLayout>
            <WizrFlexLayout justifyContent="flex-end" background="white" flexDirection="row">
            <WizrButton type='text' text='Cancel' onClick={()=>{onCancel()}} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text='Save' onClick={()=>{createRole()}} disabled={loading} style={{borderRadius: '20px'}}/>   
                     </WizrFlexLayout>
                    </WizrFlexLayout>
    )
}
export default AddRoles