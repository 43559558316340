import { init, RematchRootState, RematchDispatch } from '@rematch/core'
import { models, RootModel } from '.'
import createPersistPlugin from '@rematch/persist'
import storage from 'redux-persist/lib/storage'

const persistPlugin = createPersistPlugin({
  key: 'root',
  storage,
  version: 2,
  whitelist: ['persisted'],
})

export const store = init({ models, plugins: [persistPlugin as any] })
export type Store = typeof store
export type Dispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>
