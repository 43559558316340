import styled from "styled-components"
import { useContentLoadingSelector, useFeatureSettingSelector } from "../../redux/selectors/content"
import WizrFlexLayout from "../WizrFlexLayout"
import WizrInput from "../WizrInput"
import WizrText from "../WizrText"
import InfoIcon from "../../assets/icons/Info"
import WizrFlexSpacing from "../WizrFlexSpacing"
import WizrButton from "../WizrButton"
import { useState } from "react"
import { dispatchFeatureSettings, dispatchUpdatePersona } from "../../redux/actions/content"

const StyledContainer = styled(WizrFlexLayout)`
  gap: 15px;
`
const StyledWizrInput = styled(WizrInput)`
background:#F2F4F9;`
const InputContainer = styled(WizrFlexLayout)`
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  background-color: #fff;
  
`

const Persona = ({onCancel,persona,action}:any):JSX.Element=>{
    const featureData =useFeatureSettingSelector()
    const loading = useContentLoadingSelector()
   const [personaState,setPersonaState] =useState(persona?
    {name:persona?.name,description:persona?.description,language:persona?.language,tone:persona?.tone,length:persona?.length}:
    {name:'',description:'',language:'',tone:'',length:''})

    const onSave = async ()=>{
      let payload;
      if (action==='edit') {
         payload = {
            id: featureData?.id,
            data: {
            persona_id:persona?.persona_id,
            name: personaState?.name,
            description: personaState?.description,
            length: personaState?.length,
            tone: personaState?.tone,
            language:personaState?.language,
            status: persona?.status
            }
            }}
            else{
              payload = {
                id: featureData?.id,
                data: {
                name: personaState?.name,
                description: personaState?.description,
                length: personaState?.length,
                tone: personaState?.tone,
                language:personaState?.language,
                status: 'active'
                }
                }
            }
           await  dispatchUpdatePersona(payload)
           dispatchFeatureSettings()
           onCancel()

    }
     
    return(
        <StyledContainer background='#FFF' justifyContent='space-evenly'>
        <InputContainer>
          <div style={{gap:"10px",display:"flex"}}>
            <WizrText type='body2'>Name</WizrText><InfoIcon/></div>
           <StyledWizrInput placeholder='Enter name '  value={personaState?.name} onChange={(value:any)=>{
             setPersonaState({...personaState,
                name:value})
            }} ></StyledWizrInput>

          </InputContainer>
          <InputContainer>
          <div style={{gap:"10px",display:"flex"}}>
            <WizrText type='body2'>Description</WizrText><InfoIcon/></div>
           <StyledWizrInput placeholder='Enter description '  value={personaState?.description} onChange={(value:any)=>{
                setPersonaState({...personaState,
                    description:value})
           }} ></StyledWizrInput>

          </InputContainer>
          <InputContainer>
          <div style={{gap:"10px",display:"flex"}}>
            <WizrText type='body2'>Length</WizrText><InfoIcon/></div>
           <StyledWizrInput placeholder='Enter length '  value={personaState?.length} onChange={(value:any)=>{
          setPersonaState({...personaState,
            length:value})
           }} ></StyledWizrInput>

          </InputContainer>
          <InputContainer>
          <div style={{gap:"10px",display:"flex"}}>
            <WizrText type='body2'>Tone</WizrText><InfoIcon/></div>
           <StyledWizrInput placeholder='Enter tone '  value={personaState?.tone} onChange={(value:any)=>{
          setPersonaState({...personaState,
            tone:value})
           }} ></StyledWizrInput>

          </InputContainer>
          <InputContainer>
          <div style={{gap:"10px",display:"flex"}}>
            <WizrText type='body2'>Language</WizrText><InfoIcon/></div>
           <StyledWizrInput placeholder='Enter language '  value={personaState?.language} onChange={(value:any)=>{
          setPersonaState({...personaState,
            language:value})
           }} ></StyledWizrInput>

          </InputContainer>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text='Save' onClick={()=>{onSave()}} disabled={loading} style={{borderRadius: '20px'}}/>
          </WizrFlexSpacing>
        </StyledContainer>
    )
}

export default Persona