const QuestionIcon = ({stroke = '#999B9F'}:any): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M1.66675 12.3333V9.66666C1.66675 5.24838 5.24847 1.66666 9.66675 1.66666H12.3334C16.7517 1.66666 20.3334 5.24838 20.3334 9.66666V12.3333C20.3334 16.7516 16.7517 20.3333 12.3334 20.3333H9.66675C5.24847 20.3333 1.66675 16.7516 1.66675 12.3333Z'
        stroke= {stroke}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.3335 7.852C8.3335 6.39867 9.5335 5.59867 11.3295 5.66667C12.6256 5.71161 13.6562 6.76981 13.6668 8.06667C13.7295 9.27938 13.1548 10.4369 12.1508 11.12C11.2974 11.6667 10.8464 12.6649 11.0002 13.6667'
        stroke={stroke}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.0002 17C10.6326 17 10.3335 16.7009 10.3335 16.3333C10.3335 15.9657 10.6326 15.6667 11.0002 15.6667C11.3678 15.6667 11.6668 15.9657 11.6668 16.3333C11.6668 16.7009 11.3678 17 11.0002 17Z'
        fill={stroke}
      />
      <path
        d='M11.0001 15C11.7365 15 12.3334 15.597 12.3334 16.3333C12.3334 17.0697 11.7365 17.6667 11.0001 17.6667C10.2637 17.6667 9.66675 17.0697 9.66675 16.3333C9.66675 15.597 10.2637 15 11.0001 15Z'
        fill={stroke}
      />
    </svg>
  )
}

export default QuestionIcon
