import { BlobServiceClient } from '@azure/storage-blob'
import jwt_decode from 'jwt-decode'
import { extractIdToken, getTenantId, getTenantIdwithtokenId } from '../utils'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'

class WizrAzureConnector {
  
  blobServiceClient: any

  constructor(sasUrl?: string) {

    // const token = extractIdToken(location.hash) 
    // // console.log('token', token);
    
    // localStorage.setItem('id_token', token)   
    
    if(sasUrl){
      this.blobServiceClient = new BlobServiceClient(sasUrl)
    }
  }

  convertuploadToPercent(size: number, uploadedSize: number) {
    return Math.round((uploadedSize / size) * 100)
  }
  
  async downloadFile(folderId: string, fileData: any) {
    const blobData = await this.getBlob(folderId, fileData)
  }

  async getBlob(folderId: any, fileData: any) {
    console.log('getblob');
    
    const tenantId = getTenantId()
    const containerClientUrl = `${tenantId}/inarchive/${folderId}/${fileData?.file_path}`
   // const containerClientUrl = `/inarchive/${folderId}`
    const containerClient = this.blobServiceClient.getContainerClient(containerClientUrl)
    const blobClient = containerClient.getBlobClient(fileData?.file_name)
    const response = await blobClient.download()
    const blob = await response.blobBody
    return blob
  }

  async uploadFile(
    folderId: any,
    fileData: any,
    onFileUploadProgress: any,
    tags: Array<string>,
    uploadFolder: any,
  ) {
    const tenantId = getTenantId()
    const containerClientUrl = uploadFolder ? `${tenantId}/${uploadFolder}/${folderId&&uploadFolder==='inbox'?folderId:''}` : `${tenantId}`
    const containerClient = this.blobServiceClient.getContainerClient(containerClientUrl)
    const blobClient = containerClient.getBlobClient(fileData?.name)
    const blockBlobClient = blobClient.getBlockBlobClient()
    const blobData = new Blob([fileData.originFileObj], {
      type: fileData.originFileObj.type,
    })    
    if (blobData) {
      const jwtDecodeData: { [key: string]: any } = jwt_decode(localStorage.id_token)
      const tagsAsString = tags.join(',')
      const metadata: any = {
        tags: tagsAsString,
        user_id: jwtDecodeData?.oid,
        user_name: jwtDecodeData?.name,
      }

      const response = blockBlobClient.uploadData(blobData, {
        onProgress: (ev: any) =>
          onFileUploadProgress
            ? onFileUploadProgress(
                this.convertuploadToPercent(fileData?.size, ev?.loadedBytes),
                fileData?.name,
              )
            : '',
        blobHTTPHeaders: {
          blobContentType: fileData?.type,
        },
        metadata: metadata,
      })
      return response
    }
    return null
  }
  async uploadFileWithUrl(
    url: string,
    sasToken: string,
    fileData: any,
    tags: Array<string>,
    onFileUploadProgress: any,
    metadata:any,
    timestamp: any
  ) {
    try {
      const newurl = url + '?' + sasToken;      
      const sasUrl = new BlobServiceClient(newurl);
  
      const containerClient = sasUrl.getContainerClient('');
      const blobClient = containerClient.getBlobClient(`${timestamp}/${fileData?.name}`);
      const blockBlobClient = blobClient.getBlockBlobClient();
  
      const blobData = new Blob([fileData.originFileObj], {
        type: fileData.originFileObj.type,
      });
  
      if (blobData) {
        // const jwtDecodeData: { [key: string]: any } = jwt_decode(localStorage.id_token);
        // const metadata: any = {
        //   tags: tags.join(','),
        //   user_id: jwtDecodeData?.oid,
        //   user_name: jwtDecodeData?.name,
        // };
  
        const response = await blockBlobClient.uploadData(blobData, {
          onProgress: (ev: any) =>
            onFileUploadProgress
              ? onFileUploadProgress(
                  this.convertuploadToPercent(fileData?.size, ev?.loadedBytes),
                  fileData?.name,
                )
              : '',
          blobHTTPHeaders: {
            blobContentType: fileData?.type,
          },
          metadata: metadata,
        });
        return {message: 'upload success', status: 'success'}
      }
    } catch (error: any) {
      // console.log('Error uploading file to Azure Blob Storage:', error);
  
      // Log more details about the error
      if (error) {
        console.log('Error details:', error.statuscode);
      }

      // console.log('error')
      return {message: 'upload failed', status: 'failed'}
    }    
    return null;
  }
  async uploadBulkRun(fileData:any,metadata:any){
    try{ 
    
    
    const tenantId=getTenantId()
    const containerClientUrl = `inbox/${metadata?.app_id}`
    const containerClient = this.blobServiceClient.getContainerClient(containerClientUrl)
    const blobClient = containerClient.getBlobClient(fileData?.file?.name)
    
    
    const blockBlobClient = blobClient.getBlockBlobClient()
    
    const blobData = new Blob([fileData?.file?.originFileObj], {
      type: fileData?.file?.originFileObj?.type,
    })
    if (blobData) {
      const jwtDecodeData: { [key: string]: any } = jwt_decode(localStorage.id_token)
      const response = blockBlobClient.uploadData(blobData, {
        
        blobHTTPHeaders: {
          blobContentType: fileData?.type,
        },
        metadata: metadata,
      })
      return response
    }
    return null}
    catch{
      alert("fileupload failed")
    }
  }

  async downloadFileTemplate(app_id:any,app_name:any) {
    try{
    const blobData: any = await this.getBlobTemplate(app_id,app_name);

    // Create a Blob from the array buffer
    const blob = new Blob([blobData], { type: 'application/octet-stream' });

    // Create a link element
    const link = document.createElement('a');

    // Set the link's href to a URL representing the Blob
    link.href = window.URL.createObjectURL(blob);

    // Set the Content-Disposition header to force download
    link.setAttribute('download', `${app_name}_V1.csv`);

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);}
    catch{
      console.log("failes");
      
    }
}

  async getBlobTemplate(app_id:any,app_name:any) {
    try{
    
    const containerClientUrl = `template/active/${app_id}`
    const containerClient = this.blobServiceClient.getContainerClient(containerClientUrl)
    const blobClient = containerClient.getBlobClient(`${app_name}_V1.csv`)
  
    
    const downloadBlockBlobResponse = await blobClient.download()
    const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody)
   
    return downloaded}
    catch{
      console.log("failed");
      
    }
  }
  
  async downloadFileOutput(app_id:any,file_name:any) {
    try{
    const blobData: any = await this.getBlobOutput(app_id,file_name);

    // Create a Blob from the array buffer
    const blob = new Blob([blobData], { type: 'application/octet-stream' });

    // Create a link element
    const link = document.createElement('a');

    // Set the link's href to a URL representing the Blob
    link.href = window.URL.createObjectURL(blob);

    // Set the Content-Disposition header to force download
    link.setAttribute('download', `${file_name}`);

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);}
    catch{
      console.log("failes");
      
    }
}

  async getBlobOutput(app_id:any,file_name:any) {
    try{
    
    const containerClientUrl = `outbox/${app_id}`
    const containerClient = this.blobServiceClient.getContainerClient(containerClientUrl)
    const blobClient = containerClient.getBlobClient(file_name)
    
    const downloadBlockBlobResponse = await blobClient.download()
    const downloaded = await blobToString(await downloadBlockBlobResponse.blobBody)
   
    return downloaded}
    catch{
      console.log("failed");
      
    }
  }
  
}




const wizrAzureConnectorInstance = new WizrAzureConnector(generateAzureUploadUrl() || '')
const WizrAzurOrchestratorInstance = new WizrAzureConnector(generateAzureOrchastratorUrl() || '')
const WizrAzurOrchestratorBulkRun =  new WizrAzureConnector(generateAzureOrchastratorUrlBulkRun(extractIdToken(location.hash) )||"")
const WizrAzurOrchestratorBulkRunwithUrl =  new WizrAzureConnector("")

function generateAzureUploadUrl() {
  const azureSASUrl =
    process.env.REACT_APP_AZURE_FILE_CONTAINER_URL +
    '/' +
    '?' +
    process.env.REACT_APP_AZURE_FILE_CONTAINER_SAS_TOKEN
  return azureSASUrl
}

function generateAzureOrchastratorUrl() {
  const tenantId = getTenantId()

  const azureSASUrl =
    process.env.REACT_APP_AZURE_FILE_CONTAINER_URL +
    '/' +
    
    '?' +
    process.env.REACT_APP_AZURE_FILE_CONTAINER_SAS_TOKEN

  return azureSASUrl
}

function generateAzureOrchastratorUrlBulkRun(token: any) {
  
  const tenantId = getTenantIdwithtokenId(token)
  

  const azureSASUrl =
    process.env.REACT_APP_AZURE_FILE_CONTAINER_URL_BULKRUN +
    '/' +
    tenantId +
    '?' +
    process.env.REACT_APP_AZURE_FILE_CONTAINER_SAS_TOKEN_BULKRUN
  //const azureSASUrl="drtfyguhj"

  return azureSASUrl
}

async function blobToString(blob: any) {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = (ev: any) => {
      resolve(ev.target.result);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
}


export { WizrAzurOrchestratorInstance, wizrAzureConnectorInstance ,WizrAzurOrchestratorBulkRun, WizrAzurOrchestratorBulkRunwithUrl}