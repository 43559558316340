import { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import WizrText from '../WizrText'
import WizrButton from '../WizrButton'
import WizrFlexSpacing from '../WizrFlexSpacing'
import { Input, Form } from 'antd'
import WizrTagInput from '../WizrTagInput'
import WizrFlexLayout from '../WizrFlexLayout'
import InfoIcon from '../../assets/icons/Info'
import { dispatchTags } from '../../redux/actions/content'
import { useTagsSelector } from '../../redux/selectors/content'

interface IAddTagsForm {
  onSubmit?: any
  onCancel?: () => void
  fileDetail?: any
}

const StyledWizrFlexSpacing = styled(WizrFlexSpacing)`
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
`

const AddTagsForm = ({ fileDetail, onCancel, onSubmit }: IAddTagsForm): JSX.Element => {
  const [currentTags, setCurrentTags] = useState<any>()
 
  
 const tags = useTagsSelector()
 const tagList = tags?.map((item: any) => ({
  label: item,
  value: item,
}))
  return (
    <WizrFlexLayout background='#FFF' justifyContent='space-evenly'>
      <WizrTagInput
        //open={false}
        placeHolder='Enter tags'
        value={fileDetail?.tags}
        onChange={setCurrentTags}
        options={tagList}
      />
      <br />
      <WizrText type='caption'>
        {' '}
        <InfoIcon /> If you are uploading multiple files at one time, all the files will have the
        same tags mentioned during upload. The tags can be changed individually after import if
        needed
      </WizrText>
      <br />
      <StyledWizrFlexSpacing
        justifyContent='flex-end'
        flexDirection='row'
        size='large'
        background='#FFF'
      >
        <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
        <WizrButton text='Confirm' onClick={() => onSubmit(currentTags)} style={{borderRadius: '20px'}}/>
      </StyledWizrFlexSpacing>
    </WizrFlexLayout>
  )
}

export default AddTagsForm
