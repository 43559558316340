import { createContext, useEffect } from 'react'
import { notification } from 'antd'
import { useNotificationStateSelector } from '../../redux/selectors/common'
import { dispatchNotificationState } from '../../redux/actions/common'

type GlobalNotificationContext = object

const initalState: GlobalNotificationContext = {}

const WizrNotificationContext = createContext(initalState)

const WizrNotificationProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [api, contextHolder] = notification.useNotification()
  const notificationState = useNotificationStateSelector()

  const onNotificationClose = () =>
    dispatchNotificationState({
      message: null,
      description: null,
      type: null,
    })

  useEffect(() => {
    const { message, description, type } = notificationState
    if (message) {
      const callerFn = type === 'success' ? api.success : api.error
      callerFn({
        message,
        description,
        onClose: onNotificationClose,
        duration: 3,
      })
    }
  }, [notificationState])

  return (
    <WizrNotificationContext.Provider value={{}}>
      {contextHolder}
      {children}
    </WizrNotificationContext.Provider>
  )
}

export { WizrNotificationProvider }
