import { Select } from 'antd'
import { styled } from 'styled-components'

interface IWizrDropDown {
  placeHolder?: string
  options: any
  onChange?: any
  value?: any
  defaulValue?: any
  className?: any
  style?: any
  mode?: any
  disabled?: boolean
}

const StyledDropDown = styled(Select)`
  .ant-select-selection-placeholder {
    text-align: left !important;
  }

  .ant-select-selection-item {
    text-align: left;
  }
  width: 100%;
`
const WizrDropDown = ({ placeHolder, options, onChange, value, defaulValue, className, style,mode,disabled }: IWizrDropDown) => {
  return (
    <StyledDropDown
    disabled={disabled}
    mode={mode}
      style={style}
      placeholder={placeHolder}
      options={options}
      onChange={onChange}
      popupMatchSelectWidth={false}
      defaultValue={defaulValue}
      value={value}
      className={className}
    />
  )
}

export default WizrDropDown
