import React from 'react'

const InfoIcon = ({stroke='#999B9F'}:any): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 7V9C1 12.3137 3.68629 15 7 15H9C12.3137 15 15 12.3137 15 9V7C15 3.68629 12.3137 1 9 1H7C3.68629 1 1 3.68629 1 7Z'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 8V12' stroke={stroke} strokeWidth='1.5' strokeLinecap='round' />
      <path
        d='M8 5.5C7.7243 5.5 7.5 5.2757 7.5 5C7.5 4.7243 7.7243 4.5 8 4.5C8.2757 4.5 8.5 4.7243 8.5 5C8.5 5.2757 8.2757 5.5 8 5.5Z'
        fill={stroke}
      />
      <path
        d='M8 4C8.55228 4 9 4.44772 9 5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4Z'
        fill={stroke}
      />
    </svg>
  )
}

export default InfoIcon
