const DoneIcon = (): JSX.Element => {
  return (
    <svg width='45' height='44' viewBox='0 0 45 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.5 22.0002C15.5002 18.6607 17.8594 15.7864 21.1348 15.1351C24.4102 14.4838 27.6895 16.2369 28.9673 19.3223C30.2451 22.4077 29.1655 25.966 26.3887 27.8212C23.6119 29.6764 19.9113 29.3117 17.55 26.9502C16.2373 25.6373 15.4999 23.8568 15.5 22.0002Z'
        stroke='#999B9F'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19 22.0002L21.333 24.3332L26 19.6672'
        stroke='#999B9F'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default DoneIcon
