import { styled } from 'styled-components'
import WizrText from '../WizrText'
import WizrButton from '../WizrButton'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrDropDown from '../WizrDropDown'
import { useEffect, useState } from 'react'
import { useListTenantSelector } from '../../redux/selectors/content'
import { dispatchListTenants } from '../../redux/actions/content'

interface IselectTenant {
  onselectTenant?: any
  onCancel?: () => void
}

const TextContainer = styled.div`
  padding: 20px 0px;
  background: #fff;
  text-align:left;
  gap: 10px;
`
const StyledDropDown = styled(WizrDropDown)`
.ant-select-selector{
    background-color:#F2F4F9 !important;
    width:300px !important;
   
}
 `
const selectTenant = ({ onselectTenant, onCancel }: IselectTenant): JSX.Element => {
    const [tenant,setTenant] = useState()
   
    const tenants = useListTenantSelector()
  
    useEffect(() => {
      dispatchListTenants()
    }, [])
    const dropDownConverter = (value: string[]) => {
      const result =
        value?.length > 0 &&
        value?.map((item: any, key: number) => ({
          label: item?.tenant_name,
          value: item?.tenant_id,
        }))
      return result
    }
  return (
    <WizrFlexSpacing background='#FFF'>
      <TextContainer>
        
        <StyledDropDown options={dropDownConverter(tenants)} value={tenant} placeHolder='Tenant' onChange={(value:any)=>setTenant(value)}/>

      </TextContainer>
      <WizrFlexSpacing flexDirection='row' size='large' justifyContent='flex-end' background='#FFF'>
        {/* <WizrButton type='text' text='Cancel' onClick={onCancel} /> */}
        <WizrButton text='Proceed' onClick={()=>{onselectTenant(tenant)}} style={{borderRadius: '20px'}}/>
      </WizrFlexSpacing>
    </WizrFlexSpacing>
  )
}

export default selectTenant
