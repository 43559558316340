const StartIcon = (): JSX.Element => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.1581 8.515L12.3091 5L10.4601 8.515C10.3295 8.77186 10.1404 8.99449 9.90808 9.165C9.67464 9.33638 9.40451 9.45107 9.11908 9.5L5.30908 10.247L7.96908 13.428C8.33285 13.8267 8.50451 14.3643 8.43908 14.9L7.93308 19L11.4411 17.3C11.7117 17.1689 12.0084 17.1006 12.3091 17.1C12.5912 17.1009 12.869 17.1694 13.1191 17.3L16.7091 18.895L16.2031 14.844C16.1391 14.3095 16.3111 13.7736 16.6741 13.376L19.3091 10.247L15.4991 9.5C15.2133 9.45122 14.9428 9.33652 14.7091 9.165C14.4771 8.99438 14.2884 8.77175 14.1581 8.515Z'
        stroke='#999B9F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
export default StartIcon
