import React from 'react'

const NotesIcon = ({stroke='#999B9F'}:any): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='23' viewBox='0 0 22 23' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.41075 1.33335H14.7721C14.8485 1.33335 14.9245 1.33824 15.0001 1.34802C17.9965 1.55757 20.3233 4.04428 20.3334 7.04802V15.62C20.3224 18.7828 17.7522 21.3393 14.5894 21.3334H7.41075C4.24743 21.3393 1.67704 18.782 1.66675 15.6187V7.04802C1.67704 3.88471 4.24743 1.32745 7.41075 1.33335Z'
        stroke={stroke}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M20.3334 7.79799C20.7476 7.79799 21.0834 7.46221 21.0834 7.04799C21.0834 6.63378 20.7476 6.29799 20.3334 6.29799V7.79799ZM15.0001 7.04799H14.2501C14.2501 7.46221 14.5859 7.79799 15.0001 7.79799V7.04799ZM15.7501 1.34799C15.7501 0.933778 15.4143 0.597992 15.0001 0.597992C14.5859 0.597992 14.2501 0.933778 14.2501 1.34799H15.7501ZM9.66675 16.75C10.081 16.75 10.4167 16.4142 10.4167 16C10.4167 15.5858 10.081 15.25 9.66675 15.25V16.75ZM5.66675 15.25C5.25253 15.25 4.91675 15.5858 4.91675 16C4.91675 16.4142 5.25253 16.75 5.66675 16.75V15.25ZM15.0001 12.75C15.4143 12.75 15.7501 12.4142 15.7501 12C15.7501 11.5858 15.4143 11.25 15.0001 11.25V12.75ZM5.66675 11.25C5.25253 11.25 4.91675 11.5858 4.91675 12C4.91675 12.4142 5.25253 12.75 5.66675 12.75V11.25ZM20.3334 6.29799H15.0001V7.79799H20.3334V6.29799ZM15.7501 7.04799V1.34799H14.2501V7.04799H15.7501ZM9.66675 15.25H5.66675V16.75H9.66675V15.25ZM15.0001 11.25H5.66675V12.75H15.0001V11.25Z'
        fill={stroke}
      />
    </svg>
  )
}

export default NotesIcon
