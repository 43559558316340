/* !
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { AuthProvider } from './authContext';

const root = ReactDOM.createRoot(document.getElementById('root'))
// document.addEventListener('DOMContentLoaded', () => {
//   if (typeof window.wizrAI_webChatLarge_show === 'function') {
//     console.log('hiiiiiiiiiiiiiiiiiiiiii');
    
//       window.wizrAI_webChatLarge_show('embedded-app-container', 'aVO2_MxZI3_iSEya3YWLsF7fkNU-qVq6fPSGy_ZaEvc');
//   } else {
//       console.error('wizrAI_webChatLarge_show function is not defined');
//   }
// });

root.render(
  <BrowserRouter>
    {/* <AuthProvider> */}
      <App />
    {/* </AuthProvider> */}
  </BrowserRouter>,
)
