import React from 'react'

const SettingsIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.0002 13.6667C9.5274 13.6667 8.3335 12.4728 8.3335 11C8.3335 9.52724 9.5274 8.33333 11.0002 8.33333C12.4729 8.33333 13.6668 9.52724 13.6668 11C13.6668 11.7072 13.3859 12.3855 12.8858 12.8856C12.3857 13.3857 11.7074 13.6667 11.0002 13.6667Z'
        stroke='#999B9F'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.0894 16.4173V14.628C18.0894 14.1849 18.2654 13.76 18.5787 13.4467L19.8441 12.1813C20.4965 11.5289 20.4965 10.4711 19.8441 9.81866L18.5787 8.55333C18.2654 8.24002 18.0894 7.81508 18.0894 7.37199V5.58133C18.0894 4.65864 17.3414 3.91066 16.4187 3.91066H14.6281C14.185 3.91066 13.76 3.73464 13.4467 3.42133L12.1814 2.15599C11.529 1.50356 10.4712 1.50356 9.81874 2.15599L8.5534 3.42133C8.24009 3.73464 7.81516 3.91066 7.37207 3.91066H5.5814C5.13808 3.91066 4.71294 4.08686 4.39959 4.40046C4.08624 4.71406 3.91038 5.13934 3.91074 5.58266V7.37199C3.91073 7.81508 3.73471 8.24002 3.4214 8.55333L2.15607 9.81866C1.50364 10.4711 1.50364 11.5289 2.15607 12.1813L3.4214 13.4467C3.73471 13.76 3.91073 14.1849 3.91074 14.628V16.4173C3.91074 17.34 4.65872 18.088 5.5814 18.088H7.37207C7.81516 18.088 8.24009 18.264 8.5534 18.5773L9.81874 19.8427C10.4712 20.4951 11.529 20.4951 12.1814 19.8427L13.4467 18.5773C13.76 18.264 14.185 18.088 14.6281 18.088H16.4174C16.8607 18.0883 17.286 17.9125 17.5996 17.5991C17.9132 17.2858 18.0894 16.8606 18.0894 16.4173Z'
        stroke='#999B9F'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default SettingsIcon
