const Search = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5 10.7655C5.00003 8.01511 6.94296 5.64777 9.64053 5.1113C12.3381 4.57483 15.039 6.01866 16.0913 8.55977C17.1437 11.1009 16.2544 14.0315 13.9674 15.5593C11.6804 17.0871 8.63257 16.7866 6.688 14.8415C5.60716 13.7604 4.99998 12.2942 5 10.7655Z'
        stroke='#999B9F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5288 16.5295L18.9998 19.0005'
        stroke='#999B9F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Search
