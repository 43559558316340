const UrlIcon = (): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <g clip-path='url(#clip0_1989_4215)'>
        <path
          d='M8.50033 14.667C4.81833 14.667 1.83366 11.6823 1.83366 8.00029C1.83366 4.31829 4.81833 1.33362 8.50033 1.33362C12.1823 1.33362 15.167 4.31829 15.167 8.00029'
          stroke='#1D1F22'
          stroke-width='1.5'
          stroke-linecap='round'
        />
        <path
          d='M9.52083 14.1596C9.19683 14.4943 8.85016 14.667 8.50016 14.667C8.15016 14.667 7.8035 14.4943 7.48016 14.1596C7.15616 13.8243 6.86216 13.333 6.61483 12.7143C6.36683 12.095 6.17016 11.361 6.03616 10.5516C5.89947 9.70811 5.83169 8.85485 5.8335 8.00031C5.8335 7.12498 5.90216 6.25765 6.03616 5.44898C6.17016 4.64032 6.36683 3.90565 6.61483 3.28632C6.86216 2.66698 7.15616 2.17632 7.4795 1.84098C7.8035 1.50698 8.15016 1.33365 8.50016 1.33365C8.85016 1.33365 9.19683 1.50632 9.52016 1.84098C9.84416 2.17632 10.1382 2.66765 10.3855 3.28632C10.6335 3.90565 10.8302 4.63965 10.9635 5.44898C11.0975 6.25765 11.1668 7.12498 11.1668 8.00031M1.8335 8.00031H7.16683M15.1668 8.00031H9.8335'
          stroke='#1D1F22'
          stroke-width='1.5'
          stroke-linecap='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M11.3136 11.8711C11.3136 11.592 11.0212 11.4809 10.9172 11.7369C10.5969 12.5242 10.757 13.4254 11.3287 14.0542C11.6826 14.4443 12.1847 14.6668 12.7114 14.6668C13.238 14.6668 13.7402 14.4443 14.094 14.0542C14.8575 13.2139 14.8575 11.931 14.094 11.0907L12.8407 9.74851C12.8071 9.7126 12.7601 9.69223 12.7109 9.69223C12.6618 9.69223 12.6148 9.7126 12.5812 9.74851C12.5466 9.7864 12.5276 9.8359 12.5278 9.88717V11.8711C12.5388 12.2065 12.2765 12.4875 11.9412 12.4996H11.9003C11.565 12.4875 11.3026 12.2065 11.3136 11.8711Z'
          stroke='#1D1F22'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1989_4215'>
          <rect width='16' height='16' fill='black' transform='translate(0.5 0.000305176)' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UrlIcon
