const CloseIcon = (): JSX.Element => {
  return (
    <svg width='45' height='44' viewBox='0 0 45 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M15.5 22.0002C15.5002 18.6607 17.8594 15.7864 21.1348 15.1351C24.4102 14.4838 27.6895 16.2369 28.9673 19.3223C30.2451 22.4077 29.1655 25.966 26.3887 27.8212C23.6119 29.6764 19.9113 29.3117 17.55 26.9502C16.2373 25.6373 15.4999 23.8568 15.5 22.0002Z'
        stroke='#999B9F'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M19.3448 24.0937C19.0518 24.3866 19.0517 24.8614 19.3446 25.1544C19.6374 25.4473 20.1123 25.4474 20.4052 25.1546L19.3448 24.0937ZM23.0302 22.5306C23.3232 22.2377 23.3233 21.7629 23.0304 21.4699C22.7376 21.177 22.2627 21.1769 21.9698 21.4697L23.0302 22.5306ZM21.9695 21.47C21.6767 21.763 21.6769 22.2379 21.9699 22.5307C22.2629 22.8235 22.7378 22.8233 23.0305 22.5303L21.9695 21.47ZM25.6545 19.9043C25.9473 19.6113 25.9471 19.1364 25.6541 18.8436C25.3611 18.5508 24.8862 18.551 24.5935 18.844L25.6545 19.9043ZM23.0302 21.4697C22.7373 21.1769 22.2624 21.177 21.9696 21.4699C21.6767 21.7629 21.6768 22.2377 21.9698 22.5306L23.0302 21.4697ZM24.5948 25.1546C24.8877 25.4474 25.3626 25.4473 25.6554 25.1544C25.9483 24.8614 25.9482 24.3866 25.6552 24.0937L24.5948 25.1546ZM21.9696 22.5304C22.2624 22.8233 22.7373 22.8234 23.0302 22.5306C23.3232 22.2377 23.3233 21.7629 23.0304 21.4699L21.9696 22.5304ZM20.4054 18.8439C20.1126 18.551 19.6377 18.5509 19.3448 18.8437C19.0518 19.1366 19.0517 19.6114 19.3446 19.9044L20.4054 18.8439ZM20.4052 25.1546L23.0302 22.5306L21.9698 21.4697L19.3448 24.0937L20.4052 25.1546ZM23.0305 22.5303L25.6545 19.9043L24.5935 18.844L21.9695 21.47L23.0305 22.5303ZM21.9698 22.5306L24.5948 25.1546L25.6552 24.0937L23.0302 21.4697L21.9698 22.5306ZM23.0304 21.4699L20.4054 18.8439L19.3446 19.9044L21.9696 22.5304L23.0304 21.4699Z'
        fill='#999B9F'
      />
    </svg>
  )
}

export default CloseIcon
