import styled from 'styled-components'
import ExtensionLogo from '../assets/icons/ExtensionLogo'
import WizrFlexLayout from '../components/WizrFlexLayout'
import WizrText from '../components/WizrText'
import TickIconWithBorder from '../assets/icons/TickIconwithBorder'

const ExtensionRedirectScreen = (): JSX.Element => {
  // const StyledWizrFlexLayout = styled(WizrFlexLayout)`
  // background-color:red !important;

  // maxWidth:"50%" !important;
  // maxHeight:"50%" !important;
  // `

  const StyledWizrFlexLayout = styled.div`
    display: flex;
    height: 60%;
    width: 50%;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    flex-direction: column;
    padding: 2% 5%;
    gap: 30px;
  `

  return (
    <WizrFlexLayout id='wizr_extension_redirect' justifyContent='center' alignItems='center'>
      {/* <WizrFlexLayout
        alignItems='center'
        style={{ padding: '40px', gap: '40px' }}
        justifyContent='flex-start'
      >
        <ExtensionLogo />
        <WizrText type='h6' textColor='black'>
          We are excited to have you{' '}
        </WizrText>
        <WizrText type='h4'>Make your customer service super-human</WizrText>
        <WizrText type='body1'>
          Convert customer support to revenue center using Generative AI{' '}
        </WizrText>
        <WizrText type='body1'>Feel free to close this tab </WizrText>
      </WizrFlexLayout> */}
      <StyledWizrFlexLayout>
        {/* <WizrFlexLayout style={{maxWidth:"60%"}} justifyContent='center' alignItems='center' background='white'> */}
        <ExtensionLogo />
        {/* </WizrFlexLayout> */}
        {/* <WizrFlexLayout style={{maxWidth:"60%"}} justifyContent='center' alignItems='center' background='white'> */}
        <TickIconWithBorder />
        <WizrFlexLayout background='white' style={{ maxHeight: '65px' }}>
          <WizrText type='sub1' textColor='black'>
            {' '}
            Welcome back!{' '}
          </WizrText>
          <WizrText type='cardData' textColor='black'>
            {' '}
            Your login was successful, and we’ve received the details of your tool.
            <br />
            Please close the window and return to your ticket system to use extention.
          </WizrText>
          {/* </WizrFlexLayout> */}
        </WizrFlexLayout>
      </StyledWizrFlexLayout>
    </WizrFlexLayout>
    // </WizrFlexLayout>
  )
}
export default ExtensionRedirectScreen
