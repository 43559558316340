import { Select } from 'antd'
import WizrInput from '../WizrInput'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { styled } from 'styled-components'
import WizrText from '../WizrText'
import { useEffect, useMemo, useState } from 'react'
import { dispatchFiltervalues, dispatchListAllIntents, dispatchTicketBasedOnIntent } from '../../redux/actions/content'
import { useControlroomFilter, useListAllIntents } from '../../redux/selectors/content'

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`

interface ICreateAppForm {
    appData?: { [key: string]: any }
    onFormSubmission: (params: { [key: string]: any }) => void
    onCancel: () => void
}

const ControlRoomFilter = ({ appData, onCancel }: ICreateAppForm): JSX.Element => {

    const [productInputState, setProductInputState] = useState<any>()

    const intents = useListAllIntents()
    const controlroomFilter = useControlroomFilter();

    useEffect(() => {
        setProductInputState(controlroomFilter)
    }, [controlroomFilter])
    

    function createArrayOfObjects(arr: any) {
        return arr.map((item: any) => ({ value: item[0], label: item[0] }));
    }

    const memoizedIntentsArray = useMemo(() => {
        return createArrayOfObjects(intents?.resultTable?.rows || []);
    }, [intents]);
    

    useEffect(() => {

        const queryForIntents = {
            sql: `select distinct intent from ecommerce_analytics LIMIT 1000`
        }
        dispatchListAllIntents(queryForIntents)

    }, [])




    const doUpdateFields = (name: any, value: any) => {
        setProductInputState({
            ...productInputState,
            [name]: value
        })
    }    

    const onFormSubmission = () => {

        let sqlQuery = "SELECT * FROM ecommerce_analytics WHERE (Created_date_epoch_millis BETWEEN 1711909800000 AND 1712514600000) AND ";

        //the field values
        const queryObj = productInputState;        

        // Loop through the keys and values in the object
        Object.entries(queryObj).forEach(([key, values]: any, index) => {
            // Add the opening parenthesis for each key
            sqlQuery += "(";

            // Loop through the values for each key and join them with OR condition
            values.forEach((value: any, i: any) => {
                // Add the key and value to the query
                sqlQuery += `${key} = '${value}'`;

                // Add OR condition if it's not the last value
                if (i < values.length - 1) {
                    sqlQuery += " OR ";
                }
            });

            // Add the closing parenthesis for each key
            sqlQuery += ")";

            // Add AND condition if it's not the last key
            if (index < Object.keys(queryObj).length - 1) {
                sqlQuery += " AND ";
            }
        });

        const filteredQuery = {
            sql: `${sqlQuery} ORDER BY Created_date_epoch_millis DESC`
        }
        dispatchTicketBasedOnIntent(filteredQuery)
        dispatchFiltervalues(queryObj)
        onCancel();
    }



    return (
        <StyledContainer background='#FFF' justifyContent='space-evenly'>
            <WizrFlexLayout flexDirection='row' background='white' style={{ gap: '10px' }} textAlign='flex-start'>
                <WizrFlexLayout background='white' textAlign='flex-start' alignContent='flex-start' alignItems='flex-start' style={{ gap: '8px' }}>
                    <WizrText type='body1'>Relevance</WizrText>
                    <StyledWizrTagInput
                        mode='multiple'
                        options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                        onChange={(value: any) => {
                            doUpdateFields('Relevance', value)
                        }}
                        value={productInputState?.Relevance}
                        maxTagCount={'responsive'}
                    ></StyledWizrTagInput>
                </WizrFlexLayout>
                <WizrFlexLayout background='white' textAlign='flex-start' alignContent='flex-start' alignItems='flex-start' style={{ gap: '8px' }}>
                    <WizrText type='body1'>Escalation</WizrText>
                    <StyledWizrTagInput
                        mode='multiple'
                        options={[{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]}
                        value={productInputState?.Escalate}
                        onChange={(value: any) => {
                            doUpdateFields('Escalate', value)
                        }}
                        maxTagCount={'responsive'}
                    ></StyledWizrTagInput>
                </WizrFlexLayout>
            </WizrFlexLayout>
            <WizrFlexLayout background='white' textAlign='flex-start' alignContent='flex-start' alignItems='flex-start' style={{ gap: '8px' }}>
                <WizrText type='body1'>Type</WizrText>
                <StyledWizrTagInput
                    mode='multiple'
                    options={[{ label: 'Task', value: 'task' }, { label: 'Bug', value: 'bug' }, { label: 'Issue', value: 'issue' }]}
                    value={productInputState?.Type}
                    onChange={(value: any) => {
                        doUpdateFields('Type', value)
                    }}
                    maxTagCount={'responsive'}
                ></StyledWizrTagInput>
            </WizrFlexLayout>
            <WizrFlexLayout background='white' textAlign='flex-start' alignContent='flex-start' alignItems='flex-start' style={{ gap: '8px' }}>
                <WizrText type='body1'>Category</WizrText>
                <StyledWizrTagInput
                    mode='multiple'
                    options={[{ label: 'Technical Issue', value: 'technical_issue' }, { label: 'Billing & Subscription', value: 'billing_and_subscription' }]}
                    value={productInputState?.Category}
                    onChange={(value: any) => {
                        doUpdateFields('Category', value)
                    }}
                    maxTagCount={'responsive'}
                ></StyledWizrTagInput>
            </WizrFlexLayout>
            <WizrFlexLayout background='white' textAlign='flex-start' alignContent='flex-start' alignItems='flex-start' style={{ gap: '8px' }}>
                <WizrText type='body1'>Intent</WizrText>
                <StyledWizrTagInput
                    mode='multiple'
                    options={memoizedIntentsArray}
                    value={productInputState?.Intent}
                    onChange={(value: any) => {
                        doUpdateFields('Intent', value)
                    }}
                    maxTagCount={'responsive'}
                ></StyledWizrTagInput>
            </WizrFlexLayout>
            <WizrFlexLayout background='white' textAlign='flex-start' alignContent='flex-start' alignItems='flex-start' style={{ gap: '8px' }}>
                <WizrText type='body1'>Auto Tags</WizrText>
                <StyledWizrTagInput
                    mode='multiple'
                    options={[{ label: 'Login Issue', value: 'login_issue' }, { label: 'Forgot password', value: 'forgot_password' }]}
                    value={productInputState?.Tags}
                    onChange={(value: any) => {
                        doUpdateFields('Tags', value)
                    }}
                    maxTagCount={'responsive'}
                ></StyledWizrTagInput>
            </WizrFlexLayout>
            <WizrFlexLayout background='white' textAlign='flex-start' alignContent='flex-start' alignItems='flex-start' style={{ gap: '8px' }}>
                <WizrText type='body1'>Sentiment</WizrText>
                <StyledWizrTagInput
                    mode='multiple'
                    options={[{ label: 'Positive', value: 'positive' }, { label: 'Negative', value: 'negative' }, { label: 'Neutral', value: 'neutral' }]}
                    value={productInputState?.Sentiment}
                    onChange={(value: any) => {
                        doUpdateFields('Sentiment', value)
                    }}
                    maxTagCount={'responsive'}
                ></StyledWizrTagInput>
            </WizrFlexLayout>
            <WizrFlexLayout background='white' textAlign='flex-start' alignContent='flex-start' alignItems='flex-start' style={{ gap: '8px' }}>
                <WizrText type='body1'>Ticket Priority</WizrText>
                <StyledWizrTagInput
                    mode='multiple'
                    options={[{ label: 'Critical', value: 'critical' }, { label: 'High', value: 'high' }, { label: 'Medium', value: 'medium' }, { label: 'Low', value: 'low' }]}
                    value={productInputState?.Ticket_Priority}
                    onChange={(value: any) => {
                        doUpdateFields('Ticket_Priority', value)
                    }}
                    maxTagCount={'responsive'}
                ></StyledWizrTagInput>
            </WizrFlexLayout>
            <WizrFlexSpacing
                justifyContent='flex-end'
                flexDirection='row'
                size='large'
                background='#FFF'
                style={{ padding: '8px' }}
            >
                <WizrButton type='text' text='Cancel' onClick={onCancel} style={{ borderRadius: '20px' }} />
                <WizrButton htmlType='submit' text='Save' onClick={onFormSubmission} style={{ borderRadius: '20px' }} />
            </WizrFlexSpacing>
        </StyledContainer>
    )
}

export default ControlRoomFilter


const StyledWizrTagInput = styled(Select)`
  /* Add your custom styles here */
  .ant-select-selection-placeholder {
    text-align: left;
  }
  width: 100%;
  .ant-select-selector {
    min-height: 50px;
    background: #f2f4f9 !important;
    border-radius: 16px !important;
    padding-left: 20px !important;
  }
  .ant-select-selection-item {
    background: white !important;
    border-radius: 20px !important;
    border: 1px solid black !important;
    gap: 8px !important;
    padding-right: 8px !important;
  }
  .ant-select-selection-overflow {
    gap: 8px !important;
  }
  overflow: auto; /* Hide any overflow content */
`