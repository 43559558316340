import { Form } from 'antd'
import { useEffect, useState } from 'react'
import WizrInput from '../WizrInput'
import WizrTextArea from '../WizrTextArea'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { styled } from 'styled-components'
import WizrText from '../WizrText'
import WizrDropDown from '../WizrDropDown'
import { dispatchEmbeddingModelList, dispatchListAutoTags } from '../../redux/actions/content'
import { useAgentTagListSelector, useAutoTagListSelector, useEmbeddingModelListSelector } from '../../redux/selectors/content'
import InfoIcon from '../../assets/icons/Info'

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
  padding: 20px;
`


interface ICreateUpdateFolderForm {
  folderName?: string
  folderDescription?: string
  AutoTag?: any
  AgentTag?: any
  embeddingModel?: string
  onFormSubmission: (params: { [key: string]: any }) => void
  onCancel: () => void
}

const CreateUpdateFolderForm = ({
  folderName,
  folderDescription,
  AutoTag,
  AgentTag,
  embeddingModel,
  onFormSubmission,
  onCancel,
}: ICreateUpdateFolderForm): JSX.Element => {
  const [form] = Form.useForm()

  const autoTagData = useAutoTagListSelector()
  const agentTagData =  useAgentTagListSelector()

  const [currentTicketState,setCurrentTicketState] = useState<any>({
    name:folderName,
    description:folderDescription,
    auto_tag:AutoTag
  })

  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item.name,
        value: item.app_feature_id,
        tenant_feature_id: item.tenant_feature_id
      }))
    return result
  }
  const dropDownConverterEmbedding = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: item.display_name,
        value: item.id,
      }))
    return result
  }
  

  useEffect(() => {
    form.setFieldValue('TicketFolderName', folderName)
    form.setFieldValue('TicketFolderDescription', folderDescription)
    form.setFieldValue('AutoTag', AutoTag)
   // form.setFieldValue('AgentTag', AgentTag)
    form.setFieldValue('embeddingModel',embeddingModel)
  }, [folderName, folderDescription, AutoTag])

  useEffect(() => {
    dispatchListAutoTags('autotag')
   // dispatchListAgentTags('agenttag')
    dispatchEmbeddingModelList()
  }, [])
  const embeddingModelList = useEmbeddingModelListSelector()

  return (
    <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFormSubmission}
        onFinishFailed={() => null}
        autoComplete='off'
      >
        <Form.Item
          name='TicketFolderName'
          rules={[{ required: true, message: 'Please Enter a Folder Name', whitespace: true }]}
        >
          <WizrFlexLayout background='white' textAlign='left' style={{gap:'10px'}}>
                      <WizrText type='body2' textColor='#707276'>Group Name</WizrText>

          <StyledWizrTagInput
            placeholder='Enter Folder Name'
            onChange={(value: string) =>{ 
              setCurrentTicketState({...currentTicketState,name:value})
              form.setFieldsValue({ TicketFolderName: value })}}
            value={currentTicketState?.name}
          />
          </WizrFlexLayout>
        </Form.Item>
        <Form.Item name='TicketFolderDescription' rules={[{ required: false, whitespace: true }]}>
        <WizrFlexLayout background='white' textAlign='left' style={{gap:'10px'}}>

        <WizrText type='body2' textColor='#707276'>Description</WizrText>
          <WizrTextArea
            border='0px solid var(--grayscale-300, #f2f4f9)'
            background=' #f2f4f9'
            placeholder='Description'
            onChange={(value: string) => {
              setCurrentTicketState({...currentTicketState,description:value})
              form.setFieldsValue({ TicketFolderDescription: value })}}
            value={currentTicketState?.description}
          /></WizrFlexLayout>
        </Form.Item>
        <Form.Item name='embeddingModel' rules={[{ required: false, whitespace: true }]}>
        <WizrFlexLayout background='white' textAlign='left' style={{gap:'10px'}}>

        <WizrText type='body2' textColor='#707276'>Embedding Model</WizrText>
        <StyledDropDown
              value={AutoTag}
              options={dropDownConverterEmbedding(embeddingModelList)}
              onChange={(value: string) => { form.setFieldsValue({ embeddingModel: value })}}
            />
            </WizrFlexLayout>
        </Form.Item>
        <Form.Item name='AutoTag' rules={[{ required: true, whitespace: true, message: 'Auto Tag is required' }]} 
                                   style={{ textAlign: 'left' }}>
          <WizrFlexLayout alignContent='flex-start' background='white' alignItems='flex-start' style={{gap: '15px'}}>
            <WizrText type='sub2'>Auto tagging</WizrText>
            <WizrFlexLayout alignContent='center' justifyContent='center'textAlign='left' style={{padding:'10px',gap:'10px',borderRadius:'8px'}} flexDirection='row' background='#F0FDF4'>
              <InfoIcon stroke='#16A34A'/>
            <WizrText type='caption2' textColor='#16A34A'>It is mandatory to select auto-tagging before you upload the file here</WizrText>

            </WizrFlexLayout>
            <WizrText type='body2'>Select auto tag</WizrText>
            <StyledDropDown
              value={AutoTag}
              options={dropDownConverter(autoTagData)}
              onChange={(value: string) => { form.setFieldsValue({ AutoTag: value })}}
            />
            </WizrFlexLayout>
        </Form.Item>
        <WizrFlexLayout alignContent='flex-start' background='white' alignItems='flex-start' style={{gap: '15px'}}>
        
            <WizrText type='body2'>Select agent Tag</WizrText>
            <StyledDropDown
              value={AgentTag}
              options={dropDownConverter(agentTagData)}
              onChange={(value: string) => { form.setFieldsValue({ AgentTag: value })}}
            />
           </WizrFlexLayout>
        
        <Form.Item>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
            <WizrButton htmlType='submit' text={'Proceed'} style={{borderRadius: '20px'}}/>
          </WizrFlexSpacing>
        </Form.Item>
      </Form>
    </StyledContainer>
  )
}

export default CreateUpdateFolderForm


const StyledDropDown = styled(WizrDropDown)`
  height: 50px;
  .ant-select-selector {
    height: 50px !important;
    background-color: #f2f4f9 !important;
    .ant-select-selection-placeholder {
      margin-top: 10px;
    }
  }
`


const StyledWizrTagInput = styled(WizrInput)`
background-color: #f2f4f9;

`
// const StyledTextArea = styled(WizrTextArea)`
// background: #f2f4f9 !important;
// `
