import { createModel } from '@rematch/core'
import { RootModel } from '..'
import jwt_decode from 'jwt-decode'
import {
  createFolder,
  deleteFile,
  deleteFolder,
  listFiles,
  listFolders,
  updateFile,
  uploadFile,
  updateFolder,
  listTemplates,
  managePrompts,
  addComments,
  listPromptHistory,
  publishPrompt,
  testPrompt,
  listLLMModels,
  getPromptDetails,
  listAppTemplates,
  createApp,
  updateApp,
  testAppTool,
  deleteApp,
  getAppDetails,
  getPublishedAppDetails,
  publishApp,
  listAppHistory,
  listAppVersions,
  getBulkTestStatus,
  testAppshareTool,
  listRunHistory,
  //listAppTags,
  getPromptTags,
  listTags,
  getAppShareDetails,
  appShareLinkGeneration,
  appShareLinkValidation,
  bulkrunStatusApp,
  uploadFilesusingLink,
  generateSASurl,
  terminateBulkrun,
  executeBulkrun,
  appApiIntegrate,
  getPromptTemplateDetails,
  listPublishedPrompts,
  listDraftPrompts,
  getAppDetailsTemplate,
  listAppPublished,
  listAppDraft,
  enableDisableUser,
  listTenantUsers,
  updateUserDetails,
  createUser,
  deleteUser,
  getUserDetails,
  getTenantList,
  createTenants,
  getAccessList,
  getRoleList,
  deleteAccess,
  createRole,
  updateRole,
  deleteRole,
  getTenantDetails,
  listPackages,
  listProducts,
  updateProducts,
  createPackage,
  updatePackages,
  updateTenants,
  deletePackages,
  deleteTenants,
  disableTenant,
  enableTenant,
  listVectorDb,
  listVisionModels,
  listVoiceModels,
  listModels,
  listIntegrations,
  masterTenantIntegrations,
  deleteIntegration,
  updateIntegration,
  createIntegration,
  listAppForPackage,
  listAppGeneral,
  setAsDefault,
  getFeatureSettings,
  updateFetaureSettingsStatus,
  createFeatureIntegrationSettings,
  createGroupFolder,
  getAutoTagList,
  setAsTemplate,
  updateTicketType,
  updateCompanyInfo,
  updatePersona,
  getFeatureAppDetails,
  getPacakageDetails,
  updateFeatureSettings,
  getEndPointUrl,
  getTenantGroups,
  saveTenantGroups,
  getGroupRowDetails,
  deleteGroupRowDetails,
  deleteGroupsFaqPastTickets,
  getIntegrationToolsForTenant,
  getIntegrationsForSelectedTool,
  listEmbeddingModels,
  importPastTickets,
  controlRoomApi,
  getSubscriptionDetails,
  deleteTicketsFaqPt,
  listTemplateVersion,
  testChatBot,
  testChatBotAdithyaBirla,
  appInsights,
  getBotDetails,
  updateBot,
  testBot,
  listWidgets,
  listWidgetTypes,
  createWidget,
  testWidget,
  getAgentList,
  getInstallationList,
  getToolsList,
  createAgent,
  createBot,
  deleteAgent,
  getWidgetDetails,
  updateWidget,
  deleteWidget,
  getBotRunHistoryDetails,
  getBotRunHistoryDetailsOfACoversation,
  listAgentConversations,
  getAgentConversationDetail,
  generateCode,
  getAutosolveConversations,
  appInsightsWhole,
  addCommentsApp,
  getBotTestHistory,
  saveFeedBackAutoSolve,
  getWidgetHistoryDetails,
  downloadCsvReport,
  getUserMappedRoles,
  updateAgentApi,
 
} from '../../api/helpers/contents'
import { v4 as uuidv4 } from 'uuid'
import {
  addValueToLocalStorage,
  getValueFromLocalStorage,
  statusChecker,
  getTenantId,
  getAccessLists,
  getCurrentTimeChatBot,
} from '../../utils'
import {
  WizrAzurOrchestratorBulkRun,
  WizrAzurOrchestratorBulkRunwithUrl,
  WizrAzurOrchestratorInstance,
} from '../../utils/wizrAzureConnector'
import axios from 'axios'

type chatMessage = {
  isSent: boolean
  text: string
  liked: boolean
  disliked: boolean
  knowledgeBase: any
  time: any
}

interface IContentModel {
  contentData: Array<any>
  contentFilesData: Array<any>
  currentFolder: any
  isContentLoading: boolean
  isInnerContentLoading: boolean
  templatesData: Array<any>
  promptHistory: Array<any>
  chatMessage: chatMessage[]
  llmModelList: Array<any>
  promptData: any
  appData: Array<any>
  appDesignerData: any
  currentAppDetails: any
  testStatus: boolean
  queryUrl: any
  appHistory: Array<any>
  appVersions: any
  bulkRunStatus: boolean
  isBulkRunContentLoading: any
  runHistory: Array<any>
  Tags: Array<any>
  appShareLinkGeneration: any
  appShareLinkValidation: any
  bulkRunResultDynamic: any
  visionModelList: Array<any>
  fileParams: any
  enduserresult: any
  appApiUrl: any
  updatedUrlforCsvUpload: any
  //promptTags:Array<any>
  vectorDB: any
  userList: Array<any>
  userDetails: any
  tenantList: any
  rolesList: any
  accessList: any
  tenantDetails: any
  productList: any
  packageList: any
  permissionValues: any
  viewUser: any
  integrationList: Array<any>
  masterIntegrationList: Array<any>
  featureSettings: any
  ticketDetail: any
  autoTagData: any
  agentTagData:any
  endpointdata: any
  tenantGroup: any
  loader: any
  pastTicketData: any
  faqData: any
  // pastTicketApps:any
  agentAssistApps: any
  ticketFaqPtDetails: any
  embeddingModelList: any
  topIntents: any
  topIncreasedIntents: any
  topDecreasedIntents: any
  topTotalIntents: any
  ticketsBasedonIntent: any
  intentsGraphData: any
  intentLists: any
  timetoResponse: any
  timetoResolve: any
  ticketEscallation: any
  firstContactResolution: any
  subscriptionDetails: any
  currentModule: any
  commonTags: any
  allIntents: any
  customerHealth: any
  chatBotHistory: any
  controlroomFilter: any
  appInsights: any
  botDetails: any
  widgetList: any
  widgetTypes: any
  agentList: any
  toolsList: any
  selectedTools: any
  widgetDetails: any
  AutosolveRunHistory: any
  AutosolveRunHistoryConversations: any
  agentConversationList: any
  agentConversationDetail: any
  autoSolveConversationDetail: any
  installationList: any
  generateCode:any
  isChatBotHistoryLoader: boolean
  cleanupResponse:any
  learnMore:any
  terminateUrl:any
  agentDetails: any
  fileURL: any  
  widgetHistoryDetails: any
  totalConversationCount:any
  totalUpvoteCount:any
  totalDownvoteCount:any
  sasToken: any
  isfileLoading: boolean
  userMappedRoles: any
}
interface ITokenDecoded {
  iss: string
}
const intialState: IContentModel = {
  contentData: [],
  contentFilesData: [],
  currentFolder: null,
  isContentLoading: false,
  isInnerContentLoading: false,
  templatesData: [],
  promptHistory: [],
  chatMessage: [],
  llmModelList: [],
  promptData: {},
  appData: [],
  appDesignerData: {},
  currentAppDetails: {},
  testStatus: false,
  queryUrl: {},
  appHistory: [],
  appVersions: [],
  bulkRunStatus: false,
  isBulkRunContentLoading: [],
  runHistory: [],
  Tags: [],
  appShareLinkGeneration: null,
  appShareLinkValidation: null,
  bulkRunResultDynamic: {},
  visionModelList: [],
  fileParams: [],
  enduserresult: [],
  appApiUrl: {},
  updatedUrlforCsvUpload: [],
  vectorDB: null,
  userList: [],
  userDetails: {},
  tenantList: [],
  rolesList: [],
  accessList: [],
  tenantDetails: {},
  productList: [],
  packageList: [],
  viewUser: {},
  permissionValues: [],
  integrationList: [],
  masterIntegrationList: [],
  featureSettings: {},
  ticketDetail: {},
  autoTagData: [],
  agentTagData:[],
  endpointdata: null,
  tenantGroup: [],
  loader: false,
  pastTicketData: [],
  faqData: [],
  // pastTicketApps:[],
  agentAssistApps: [],
  ticketFaqPtDetails: [],
  embeddingModelList: [],
  topIntents: [],
  topIncreasedIntents: [],
  topDecreasedIntents: [],
  topTotalIntents: [],
  ticketsBasedonIntent: [],
  intentsGraphData: [],
  intentLists: [],
  timetoResponse: [],
  timetoResolve: [],
  ticketEscallation: [],
  firstContactResolution: [],
  subscriptionDetails: '',
  commonTags: [],
  currentModule: '',
  customerHealth: {},
  allIntents: [],
  chatBotHistory: [],
  controlroomFilter: {},
  appInsights: [],
  botDetails: {},
  widgetList: [],
  widgetTypes: [],
  toolsList: [],
  selectedTools: [],
  agentList: [],
  widgetDetails: {},
  AutosolveRunHistory: [],
  AutosolveRunHistoryConversations: [],
  agentConversationList: [],
  agentConversationDetail: [],
  autoSolveConversationDetail: [],
  installationList: [],
  generateCode:'',
  isChatBotHistoryLoader: false,
  cleanupResponse: "",
  learnMore: {},
  terminateUrl: "",
  agentDetails: null,
  fileURL: '',
  widgetHistoryDetails: [],
  totalConversationCount:0,
  totalUpvoteCount:0,
  totalDownvoteCount:0,
  sasToken: '',
  isfileLoading: false,
  userMappedRoles: [],
}

export const contentModel = createModel<RootModel>()({
  state: intialState as IContentModel,
  reducers: {
    updateContentData(state, payload: Array<any>) {
      return {
        ...state,
        contentData: payload,
      }
    },
    updateContentFilesData(state, payload: Array<any>) {
      return {
        ...state,
        contentFilesData: payload,
      }
    },
    updateCurrentFolder(state, payload: any) {
      return {
        ...state,
        currentFolder: payload,
      }
    },
    updateContentLoading(state, payload: any) {
      return {
        ...state,
        isContentLoading: payload,
      }
    },
    updatefileLoading(state, payload: any) {
      return {
        ...state,
        isfileLoading: payload,
      }
    },
    updateInnerContentLoading(state, payload: any) {
      return {
        ...state,
        isInnerContentLoading: payload,
      }
    },
    updateTemplateData(state, payload: any) {
      return {
        ...state,
        templatesData: payload,
      }
    },
    updatePromptHistory(state, payload: any) {
      return {
        ...state,
        promptHistory: payload,
      }
    },
    updateChatMessage(state, payload: any) {
      return {
        ...state,
        chatMessage: [...state.chatMessage, payload],
      }
    },
    clearChatMessage(state, payload?: any) {
      return {
        ...state,
        chatMessage: [],
      }
    },
    updateLLMModelList(state, payload: any) {
      return {
        ...state,
        llmModelList: payload,
      }
    },
    updateVectorDb(state, payload: any) {
      return {
        ...state,
        vectorDB: payload,
      }
    },
    updatePromptData(state, payload: any) {
      return {
        ...state,
        promptData: payload,
      }
    },
    updateAppData(state, payload: any) {
      return {
        ...state,
        appData: payload,
      }
    },
    appDesignerCreationData(state, payload: any) {
      return {
        ...state,
        appDesignerData: payload,
      }
    },

    updateCurrentAppDetails(state, payload: any) {
      return {
        ...state,
        currentAppDetails: payload,
      }
    },
    updateTestStatus(state, payload: any) {
      return {
        ...state,
        testStatus: payload,
      }
    },
    updateQueryUrl(state, payload: any) {
      return {
        ...state,
        queryUrl: payload,
      }
    },
    updateAppHistory(state, payload: any) {
      return {
        ...state,
        appHistory: payload,
      }
    },
    updateAppHistoryPagination(state, payload: any) {
      return {
        ...state,
        appHistory: [...state?.appHistory||[],...payload]
      }
    },
    updateRunHistoryPagination(state, payload: any) {
      return {
        ...state,
        runHistory: [...state?.runHistory||[],...payload]
      }
    },

    updateAppVersions(state, payload: any) {
      return {
        ...state,
        appVersions: payload,
      }
    },
    updateBulkRunStatus(state, payload: any) {
      return {
        ...state,
        bulkRunStatus: payload,
      }
    },
    updateBulkRunContent(state, payload: any) {
      return {
        ...state,
        isBulkRunContentLoading: payload,
      }
    },
    updateRunHistory(state, payload: any) {
      return {
        ...state,
        runHistory: payload,
      }
    },
    updateTags(state, payload: any) {
      return {
        ...state,
        Tags: payload,
      }
    },
    updatePromptTags(state, payload: any) {
      return {
        ...state,
        promptTags: payload,
      }
    },
    updateAppSharelinkGeneration(state, payload: any) {
      return {
        ...state,
        appShareLinkGeneration: payload,
      }
    },
    updateAppShareLinkValidation(state, payload: any) {
      return {
        ...state,
        appShareLinkValidation: payload,
      }
    },
    updateBulkRunResultDynamic(state, payload: any) {
      return {
        ...state,
        bulkRunResultDynamic: payload,
      }
    },
    updateVisionModelList(state, payload: any) {
      return {
        ...state,
        visionModelList: payload,
      }
    },

    updateFileParams(state, payload: any) {
      return {
        ...state,
        fileParams: [...state.fileParams, payload],
      }
    },
    updateEnduser(state, payload: any) {
      return {
        ...state,
        enduserresult: payload,
      }
    },
    updateAppApiUrl(state, payload: any) {
      return {
        ...state,
        appApiUrl: payload,
      }
    },
    updatedUrlforCsvUpload(state, payload: any) {
      return {
        ...state,
        updatedUrlforCsvUpload: [...state.updatedUrlforCsvUpload, payload],
      }
    },
    updateUserList(state, payload?: any) {
      return {
        ...state,
        userList: payload,
      }
    },
    updateCurrentuserDetails(state, payload: any) {
      return {
        ...state,
        userDetails: payload,
      }
    },
    updateTenantList(state, payload: any) {
      return {
        ...state,
        tenantList: payload,
      }
    },
    updateRolesList(state, payload: any) {
      return {
        ...state,
        rolesList: payload,
      }
    },
    updateAccessList(state, payload: any) {
      return {
        ...state,
        accessList: payload,
      }
    },
    updateTenantDetails(state, payload: any) {
      return {
        ...state,
        tenantDetails: payload,
      }
    },
    updatePermissionValues(state, payload: any) {
      return {
        ...state,
        permissionValues: payload,
      }
    },
    updateProductList(state, payload: any) {
      return {
        ...state,
        productList: payload,
      }
    },
    updatePackageList(state, payload: any) {
      return {
        ...state,
        packageList: payload,
      }
    },
    updateViewUser(state, payload?: any) {
      return {
        ...state,
        viewUser: payload,
      }
    },
    updateIntegrationList(state, payload: any) {
      return {
        ...state,
        integrationList: payload,
      }
    },
    updateMatserIntegrationList(state, payload: any) {
      return {
        ...state,
        masterIntegrationList: payload,
      }
    },
    updateUploadedFileValues(state, payload: any) {
      return {
        ...state,
        fileParams: payload,
      }
    },
    updateFeatureSettings(state, payload: any) {
      return {
        ...state,
        featureSettings: payload,
      }
    },
    updateTicketDetails(state, payload: any) {
      return {
        ...state,
        ticketDetail: payload,
      }
    },
    updateAutoTagData(state, payload: any) {
      return {
        ...state,
        autoTagData: payload,
      }
    },
    updateAgentTagData(state, payload: any) {
      return {
        ...state,
        agentTagData: payload,
      }
    },
    
    updateEndpointdata(state, payload: any) {
      return {
        ...state,
        endpointdata: payload,
      }
    },
    updateTenantGroups(state, payload: any) {
      return {
        ...state,
        tenantGroup: payload
      }
    },
    updateLoader(state, payload: any) {
      return {
        ...state,
        loader: payload
      }
    },
    updateFaqData(state, payload: Array<any>) {
      return {
        ...state,
        faqData: payload,
      }
    },
    updatePastTicketData(state, payload: Array<any>) {
      return {
        ...state,
        pastTicketData: payload,
      }
    },
    // updatePastTicketApps(state, payload: Array<any>) {
    //   return {
    //     ...state,
    //     pastTicketApps: payload,
    //   }
    // },
    updateAgentAssistData(state, payload: Array<any>) {
      return {
        ...state,
        agentAssistApps: payload,
      }
    },
    updateFaqPtTicketDetails(state, payload: Array<any>) {
      return {
        ...state,
        ticketFaqPtDetails: payload,
      }
    },
    updateEmbeddingModelList(state, payload: Array<any>) {
      return {
        ...state,
        embeddingModelList: payload,
      }
    },
    updateTopIntents(state, payload: Array<any>) {
      return {
        ...state,
        topIntents: payload,
      }
    },
    updateTopIncreasedIntents(state, payload: Array<any>) {
      return {
        ...state,
        topIncreasedIntents: payload,
      }
    },
    updateTopDecreasedIntents(state, payload: Array<any>) {
      return {
        ...state,
        topDecreasedIntents: payload,
      }
    },
    updateTotalIntents(state, payload: Array<any>) {
      return {
        ...state,
        topTotalIntents: payload,
      }
    },
    updateTicketsBasedonIntent(state, payload: Array<any>) {
      return {
        ...state,
        ticketsBasedonIntent: payload,
      }
    },
    updateIntentsGraphData(state, payload: Array<any>) {
      return {
        ...state,
        intentsGraphData: payload,
      }
    },
    updateIntentsList(state, payload: Array<any>) {
      return {
        ...state,
        intentLists: payload,
      }
    },
    updateListAllIntents(state, payload: Array<any>) {
      return {
        ...state,
        allIntents: payload,
      }
    },
    updateTimeResponse(state, payload: Array<any>) {
      return {
        ...state,
        timetoResponse: payload,
      }
    },
    updateTimeResolve(state, payload: Array<any>) {
      return {
        ...state,
        timetoResolve: payload,
      }
    },
    updateAppInsightsList(state, payload: Array<any>) {
      return {
        ...state,
        appInsights: payload,
      }
    },
    updateTicketEscallation(state, payload: Array<any>) {
      return {
        ...state,
        ticketEscallation: payload,
      }
    },
    updateFirstContactResolution(state, payload: Array<any>) {
      return {
        ...state,
        firstContactResolution: payload,
      }
    },
    updateSubscriptionDetails(state, payload: any) {
      return {
        ...state,
        subscriptionDetails: payload,
      }
    },
    updateCurrentModule(state, payload: any) {
      return {
        ...state,
        currentModule: payload,
      }
    },
    updateCommonTags(state, payload: Array<any>) {
      return {
        ...state,
        commonTags: payload,
      }
    },
    updateCustomerHealth(state, payload: Array<any>) {
      return {
        ...state,
        customerHealth: payload,
      }
    },
    updateChatBotHistory(state, payload: Array<any>) {
      return {
        ...state,
        chatBotHistory: payload,
      }
    },
    clearChatBotHistory(state, payload?: any) {
      return {
        ...state,
        chatBotHistory: [],
      }
    },
    updateChatMessageState(state, payload: any) {
      return {
        ...state,
        chatMessage: payload,
      }
    },
    updateControlroomFilerValues(state, payload: any) {
      return {
        ...state,
        controlroomFilter: payload
      }
    },
    updateBotDetails(state, payload: any) {
      return {
        ...state,
        botDetails: payload
      }
    },
    clearChatDetails(state, payload?: any) {
      return {
        ...state,
        botDetails: {},
      }
    },
    updateWidgetList(state, payload: any) {
      return {
        ...state,
        widgetList: payload
      }
    },
    updateWidgetTypes(state, payload: any) {
      return {
        ...state,
        widgetTypes: payload
      }
    },
    updateWidgetDetails(state, payload: any) {
      return {
        ...state,
        widgetDetails: payload
      }
    },
    updategetWidgetHistoryDetails(state, payload: any){
      return {
        ...state,
        widgetHistoryDetails:payload
      }
    },
    updateAutosolveRunHistoryDetails(state, payload: any) {
      return {
        ...state,
        AutosolveRunHistory: payload
      }
    },
    updateAutosolveRunHistoryDetailsOfConversation(state, payload: any) {
      return {
        ...state,
        AutosolveRunHistoryConversations: payload
      }
    },
    updateAgentConversationList(state, payload: any) {
      return {
        ...state,
        agentConversationList: payload
      }
    },
    updateAgentConversationDetail(state, payload: any) {
      return {
        ...state,
        agentConversationDetail: payload
      }
    },
    updateAutosolveConversation(state, payload: any) {
      return {
        ...state,
        autoSolveConversationDetail: payload
      }
    },
    updateAutosolveConversationPagination(state, payload: any) {
      return {
        ...state,
        autoSolveConversationDetail: [...state?.autoSolveConversationDetail||[],...payload]
      }
    },
    updateWidgetChat(state, payload: any) {
      return {
        ...state,
        widgetTypes: payload
      }
    },
    updateAgentList(state, payload: any) {
      return {
        ...state,
        agentList: payload
      }
    },
    updateSelectedTools(state, payload: any) {
      return {
        ...state,
        selectedTools: payload
      }
    },
    updateListInstallation(state, payload: any) {
      return {
        ...state,
        installationList: payload
      }
    },
    updateGenerateCode(state, payload: any) {
      return {
        ...state,
        generateCode: payload
      }
    },
    updateFileUrl(state, payload: any) {
      return {
        ...state,
        fileURL: payload
      }
    },
    updateChatBotHistoryLoader(state, payload: any) {
      return {
        ...state,
        isChatBotHistoryLoader: payload,
      };
    },
    updateCleanupResponse(state, payload: any) {
      return {
        ...state,
        cleanupResponse: payload,
      };
    },
    updateLearnMore(state, payload: any) {
      return {
        ...state,
        learnMore: payload,
      };
    },
    updateTerminateUrl(state, payload: any) {
      return {
        ...state,
        terminateUrl: payload,
      };
    },
    clearFileParams(state, payload?: any) {
      return {
        ...state,
        fileParams: [],
      }
    },
    updateAgent(state, payload: any) {
      return {
        ...state,
        agentDetails: payload
      }
    },
    updateTotalConversationCount(state, payload: any) {
      return {
        ...state,
        totalConversationCount: payload
      }
    },
    updateTotalUpvoteCount(state, payload: any) {
      return {
        ...state,
        totalUpvoteCount: payload
    }
  },
  updateTotalDownvoteCount(state, payload: any) {
    return {
      ...state,
      totalDownvoteCount: payload
    }},
    updateSasToken(state, payload: any) {
      return {
        ...state,
        sasToken: payload
      }
    },
    updateUserMappedRoles(state, payload: any) {
      return {
        ...state,
        userMappedRoles: payload
      }
    },
    
  },
  effects: (dispatch) => ({
    createFolderEffect: async ({ currentFolderName, currentFolderDescription, currentembeddingModel }) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const payload = {
          folder_id: uuidv4(),
          folder_name: currentFolderName,
          folder_description: currentFolderDescription ? currentFolderDescription : '',
          embedding_model_id: currentembeddingModel,
          created_at: Date.now(),
          type: 'knowledge_base',
        }
        await createFolder(payload)
        dispatch.contentModel.listFoldersEffect('knowledge_base')
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Folder Added successfully.',
          type: 'success',
        })
      } catch (error: any) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Error',
          description: 'Folder creation failed. Please check the details you provided.',
          type: 'error',
        })
      }
    },
    listFoldersEffect: async (type) => {
      try {
        //dispatch.contentModel.updateContentLoading(true)
        const response = await listFolders(type)
        switch (type) {
          case 'knowledge_base':
            dispatch.contentModel.updateContentData(response?.data?.data)
            break
          case 'past_tickets':
            dispatch.contentModel.updatePastTicketData(response?.data?.data)

            break
          case 'faq':
            dispatch.contentModel.updateFaqData(response?.data?.data)

            break
        }
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Error',
          description: 'Oops. Something went wrong.',
          type: 'error',
        })
      }
    },
    listTempFoldersEffect: async (type) => {
      try {
        //dispatch.contentModel.updateContentLoading(true)
        const response = await listFolders(type)
        const responsedata: any = response?.data?.data
        switch (type) {
          case 'knowledge_base':
            dispatch.contentModel.updateContentData(response?.data?.data)
            break
          case 'past_tickets':
            dispatch.contentModel.updatePastTicketData([...responsedata])
            break
          case 'faq':
            dispatch.contentModel.updateFaqData([...responsedata])
            break
        }
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Error',
          description: 'Oops. Something went wrong.',
          type: 'error',
        })
      }
    },
    deleteFolderEffect: async ({ folderId, type }: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        await deleteFolder(folderId)
        dispatch.contentModel.listFoldersEffect(type)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Folder Deleted successfully.',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Error',
          description: 'Folder Deleting Failed.',
          type: 'error',
        })
      }
    },
    updateFolderEffect: async ({ payload, type }: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        await updateFolder({
          folder_id: payload?.folder_id,
          folder_name: payload?.folder_name,
          folder_description: payload?.folder_description,
          updated_at: Date.now(),
          // embedding_model_id: payload?.embedding_model_id

        })
        dispatch.contentModel.listFoldersEffect(type)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Folder Updated successfully.',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Error',
          description: 'Folder Updation Failed',
          type: 'error',
        })
      }
    },
    listFilesEffect: async (folderId) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        dispatch.contentModel.updateContentFilesData([])
        const jwtDetails: any = jwt_decode(localStorage.getItem('id_token') || '')
        const response = await listFiles(folderId)
        const fileListsData = response?.data?.data
        const filesToUpload = getValueFromLocalStorage('filesToUpload')?.[folderId] || {}
        const UrlToUpload = getValueFromLocalStorage('UrlToUpload')?.[folderId] || {}
        const fileUploadDataLocal = { ...filesToUpload, ...UrlToUpload }
        if (fileUploadDataLocal) {
          fileListsData?.forEach((fileData: any) => {
            if (fileUploadDataLocal[fileData?.file_name]) {
              delete fileUploadDataLocal[fileData.file_name]
            }
          })

          const localFileListData = Object.keys(fileUploadDataLocal).map((fileMap: any) => ({
            file_id: 'cbd95851-b468-4588-a8af-78fe6a1cab4e',
            file_name: fileMap,
            file_type: null,
            tags: [],
            created_at: Date.now(),
            updated_at: Date.now(),
            content_type: 'file',
            upload_status: 'inprogress',
            user_name: jwtDetails?.name,
          }))
          dispatch.contentModel.updateContentFilesData([...localFileListData, ...fileListsData])
        } else {
          dispatch.contentModel.updateContentFilesData(response?.data?.data)
        }
        //addValueToLocalStorage('filesToUpload', fileUploadDataLocal)

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Error',
          description: 'Oops. Something went wrong.',
          type: 'error',
        })
      }
    },
    updateFileEffect: async ({ file_id, file_name, file_tags }) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const payload = {
          file_id: file_id,
          file_name: file_name,
          tags: file_tags,
          updated_at: Date.now(),
        }
        await updateFile(payload)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'File Updated successfully.',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Error',
          description: 'Oops. File Updation Failed.',
          type: 'error',
        })
      }
    },
    uploadFile: async (payload) => {
      try {
        //     dispatch.contentModel.updateContentLoading(true)
        uploadFile({
          file_names: payload?.file_names,
          tags: payload?.tags,
          folder_id: payload?.folder_id,
          created_at: Date.now(),
          content_type: payload?.type,
        }).then((resp: any) => {
          dispatch.contentModel.listFilesEffect(payload?.folder_id)
        })
        setTimeout(() => {
          dispatch.contentModel.listFilesEffect(payload?.folder_id)
        }, 3000)
        // //    dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
           dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Error',
          description: 'File Upload Failed',
          type: 'error',
        })
      }
    },
    deleteFile: async (fileId, rootState) => {
      const folderId = localStorage.getItem('folderId')
      try {
        dispatch.contentModel.updateContentLoading(true)
        await deleteFile(fileId).then((response) => {
          if (response?.status === 200) {
            localStorage.removeItem('filesToUpload')
            localStorage.removeItem('UrlToUpload')
            const currentFolderId = rootState?.contentModel?.currentFolder?.folder_id
            dispatch.contentModel.listFilesEffect(currentFolderId || folderId)
          }
        })
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'File Deleted successfully.',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Error',
          description: 'File Deletion Failed.',
          type: 'error',
        })
      }
    },
    listPromptTemplates: async () => {
      try {
        dispatch.contentModel.updateTemplateData(null)
        dispatch.contentModel.updateContentLoading(true)
        console.log('true');
        const response = await listTemplates()
        console.log('false', response);
        dispatch.contentModel.updateTemplateData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
      }
    },
    listPublishedPrompts: async () => {
      try {
        dispatch.contentModel.updateTemplateData(null)
        dispatch.contentModel.updateContentLoading(true)
        const response = await listPublishedPrompts()
        dispatch.contentModel.updateTemplateData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        alert('Failed')
        dispatch.contentModel.updateContentLoading(false)
      }
    },
    listDraftPrompts: async () => {
      try {
        dispatch.contentModel.updateTemplateData(null)
        dispatch.contentModel.updateContentLoading(true)
        const response = await listDraftPrompts()
        dispatch.contentModel.updateTemplateData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        alert('Failed')
        dispatch.contentModel.updateContentLoading(false)
      }
    },
    managePrompts: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        // const payloadvectordb = {
        //   query: "SELECT * FROM c where c.tenant_id ='WIZR-BASIC-TENANT' and c.active =true",
        //   container: 'system_config_vector_db',
        // }
        // same api is used for both llm and vision models
        // const responseVectorDB = await listVectorDb()
        // const responseVectorDBKey = responseVectorDB?.data?.data[0]?.id

        const managePromptPayload = {
          ...(localStorage.getItem('prompt_id') &&
            localStorage.getItem('action') !== 'copy' && { id: localStorage.getItem('prompt_id') }),
          name: payload?.name,
          tags: payload?.tags ? payload?.tags : [],
          description: payload?.description ? payload?.description : '',
          published:
            localStorage.getItem('action') === 'edit' && payload?.published === true ? true : false,
          //published:payload?.published,
          published_on: null,
          created_on: payload?.created_on ? payload?.created_on : Date.now(),
          updated_on: Date.now(),

          prompt_body: {
            system_message: payload?.systemMessage,
            temperature: payload?.temperature === undefined ? 0.5 : payload?.temperature,
            max_tokens: payload?.max_tokens ? parseInt(payload?.max_tokens) : 1000,
            top_p: payload?.top_p === undefined ? 0.5 : payload?.top_p,
            frequency_penalty: payload?.frequency_penalty ? payload?.frequency_penalty : 0,
            presence_penalty: payload?.presence_penalty ? payload?.presence_penalty : 0,
            zero_shot: payload?.examples?.length > 0 ? false : true,
            examples:
              payload?.zero_shot || payload?.llm_model?.model_type === 'completion'
                ? []
                : payload?.examples
                  ? payload?.examples
                  : [],
            knowledgebase_required: payload?.knowledgebase_required
              ? payload?.knowledgebase_required
              : false,
            stop_sequences: payload?.stop_sequences ? payload?.stop_sequences : [],
            llm_model_id: payload?.llm_model?.id
              ? payload?.llm_model?.id
              : '820b7f6f-9d64-4a8e-b82c-31b64570a2f2',
            llm_type: payload?.llm_model?.type ? payload?.llm_model?.type : 'azureopenai',
            model_type: payload?.llm_model?.model_type
              ? payload?.llm_model?.model_type
              : 'chatcompletion',
            message_params:
              payload?.message_params?.length > 0
                ? payload?.message_params?.map((item: any) => {
                  return {
                    name: item.name,
                    type: item.parameterType,
                    value: item.modalTags || '',
                  }
                })
                : [],
            //vector_db_key: responseVectorDBKey,
            embedding_model_id: payload?.embedding_model_id ? payload?.embedding_model_id : '',
            folder_id_list: payload?.folderList ? payload?.folderList : [],
          },
        }
        const response = await managePrompts(managePromptPayload)

        dispatch.contentModel.updateContentLoading(false)
        if (response?.status === 200) {
          localStorage.setItem('tested', 'false')
          localStorage.setItem('action', 'edit')
          localStorage.setItem('prompt_id', response?.data?.data?.id)

          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Prompt Created successfully.',
            type: 'success',
          })
        }
      } catch (err) {
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Prompt Creation Failed.',
          type: 'failed',
        })
      }
    },
    managePromptsApp: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        // const payloadvectordb = {
        //   query: "SELECT * FROM c where c.tenant_id ='WIZR-BASIC-TENANT' and c.active =true",
        //   container: 'system_config_vector_db',
        // }
        // same api is used for both llm and vision models
        // const responseVectorDB = await listVectorDb()
        // const responseVectorDBKey = responseVectorDB?.data?.data[0]?.id

        const managePromptPayload = {
          // id:payload?.id,
          name: payload?.name,
          tags: payload?.tags ? payload?.tags : [],
          description: payload?.description ? payload?.description : '',
          published:
            localStorage.getItem('action') === 'edit' && payload?.published === true ? true : false,
          //published:payload?.published,
          published_on: null,
          created_on: payload?.created_on ? payload?.created_on : Date.now(),
          updated_on: Date.now(),

          prompt_body: {
            system_message: payload?.systemMessage,
            temperature: payload?.temperature === undefined ? 0.5 : payload?.temperature,
            max_tokens: payload?.max_tokens ? parseInt(payload?.max_tokens) : 1000,
            top_p: payload?.top_p === undefined ? 0.5 : payload?.top_p,
            frequency_penalty: payload?.frequency_penalty ? payload?.frequency_penalty : 0,
            presence_penalty: payload?.presence_penalty ? payload?.presence_penalty : 0,
            zero_shot: payload?.examples?.length > 0 ? false : true,
            examples:
              payload?.zero_shot || payload?.llm_model?.model_type === 'completion'
                ? []
                : payload?.examples
                  ? payload?.examples
                  : [],
            knowledgebase_required: payload?.knowledgebase_required
              ? payload?.knowledgebase_required
              : false,
            stop_sequences: payload?.stop_sequences ? payload?.stop_sequences : [],
            llm_model_id: payload?.llm_model?.id
              ? payload?.llm_model?.id
              : '820b7f6f-9d64-4a8e-b82c-31b64570a2f2',
            llm_type: payload?.llm_model?.type ? payload?.llm_model?.type : 'azureopenai',
            model_type: payload?.llm_model?.model_type
              ? payload?.llm_model?.model_type
              : 'chatcompletion',
            message_params:
              payload?.message_params?.length > 0
                ? payload?.message_params?.map((item: any) => {
                  return {
                    name: item.name,
                    type: item.parameterType,
                    value: item.modalTags || '',
                  }
                })
                : [],
            //vector_db_key: responseVectorDBKey,
            embedding_model_id: payload?.embedding_model_id ? payload?.embedding_model_id : '',
            folder_id_list: payload?.folderList ? payload?.folderList : [],
            best_of: payload?.best_of ? payload?.best_of : 1,
            post_response_text: payload?.post_response_text ? payload?.post_response_text : '',
            pre_response_text: payload?.pre_response_text ? payload?.pre_response_text : '',
          },
        }
        const response = await managePrompts(managePromptPayload)

        dispatch.contentModel.updateContentLoading(false)
        if (response?.status === 200) {
          localStorage.setItem('tested', 'false')
          localStorage.setItem('action', 'edit')
          localStorage.setItem('prompt_id', response?.data?.data?.id)

          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Prompt Created successfully.',
            type: 'success',
          })
        }
      } catch (err) {
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Prompt Creation Failed.',
          type: 'failed',
        })
      }
    },

    listPromptHistory: async (promptId: string) => {
      try {
        dispatch.contentModel.updatePromptHistory(null)
        dispatch.contentModel.updateContentLoading(true)
        const response = await listPromptHistory(promptId)
        dispatch.contentModel.updatePromptHistory(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        alert('Failed')
        dispatch.contentModel.updateContentLoading(false)
      }
    },
    publishPromptDraft: async (id: string) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const publishPromptPayload = {
          id: id,
          action: 'publish',
          last_updated: new Date().getTime(),
        }
        const response = await publishPrompt(publishPromptPayload)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Prompt Published successfully.',
            type: 'success',
          })
        }
        dispatch.contentModel.updateContentLoading(false)
        dispatch.contentModel.listDraftPrompts()
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Prompt Publish Failed.',
          type: 'failed',
        })
      }
    },
    addComments: async (payload: any) => {
      try {
        const test_id = localStorage.getItem('prompt_history_id')
        const pmt_id = localStorage.getItem('prompt_id')
        const feedBackpayload = {
          id: test_id,
          prompt_id: pmt_id,
          feedback: payload.feedback,
          comment: payload.comment,
        }
        const response = await addComments(feedBackpayload)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Comment added successfully.',
          type: 'success',
        })
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Comment Addition Failed',
          type: 'failed',
        })
      }
    },
    addCommentsApp: async (payload: any) => {
      try {
      
        const response = await addCommentsApp(payload)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Comment added successfully.',
          type: 'success',
        })
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Comment Addition Failed',
          type: 'failed',
        })
      }
    },
    unpublishPromptPublished: async (id: any) => {
      try {
        const publishPromptPayload = {
          id: id,
          action: 'unpublish',
          last_updated: new Date().getTime(),
        }
        const response = await publishPrompt(publishPromptPayload)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Prompt UnPublished successfully.',
          type: 'success',
        })
        dispatch.contentModel.listPublishedPrompts()
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Prompt UnPublish Failed.',
          type: 'failed',
        })
      }
    },
    promptTestChat: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        dispatch.contentModel.updateTestStatus(true)
        const response = await testPrompt(payload)

        dispatch.contentModel.updateQueryUrl(response?.data)
        const statusResponse = await statusChecker(response?.data?.statusQueryGetUri)
        if (statusResponse) {
          if (statusResponse?.error) {
            dispatch.commonModel.updateNotificationState({
              message: 'Failed',
              description: statusResponse?.error,
              type: 'failed',
            })
          } else {
            dispatch.contentModel.updateContentLoading(false)
            dispatch.contentModel.updateTestStatus(false)
            localStorage.setItem('tested', 'true')
            dispatch.contentModel.updateChatMessage({
              isSent: false,
              text: statusResponse?.llm_response?.content,
            })
          }
        }
      } catch (err) {
        alert('Failed')
      }
      dispatch.contentModel.updateTestStatus(false)
      dispatch.contentModel.updateContentLoading(false)
    },
    LLModelList: async () => {
      const tenantId = getTenantId()

      // const payload = {
      //   query: `SELECT c.id,c.llm_type,c.model_type ,c.llm_settings,c.display_name,c.model_name FROM c where (c.tenant_id='${tenantId}' or c.tenant_id ='WIZR-BASIC-TENANT')and c.status = 'active' and (c.model_type ='chatcompletion' or c.model_type = 'completion')`,
      //   container: 'system_config_llm_model',
      // }

      try {
        const response = await listLLMModels()
        if (response?.status === 200) {
          dispatch.contentModel.updateLLMModelList(response?.data?.data)
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('Failed')
      }
    },
    getModels: async () => {
      try {
        const response = await listModels()
        if (response?.status === 200) {
          dispatch.contentModel.updateLLMModelList(response?.data?.data)
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('Failed')
      }
    },
    getVectorDb: async () => {
      const tenantId = getTenantId()

      try {
        const responseVectorDB = await listVectorDb()
        const responseVectorDBKey = responseVectorDB?.data?.data
        if (responseVectorDB?.status === 200) {
          dispatch.contentModel.updateVectorDb(responseVectorDBKey)
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('Failed')
      }
    },
    getPromptDetails: async ({ promptId }) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await getPromptDetails(promptId)
        dispatch.contentModel.updatePromptData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('failed')
      }
    },
    getPromptTemplateDetails: async ({ promptId }) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await getPromptTemplateDetails(promptId)
        dispatch.contentModel.updatePromptData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('failed')
      }
    },
    listAppTemplates: async () => {
      try {
        dispatch.contentModel.updateAppData(null)
        dispatch.contentModel.updateContentLoading(true)
        const payload = { sort_by: 'c.updated_on', order: 'desc' }
        const response = await listAppTemplates(payload)
        dispatch.contentModel.updateAppData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch {
        dispatch.contentModel.updateContentLoading(false)
        console.log('Error')
      }
    },
    listAppPublished: async () => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        dispatch.contentModel.updateAppData(null)
        const payload = { sort_by: 'c.updated_on', order: 'desc' }
        const response = await listAppPublished(payload)
        dispatch.contentModel.updateAppData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Cannot retrieve data at this moment',
          type: 'failed',
        })
      }
    },
    list: async () => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        dispatch.contentModel.updateAppData(null)
        const payload = { sort_by: 'c.updated_on', order: 'desc' }
        const response = await listAppPublished(payload)
        dispatch.contentModel.updateAppData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Cannot retrieve data at this moment',
          type: 'failed',
        })
      }
    },
    getWidgetHistoryDetails: async ({payload,tool_id}:any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        // dispatch.contentModel.updateAppData(null)
        // const payload = { sort_by: 'c.updated_on', order: 'desc' }
        const response = await getWidgetHistoryDetails(payload,tool_id)
        dispatch.contentModel.updategetWidgetHistoryDetails(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Cannot retrieve data at this moment',
          type: 'failed',
        })
      }
    },
    
    listAppDraft: async () => {
      try {
        dispatch.contentModel.updateAppData(null)
        dispatch.contentModel.updateContentLoading(true)
        const payload = { sort_by: 'c.updated_on', order: 'desc' }
        const response = await listAppDraft(payload)
        dispatch.contentModel.updateAppData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Cannot retrieve data at this moment',
          type: 'failed',
        })
      }
    },
    createApp: async (payload: any) => {
      try {
        const tenantId = getTenantId()

        const { action, ...restOfItem } = payload

        const createAppPayload = {
          app_type: 'Tool',
          created_on: Date.now(),
          updated_on: Date.now(),
          // enabled: true,
          type: 'app',
          default: true,
          status: 'active',
          published: false,
          max_past_messages: payload?.memory ? 15 : 0,
          edges: [],
          nodes: [],
          ...restOfItem,
        }

        dispatch.contentModel.updateContentLoading(true)
        const response = await createApp(createAppPayload)
        dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Created App succcessfuly',
          type: 'success',
        })
        dispatch.contentModel.appDesignerCreationData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Create App',
          type: 'failed',
        })
      }
    },
    createAppNewtab: async (payload: any) => {
      try {
        const tenantId = getTenantId()

        const { action, ...restOfItem } = payload

        const createAppPayload = {
          app_version: 'N/A',
          app_type: 'Tool',
          created_on: Date.now(),
          updated_on: Date.now(),
          // enabled: true,
          type: 'app',
          default: true,
          status: 'active',
          published: false,
          max_past_messages: payload?.memory ? 15 : 0,
          edges: [],
          nodes: [],
          ...restOfItem,
        }
        dispatch.contentModel.updateContentLoading(true)
        const response = await createApp(createAppPayload)
        const newWindow: any = window.open(
          `/app-designer/tool/${response?.data?.data?.id}/${response?.data?.data?.app_id}/copy`,
          '_blank',
          'noopener,noreferrer',
        )
        newWindow.opener = null
        dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Created App succcessfuly',
          type: 'success',
        })
        dispatch.contentModel.appDesignerCreationData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Create App',
          type: 'failed',
        })
      }
    },
    updateApp: async (payload: any) => {
      try {
        const tenantId = getTenantId()
        const updateAppPayload = {
          // app_version: 2,
          app_type: 'Tool',
          updated_on: Date.now(),
          // enabled: true,
          default: true,
          status: 'active',
          published: false,
          tenant_id: payload?.tenant_id ? payload?.tenant_id : tenantId,
          max_past_messages: payload?.memory ? 15 : 0,
          edges: [],
          ...payload,
        }

        // dispatch.contentModel.updateContentLoading(true)
        const response = await updateApp(updateAppPayload)
        dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Updated App succcessfuly',
          type: 'success',
        })
        // dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Update App',
          type: 'failed',
        })
      }
    },
    deleteApp: async (appId: string) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await deleteApp(appId)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Deleted App succcessfuly',
          type: 'success',
        })
        const payload = { sort_by: 'c.updated_on', order: 'desc' }

        // dispatch.contentModel.listAppPublished()
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Delete App',
          type: 'failed',
        })
      }
    },
    appToolTest: async (payload: any) => {
      try {
        // dispatch.contentModel.updateContentLoading(true)
        dispatch.contentModel.updateTestStatus(true)
        const response = await testAppTool(payload)
        dispatch.contentModel.updateQueryUrl(response?.data)
        const statusResponse = await statusChecker(response?.data?.statusQueryGetUri)
        const parsedResponse = JSON.parse(statusResponse)
        if (parsedResponse) {
          if (parsedResponse?.error) {
            dispatch.commonModel.updateNotificationState({
              message: 'Failed',
              description: parsedResponse?.error,
              type: 'failed',
            })
          } else {
            dispatch.contentModel.updateTestStatus(false)
            dispatch.contentModel.updateChatMessage({
              isSent: false,
              text: parsedResponse?.content,
              knowledgeBase: parsedResponse?.docs_metadata
            })
          }
        }
      } catch (err) {
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Test App',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
      dispatch.contentModel.updateTestStatus(false)
    },
    getAppDetails: async ({ appId }) => {
      try {
        // dispatch.contentModel.updateContentLoading(true)
        const response = await getAppDetails(appId)
        if (response?.error) {
          alert('failed')
        } else {
          dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
          //dispatch.contentModel.updateContentLoading(false)
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('failed')
      }
    },
    getPublishedAppEditDetails: async ({ appId, type }: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await getPublishedAppDetails(appId, type)
        if (response?.error) {
          alert('failed')
        } else {
          dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
          dispatch.contentModel.updateContentLoading(false)
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('failed')
      }
    },
    getAppDetailsTemplate: async ({ appId }) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await getAppDetailsTemplate(appId)
        if (response?.error) {
          alert('failed')
        } else {
          dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
          dispatch.contentModel.updateContentLoading(false)
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('failed')
      }
    },
    stopGeneration: async (url: any) => {
      const newText = 'usercancelled'
      dispatch.contentModel.updateTestStatus(false)
      const updatedUrl = url.replace('{text}', newText)
      const response = await fetch(updatedUrl, { method: 'POST' })
    },
    publishApp: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await publishApp(payload)
        dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'App published succcessfuly',
          type: 'success',
        })
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Update App',
          type: 'failed',
        })
      }
    },
    setAsDefault: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await setAsDefault(payload)
        // dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Version as the default',
          type: 'success',
        })
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to set deafult version',
          type: 'failed',
        })
      }
    },
    listAppHistory: async ({appId,payload,pagination}) => {
      try {
        if(!pagination){
        dispatch.contentModel.updateAppHistory(null)
      }
        dispatch.contentModel.updateContentLoading(true)
        const response = await listAppHistory(appId,payload)
        if(pagination){

        dispatch.contentModel.updateAppHistoryPagination(response?.data?.data)
      console.log("if")
      }
        
        else {
        dispatch.contentModel.updateAppHistory(response?.data?.data)
      console.log("else")
    }
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('Failed')
      }
    },
    listAppVersions: async ({ appId, type, isTemplate }: any) => {
      try {
        dispatch.contentModel.updateAppVersions(null)
        // dispatch.contentModel.updateContentLoading(true)
        let response;
        if (isTemplate) {
          response = await listTemplateVersion(appId)
        }
        else {
          response = await listAppVersions(appId, type)
        }
        dispatch.contentModel.updateAppVersions(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('Failed')
      }
    },
    getBulkTestStatus: async ({ appId, execId, appname }: any) => {
      try {
        dispatch.contentModel.updateBulkRunStatus(null)

        const checkStatus = async (): Promise<any> => {
          const response = await getBulkTestStatus(appId, execId)
          //const statusResponseJson = await statusResponse.json()

          if (
            response?.data?.data[0]?.execution_status === 'Completed' ||
            response?.data?.data[0]?.execution_status === 'Completed_With_Errors'
          ) {
            //WizrAzurOrchestratorBulkRun.downloadFileOutput()
            //return response?.data?.data[0]?.output_file_name
            //dispatchbulkrunStatusApp({app_id:appId})
            dispatch.contentModel.updateBulkRunStatus(true)
            dispatch.commonModel.updateNotificationState({
              message: 'Success',
              description: `Bulkrun is completed for ${appname} .You can download your results now.`,
              type: 'success',
            })
            // dispatch.contentModel.updateBulkRunContent(false)
            dispatch.contentModel.updateBulkRunResultDynamic({
              ...response?.data?.data[0],
              appId: appId,
            })
          } else if (response?.data?.data[0]?.execution_status === 'Failed') {
            dispatch.commonModel.updateNotificationState({
              message: 'Failed',
              description: 'Failed To Execute BulkRun',
              type: 'failed',
            })
          } else if (response?.data?.data[0]?.execution_status === 'Terminated') {
            dispatch.commonModel.updateNotificationState({
              message: 'Success',
              description: 'Canceling bulkrun',
              type: 'success',
            })
            dispatch.contentModel.updateBulkRunResultDynamic({
              ...response?.data?.data[0],
              appId: appId,
            })
          } else {
            await new Promise((resolve) => setTimeout(resolve, 10000))
            const value = await checkStatus()
            return value
          }
        }
        if (appId) {
          const response = await checkStatus()
          return response
        }
        // dispatch.contentModel.updateBulkRunContent(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Execute BulkRun',
          type: 'failed',
        })
      }
    },
    appToolShareTest: async ({ payload, appId }: any) => {
      try {
        //dispatch.contentModel.updateContentLoading(true)
        dispatch.contentModel.updateTestStatus(true)

        const response = await testAppshareTool(payload, appId)
        const statusResponse = await statusChecker(response?.data?.statusQueryGetUri)
        const parsedResponse = JSON.parse(statusResponse)
        if (parsedResponse) {
          if (parsedResponse?.error) {
            dispatch.commonModel.updateNotificationState({
              message: 'Failed',
              description: parsedResponse?.error,
              type: 'failed',
            })
          } else {
            dispatch.contentModel.updateTestStatus(false)
            dispatch.contentModel.updateChatMessage({
              isSent: false,
              text: parsedResponse?.content,
            })
          }
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Test App',
          type: 'failed',
        })
      }
      // dispatch.contentModel.updateContentLoading(false)
      dispatch.contentModel.updateTestStatus(false)
    },
    listRunHistory: async ({appId,payload,pagination}) => {
      try {
        if(!pagination){
          dispatch.contentModel.updateAppHistory(null)
        }
        //dispatch.contentModel.updateRunHistory(null)
        dispatch.contentModel.updateContentLoading(true)
        const response = await listRunHistory(appId,payload)
        if(pagination){

          dispatch.contentModel.updateRunHistoryPagination(response?.data?.data)
        console.log("if")
        }
        else {
          dispatch.contentModel.updateRunHistory(response?.data?.data)
        }
       
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('Failed')
      }
    },
    listTags: async () => {
      try {
        //dispatch.contentModel.updateTags(null)
        // dispatch.contentModel.updateContentLoading(true)
        const response = await listTags()
        dispatch.contentModel.updateTags(response?.data?.data)
        // dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list App Tags',
          type: 'failed',
        })
      }
    },
    getAppShareDetails: async ({ appId }) => {
      try {
        //dispatch.contentModel.updateContentLoading(true)
        const response = await getAppShareDetails(appId)

        dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
        //dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('failed')
      }
    },
    appShareLinkGeneration: async (payload) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await appShareLinkGeneration(payload)

        dispatch.contentModel.updateAppSharelinkGeneration(response?.data?.data?.appurl)
        dispatch.contentModel.updateContentLoading(false)
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Generate AppShare Link',
          type: 'failed',
        })
      }
    },

    appShareLinkValidation: async (payload) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await appShareLinkValidation(payload)

        dispatch.contentModel.updateAppShareLinkValidation(response?.data?.message)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Validate AppShare Link',
          type: 'failed',
        })
      }
    },
    bulkrunStatusApp: async (payload) => {
      try {
        //dispatch.contentModel.updateContentLoading(true)
        const response = await bulkrunStatusApp(payload)
        const data = response?.data?.data?.sort((a: any, b: any) => b.run_time - a.run_time)

        data
          ? dispatch.contentModel.updateBulkRunContent(data)
          : dispatch.contentModel.updateBulkRunContent([])

        //dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'failed',
          type: 'failed',
        })
      }
    },

    VisionModalList: async () => {
      try {
        // same api is used for both LLM and Vision
        const response = await listVisionModels()
        if (response?.status === 200) {
          dispatch.contentModel.updateVisionModelList(response?.data?.data)
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('Failed')
      }
    },
    VoiceModelList: async () => {
      // const payload = {
      //   "query": query,
      //   container: 'system_config_llm_model',
      // }

      try {
        // same api is used for both LLM and Vision
        const response = await listVoiceModels()
        if (response?.status === 200) {
          dispatch.contentModel.updateVisionModelList(response?.data?.data)
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('Failed')
      }
    },
    uploadFilesusingLink: async (payload) => {
      try {
        dispatch.contentModel.updatefileLoading(true)

        const response = await uploadFilesusingLink(payload)

        dispatch.contentModel.updateFileParams(response?.data?.data)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'File Upload Successfull',
          type: 'success',
        })
        dispatch.contentModel.updatefileLoading(false)
      } catch (error) {
        dispatch.contentModel.updatefileLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Upload File',
          type: 'failed',
        })
      }
    },
    uploadFileMultipleApps: async ({ fileInfo, name, appIds }) => {
      try {
        dispatch.contentModel.updateContentLoading(true);
        // Create an array of promises for each API call
        const uploadPromises = appIds?.map(async (item: any) => {
          const fileUploadPayload = {
            container_id: 1,
            path: item?.app_id,
            files: [
              `${name}`,
            ]
          };

          const formData = new FormData();
          const jsonData = JSON.stringify(fileUploadPayload);
          formData.append('data', jsonData);
          formData.append(name, fileInfo?.fileList[0]?.originFileObj);

          const response = await uploadFilesusingLink(formData);
          dispatch.contentModel.updateFileParams(response?.data?.data);

          return response; // Return the response to track success/failure
        });

        // Wait for all promises to resolve
        const uploadResults = await Promise.all(uploadPromises);
        // Check if all uploads were successful
        const allUploadsSuccessful = uploadResults.every(result => result && result.status === 200);

        if (allUploadsSuccessful) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'File Upload Successful',
            type: 'success',
          });
        } else {
          // Handle case where not all uploads were successful
          console.error('Not all uploads were successful');
          // Optionally display an error notification
          dispatch.commonModel.updateNotificationState({
            message: 'Error',
            description: 'FileUpload failed',
            type: 'error',
          });
        }
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        console.error('Error during file upload:', error);
        // Handle error (e.g., display error notification)
        dispatch.commonModel.updateNotificationState({
          message: 'Error',
          description: 'File upload failed',
          type: 'error',
        });
      }

      dispatch.contentModel.updateContentLoading(false)

    },
    generateSAStoken: async ({ payload, metadata }) => {
      try {
        const { fileInfo, container_id, app_id, timestamp } = payload

        const payloadForToken = {
          container_id: container_id,
          path: `${app_id}`,
        }

        dispatch.contentModel.updateContentLoading(true)
        const response = await generateSASurl(payloadForToken)
        // fileInfo.fileList.forEach((file: any) => {
        // })
        if (response?.status === 200) {
          fileInfo.fileList.forEach((file: any) => {

            const resp = WizrAzurOrchestratorBulkRunwithUrl.uploadFileWithUrl(
              response?.data?.data?.url,
              response?.data?.data?.token,
              file,
              [],
              '',
              metadata,
              timestamp,
            )
            resp
              .then((result) => {
                dispatch.contentModel.updatedUrlforCsvUpload(`${timestamp}/${file?.name}`)
              })
              .catch((error) => {
                // Handle the error here
                console.error('Error uploading file', error)
              })
          })
        }
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        // dispatch.commonModel.updateNotificationState({
        //   message: 'Failed',
        //   description: 'Failed To Validate AppShare Link',
        //   type: 'failed',
        // })
      }
    },
    generateSASTokenForFileDownload: async ({ payload,name }) => {
      try{
        dispatch.contentModel.updateGenerateCode(``)
        const response = await generateSASurl(payload)
        const domain = response.data.data.url.replace('inbox','inarchive')

        console.log('url', `${domain}/${name}?${response.data.data.token}`);
        const pageUrl = `${domain}/${name}?${response.data.data.token}`
        window.open(pageUrl, '_blank');

        dispatch.contentModel.updateFileUrl(`${domain}/${name}?${response.data.data.token}`)
      }
      catch(error){
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To SasToken',
          type: 'failed',
        })  }
    },
    generateSASTokenGeneric: async (payload) => {
      try {
        // dispatch.contentModel.updateContentLoading(true)
        console.log('payload', payload);
        const response = await generateSASurl(payload)
        dispatch.contentModel.updateSasToken(response.data.data.token)
        // dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To SasToken',
          type: 'failed',     })  }
    },

    generateSAStokenToDownloadTemplate: async ({ payload, app_name }) => {
      try {

        dispatch.contentModel.updateContentLoading(true)
        const response = await generateSASurl(payload)
        // eslint-disable-next-line no-debugger
        // debugger;
        const azureSasUrl: any = `${response.data.data.url}/${app_name}?${response.data.data.token}`
        const link = document.createElement('a');
  
        // Set the download URL (SAS URL from Azure)
        link.href = azureSasUrl;
        
        // Optionally set the file name for the download
        link.download = azureSasUrl ? azureSasUrl.split('/').pop().split('?')[0] : '' // Extracting file name from URL
        
        // Append the link to the body (necessary for Firefox)
        document.body.appendChild(link);
        
        // Programmatically click the link to trigger the download
        link.click();
        
        // Remove the link after the download is triggered
        document.body.removeChild(link);

        
        // if (response?.status === 200) {
        //   fileInfo.fileList.forEach((file: any) => {

        //     const resp = WizrAzurOrchestratorBulkRunwithUrl.uploadFileWithUrl(
        //       response?.data?.data?.url,
        //       response?.data?.data?.token,
        //       file,
        //       [],
        //       '',
        //       metadata,
        //       timestamp,
        //     )
        //     resp
        //       .then((result) => {
        //         dispatch.contentModel.updatedUrlforCsvUpload(`${timestamp}/${file?.name}`)
        //       })
        //       .catch((error) => {
        //         // Handle the error here
        //         console.error('Error uploading file', error)
        //       })
        //   })
        // }
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        // dispatch.commonModel.updateNotificationState({
        //   message: 'Failed',
        //   description: 'Failed To Validate AppShare Link',
        //   type: 'failed',
        // })
      }
    },
    terminateBulkrun: async (payload) => {
      try {
        const response = await terminateBulkrun(payload)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Bulkrun termination failed',
          type: 'failed',
        })
      }
    },

    enduserapi: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await testAppTool(payload)
        const statusResponse = await statusChecker(response?.data?.statusQueryGetUri)
        const parsedResponse = JSON.parse(statusResponse)
        if (parsedResponse) {
          if (parsedResponse?.error) {
            dispatch.commonModel.updateNotificationState({
              message: 'Failed',
              description: parsedResponse?.error,
              type: 'failed',
            })
          } else {
            dispatch.contentModel.updateTestStatus(false)
            dispatch.contentModel.updateEnduser([parsedResponse?.content])
            // dispatch.contentModel.updateChatMessage({
            //   isSent: false,
            //   text: parsedResponse?.content,
            // })
          }
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To Test App',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
      dispatch.contentModel.updateTestStatus(false)
    },
    executeBulkrun: async (payload) => {
      try {
        const response = await executeBulkrun(payload)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Bulkrun termination failed',
          type: 'failed',
        })
      }
    },
    appApiIntegrate: async (payload) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        dispatch.contentModel.updateAppApiUrl(null)
        const response = await appApiIntegrate(payload)
        dispatch.contentModel.updateAppApiUrl(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to generate app url',
          type: 'failed',
        })
      }
    },
    enableDisableUser: async (payload: any) => {
      try {
        const response = await enableDisableUser(payload)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description:
              payload?.activation_status === 'enable'
                ? 'User enabled sucessfully'
                : 'User disabled sucessfully',
            type: 'success',
          })
        }
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to change user status',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    listUsers: async (tenantType: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await listTenantUsers(tenantType)

        dispatch.contentModel.updateUserList(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list users ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    updateUserDetails: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await updateUserDetails(payload)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'User details updated sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update user details',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    createUser: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await createUser(payload)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'User created sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to create user ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    deleteUser: async (userId: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await deleteUser(userId)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'User removed sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to delete user ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    getUserDetails: async (userId: any) => {
      try {
        dispatch.contentModel.updateCurrentuserDetails({})

        dispatch.contentModel.updateContentLoading(true)

        const response = await getUserDetails(userId)
        dispatch.contentModel.updateCurrentuserDetails(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to get user details ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    getTenantList: async () => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await getTenantList()

        dispatch.contentModel.updateTenantList(response?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list tenants ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    createTenants: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await createTenants(payload)
        const statusResponse = await statusChecker(response?.data?.statusQueryGetUri)

        if (statusResponse) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Tenant created sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to create tenant ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    updateTenants: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await updateTenants(payload)
        if (response) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Tenant updated sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update tenant ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    getAccessList: async () => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await getAccessList()

        dispatch.contentModel.updateAccessList(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list accesses ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    getRoleList: async () => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await getRoleList()

        dispatch.contentModel.updateRolesList(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list roles ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    deleteAccess: async (accessId: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await deleteAccess(accessId)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Access removed sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to delete access ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    createRole: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await createRole(payload)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Role created sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to create role ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    updateRole: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await updateRole(payload)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Role updated sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update role ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    deleteRole: async (roleId) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await deleteRole(roleId)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Role removed sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to delete role ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    getTenanatDetails: async () => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await getTenantDetails()
        dispatch.contentModel.updateTenantDetails(response?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to fetch tenant details',
          type: 'failed',
        })
        dispatch.contentModel.updateContentLoading(false)
      }
    },
    getPackageList: async () => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await listPackages()

        dispatch.contentModel.updatePackageList(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list packages ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    getProductList: async () => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await listProducts()

        dispatch.contentModel.updateProductList(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list products ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    updateProduct: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await updateProducts(payload)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Product updated sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update product ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    createPackage: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await createPackage(payload)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Package created sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to create package ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    updatePackages: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await updatePackages(payload)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Package updated sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update package ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    deletePackage: async (packageId: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await deletePackages(packageId)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Package removed sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to delete package ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    deleteTenants: async (Id: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await deleteTenants(Id)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Tenant removed sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to delete tenant ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },

    disableTenant: async (Id: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await disableTenant(Id)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Tenant disabled sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to disable tenant ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    setPermissionValues: async (payload) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const permissions = await getAccessLists(payload)
        dispatch.contentModel.updatePermissionValues(permissions)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        // dispatch.commonModel.updateNotificationState({
        //   message: 'Failed',
        //   description: 'Failed to set permission values',
        //   type: 'failed',
        // })
      }
    },
    enableTenant: async (Id: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await enableTenant(Id)
        if (response?.status === 200) {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Tenant enabled sucessfully',
            type: 'success',
          })
        }

        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to enable tenant ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },
    getIntegrationList: async () => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await listIntegrations()

        dispatch.contentModel.updateIntegrationList(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list integrations ',
          type: 'failed',
        })
      }
      dispatch.contentModel.updateContentLoading(false)
    },

    getMasterTenantIntegrations: async () => {
      try {
        // dispatch.contentModel.updateContentLoading(true)

        const response = await masterTenantIntegrations()

        dispatch.contentModel.updateMatserIntegrationList(response?.data?.data)
        // dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list integrations ',
          type: 'failed',
        })
      }
    },
    deleteIntegration: async (integrationId: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await deleteIntegration(integrationId)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Integration removed sucessfully',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to delete integrations ',
          type: 'failed',
        })
      }
    },
    updateIntegration: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await updateIntegration(payload)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Integration updated sucessfully',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update integrations ',
          type: 'failed',
        })
      }
    },
    createIntegration: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await createIntegration(payload)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Integration created sucessfully',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to create integrations ',
          type: 'failed',
        })
      }
    },
    listAppForPackage: async () => {
      try {
        const payload = {
          order: "desc",
          sort_by: "c.updated_on"
        }
        const response = await listAppForPackage(payload)
        dispatch.contentModel.updateAppData(response?.data?.data)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list apps for package ',
          type: 'failed',
        })
      }
    },
    listAppGeneral: async () => {
      try {
        const payload = {
          order: "desc",
          sort_by: "c.updated_on"
        }
        const response = await listAppGeneral(payload)
        dispatch.contentModel.updateAppData(response?.data?.data)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list apps for package ',
          type: 'failed',
        })
      }
    },
    getFeatureSettings: async () => {
      try {
        const response = await getFeatureSettings()
        dispatch.contentModel.updateFeatureSettings(response?.data?.data[0])
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to retrieve feature settings ',
          type: 'failed',
        })
      }
    },
    updateFetaureSettingsStatus: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await updateFetaureSettingsStatus(payload)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update settings ',
          type: 'failed',
        })
      }
    },
    createFeatureIntegrationSettings: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await createFeatureIntegrationSettings(payload)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Feature created sucessfully',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to create feature ',
          type: 'failed',
        })
      }
    },
    cloneAutosolveFeature: async (payload: any) => {

      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await createFeatureIntegrationSettings(payload?.feactureClone)
        if (response) {
          const filteredArray = response?.data?.data?.feature_settings?.filter((item: any) => item?.bot_id === payload?.botDetails?.bot_config?.id)
          const feature_id = filteredArray[0].id
          const botDetails = {
            ...payload?.botDetails,
            app_feature_id: feature_id
          }
          const createBotResponse = await createBot(botDetails)
        }
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Feature created sucessfully',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to create feature ',
          type: 'failed',
        })
      }
    },
    createFeatureIntegrationSettingsAutoSolve: async (data: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await createFeatureIntegrationSettings(data?.finalPayload)
        const payloadBot = {
          ...data?.botDetails,
          app_feature_id: response?.data?.data?.id
        }
        const botPayload = {
          bot_config: {
            ...data?.botDetails?.bot_config,
            agent_id: data?.botDetails?.bot_config?.agent_id,
            bot_personality: data?.finalPayload?.feature_settings?.bot_config?.bot_personality
          },
          app_feature_id: response?.data?.data?.id,
          tenant_feature_id: data?.botDetails?.id,
        }
        const resposneBot = await createBot(payloadBot)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Feature created sucessfully',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to create feature ',
          type: 'failed',
        })
      }
    },
    createGroupFolder: async ({ payload, type }: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await createGroupFolder(payload, type)
        dispatch.contentModel.updateContentLoading(false)
        if (payload?.data?.status !== 'deleted') {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Folder updated sucessfully',
            type: 'success',
          })
        }
        else {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Folder deleted sucessfully',
            type: 'success',
          })
        }
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update Folder ',
          type: 'failed',
        })
      }
    },
    updateTicketType: async ({ payload, type }: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await updateTicketType(payload, type)
        dispatch.contentModel.updateContentLoading(false)
        if (payload?.data?.status === 'deleted') {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Ticket Type deleted sucessfully',
            type: 'success',
          })
        }
        else {
          dispatch.commonModel.updateNotificationState({
            message: 'Success',
            description: 'Values updated sucessfully',
            type: 'success',
          })
        }

      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: `${error}`,
          type: 'failed',
        })
      }
    },
    updateCompanyInfo: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await updateCompanyInfo(payload)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Company details updated sucessfully',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update company details ',
          type: 'failed',
        })
      }
    },
    updatePersona: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await updatePersona(payload)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Persona updated sucessfully',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update Persona ',
          type: 'failed',
        })
      }
    },
    setAsTemplate: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await setAsTemplate(payload)
        // dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'App set as template',
          type: 'success',
        })
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to set template',
          type: 'failed',
        })
      }
    },
    updateTicketDetail: async (payload: any) => {
      try {
        dispatch.contentModel.updateTicketDetails(payload)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to get ticket details',
          type: 'failed',
        })
      }
    },
    // getAutoTagList: async (type) => {
    //   try {
    //     const response = await getAutoTagList(type)
    //     dispatch.contentModel.updateAutoTagData(response?.data?.data)
    //   } catch (error) {
    //     dispatch.contentModel.updateContentLoading(false)
    //     dispatch.commonModel.updateNotificationState({
    //       message: 'Failed',
    //       description: 'Failed to list apps for package ',
    //       type: 'failed',
    //     })
    //   }
    // },
    getAutoTagList: async (type: any) => {
      try {
        const response = await getAutoTagList(type);
    
      
        if (type === 'autotag') {
          
          dispatch.contentModel.updateAutoTagData(response?.data?.data);
        } else {
          
          dispatch.contentModel.updateAgentTagData(response?.data?.data);
        }
        
      } catch (error) {
        // Handle the error scenario
        dispatch.contentModel.updateContentLoading(false);
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list apps for package',
          type: 'failed',
        });
      }
    },
    getFeatureAppDetails: async (appId: any) => {
      try {
        const response = await getFeatureAppDetails(appId)
        dispatch.contentModel.updateCurrentAppDetails(response?.data?.data)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to get feature app details ',
          type: 'failed',
        })
      }
    },
    getPacakageDetails: async (type: string) => {
      try {
        const response = await getPacakageDetails(type)
        switch (type) {
          case 'autotag':
            dispatch.contentModel.updateAppData(response?.data?.data)
            break
          case 'agentassist':
            dispatch.contentModel.updateAgentAssistData(response?.data?.data)
            break
          case 'autosolve':
            dispatch.contentModel.updateAppData(response?.data?.data)
            break
          case 'agenttag':
            dispatch.contentModel.updateAppData(response?.data?.data)
            break
          default:
            break
        }
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to get package app list ',
          type: 'failed',
        })
      }
    },
    editFeatureSettings: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await updateFeatureSettings(payload)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Feature Settings Updated Successfully ',
          type: 'success',
        })
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update feature settings ',
          type: 'failed',
        })
      }
    },
    getEndPointUrl: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await getEndPointUrl(payload)
        dispatch.contentModel.updateEndpointdata(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to get endpoint url ',
          type: 'failed',
        })
      }
    },
    getTenantGroups: async (Id: any) => {
      try {
        dispatch.contentModel.updateTenantGroups('')

        const response = await getTenantGroups(Id)
        dispatch.contentModel.updateTenantGroups(response?.data?.data)
      }
      catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to list teams ',
          type: 'failed',
        })
      }
    },
    saveTenantGroups: async (payload: any) => {
      try {
        const response = await saveTenantGroups(payload)
      }
      catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to save teams ',
          type: 'failed',
        })
      }
    },
    createFolderFaqPT: async (payload: any) => {
      const response = await createFolder(payload)
    },
    getGroupRowDetails: async ({ type, folderId }: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await getGroupRowDetails(type, folderId)
        dispatch.contentModel.updateFaqPtTicketDetails(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to get group row details ',
          type: 'failed',
        })
      }
    },
    deleteGroupRowDetails: async ({ type, rowId }: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await deleteGroupRowDetails(type, rowId)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to delete details ',
          type: 'failed',
        })
      }
    },
    deleteGroupsFaqPastTickets: async ({ type, folderId }: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await deleteGroupsFaqPastTickets(type, folderId)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to delete details ',
          type: 'failed',
        })
      }
    },
    getIntegrationToolsForTenant: async () => {
      const response = await getIntegrationToolsForTenant()
      dispatch.contentModel.updateMatserIntegrationList(response?.data?.data)

    },

    getIntegrationsForSelectedTool: async (tools: any) => {
      dispatch.contentModel.updateIntegrationList([])

      const response = await getIntegrationsForSelectedTool(tools)
      dispatch.contentModel.updateIntegrationList(response?.data?.data)

    },
    embeddingModelList: async () => {
      try {
        // same api is used for both LLM and Vision
        const response = await listEmbeddingModels()
        if (response?.status === 200) {
          dispatch.contentModel.updateEmbeddingModelList(response?.data?.data)
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('Failed')
      }
    },
    importPastTickets: async (payload: any) => {
      try {
        // same api is used for both LLM and Vision
        const response = await importPastTickets(payload)
        if (response?.status === 200) {
          // dispatch.contentModel.importPastTickets(payload)
        }
      } catch (err) {
        dispatch.contentModel.updateContentLoading(false)
        alert('Failed')
      }
    },
    topIntentsQuery: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateTopIntents(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    getSubscriptionDetails: async () => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await getSubscriptionDetails()
        dispatch.contentModel.updateSubscriptionDetails(response?.data?.data?.subscription_key?.PrimaryKey)
        dispatch.contentModel.updateContentLoading(false)
      }
      catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to get subscription details ',
          type: 'failed',
        })
      }
    },
    topIncreasedIntenetQuery: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateTopIncreasedIntents(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    topDecreasedIntentQuery: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateTopDecreasedIntents(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    totalIntentsCountQuery: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateTotalIntents(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    ticketsBasedonIntent: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateTicketsBasedonIntent(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    IntentsGraphData: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateIntentsGraphData(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    IntentsLists: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateIntentsList(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    TimeToResponse: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateTimeResponse(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    TimeToResolve: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateTimeResolve(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    TicketEscallation: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateTicketEscallation(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    FirstContactResolution: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateFirstContactResolution(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    CommonTags: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateCommonTags(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    listAllIntents: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await controlRoomApi(payload)
        dispatch.contentModel.updateListAllIntents(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
          type: 'failed',
        })
      }
    },
    deleteTicketsFaqPt: async ({ rowId, type }: any) => {
      try{
        const response = await deleteTicketsFaqPt(rowId, type)
      }catch(error){
        dispatch.contentModel.updateContentLoading(false)
      }
    },
    testChatBot: async (payload: any) => {

      try{
        dispatch.contentModel.updateContentLoading(true)
        const response = await testChatBot(payload)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.contentModel.updateChatMessage({
          isSent: false,
          text: response?.data?.response,
          liked: false,
          disliked: false,
          knowledgeBase: response?.data?.docs_metadata
        })
        dispatch.contentModel.updateChatBotHistory(response?.data?.history)

      }catch(error){
        dispatch.contentModel.updateContentLoading(false)
        console.log('error', error);
        
      }

    },
    controlroomFilerValues: async (payload: any) => {
      dispatch.contentModel.updateControlroomFilerValues(payload)

    },
    testChatBotAdithyaBirla: async (payload: any) => {
      try{
        dispatch.contentModel.updateContentLoading(true)

      const response = await testChatBotAdithyaBirla(payload)
      dispatch.contentModel.updateContentLoading(false)

      dispatch.contentModel.updateChatMessage({
        isSent: false,
        text: response?.data?.response,
        liked: false,
        disliked: false,
        knowledgeBase: response?.data?.docs_metadata

      })
      dispatch.contentModel.updateChatBotHistory(response?.data?.history)
      }catch(error){
        console.log('error', error);
        dispatch.contentModel.updateContentLoading(false)
      }

    },
    appInsights: async (payload: any) => {
      try {
        dispatch.contentModel.updateAppInsightsList([])
        dispatch.contentModel.updateContentLoading(true)
        const response = await appInsights(payload)
        dispatch.contentModel.updateAppInsightsList(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch details',
        })
      }
    },
    appInsightsWhole :async (payload: any) => {
      try {
        dispatch.contentModel.updateAppInsightsList([])
        dispatch.contentModel.updateContentLoading(true)
        const response = await appInsightsWhole(payload)
        dispatch.contentModel.updateAppInsightsList(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To fetch appinsights ',
        })
      }
    },
    listWidgets: async () => {
      try {
        const response = await listWidgets()
        dispatch.contentModel.updateWidgetList(response?.data?.data)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list widgets',
          type: 'failed',
        })
      }
    },
    getBotDetails: async (botId: any) => {
      try {
        // dispatch.contentModel.updateContentLoading(true)        
        const response = await getBotDetails(botId)
        dispatch.contentModel.updateBotDetails(response?.data?.data)
        // dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to get bot details ',
        })
      }
    },
    listWidgetTypes: async () => {
      try {
        const response = await listWidgetTypes()
        dispatch.contentModel.updateWidgetTypes(response?.data?.data)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list widget types',
          type: 'failed',
        })
      }
    },
    updateBot: async ({payload, type}) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await updateBot(payload)
        dispatch.contentModel.updateContentLoading(false)
       if(!type){
         dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Bot updated sucessfully',
          type: 'success',
        })
       }
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to update bot ',
        })
      }
    },
    createWidget: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await createWidget(payload)
        dispatch.contentModel.updateWidgetDetails(response?.data?.data)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Widget saved succesfully',
          type: 'success',
        })
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list widget types',
          type: 'failed',
        })
        dispatch.contentModel.updateContentLoading(false)
      }
    },
    updateWidget: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await updateWidget(payload)
        dispatch.contentModel.updateWidgetDetails(response?.data?.data)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Widget updated succesfully',
          type: 'success',
        })
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list widget types',
          type: 'failed',
        })
        dispatch.contentModel.updateContentLoading(false)
      }
    },
    testWidget: async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await testWidget(payload)
        dispatch.contentModel.updateChatMessage({
          isSent: false,
          text: response?.data?.data,
          knowledgeBase: response?.data?.data?.docs_metadata
        })
        dispatch.contentModel.updateContentLoading(false)
        // dispatch.contentModel.updateWidgetChat(response?.data?.data)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list widget types',
          type: 'failed',
        })
      }
    },
    testAutoSolveBot: async ({ payload, errorMessage }: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)

        const response = await testBot(payload)
        dispatch.contentModel.updateContentLoading(false)
        const time = getCurrentTimeChatBot()
        if (response?.data?.status === 200) {
          dispatch.contentModel.updateChatMessage({
            isSent: false,
            text: response?.data?.data?.response,
            liked: false,
            disliked: false,
            knowledgeBase: response?.data?.data?.docs_metadata,
            time: time

          })
        }
        else {
          dispatch.contentModel.updateChatMessage({
            isSent: false,
            text: errorMessage ? errorMessage : "Oops I’m sorry I couldn’t generate response for that.",
            liked: false,
            disliked: false,
            knowledgeBase: response?.data?.docs_metadata,
            time: time
          })
          dispatch.contentModel.updateContentLoading(false)
        }
      }
      catch (error) {
        const time = getCurrentTimeChatBot()
        dispatch.contentModel.updateChatMessage({
          isSent: false,
          text: errorMessage ? errorMessage : "Oops I’m sorry I couldn’t generate response for that.",
          liked: false,
          disliked: false,
          // knowledgeBase: response?.data?.docs_metadata,
          time: time
        })
        dispatch.contentModel.updateContentLoading(false)
      }
      // dispatch.contentModel.updateChatBotHistory(response?.data?.history)
    },
    getAgentList: async (payload: any) => {

      const { tenant_feature_id, app_feature_id } = payload;
      try {
        const response = await getAgentList(tenant_feature_id, app_feature_id)
        dispatch.contentModel.updateAgentList(response?.data?.data)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list agents',
          type: 'failed',
        })
      }
    },
    getInstallationList: async () => {
      const response = await  getInstallationList()
      dispatch.contentModel.updateListInstallation(response?.data?.data)
    },
    createAgent: async (payload: any) => {

      try{
        dispatch.contentModel.updateContentLoading(true)
        const responseAgent = await createAgent(payload)
        dispatch.contentModel.updateContentLoading(false)
        dispatch.contentModel.updateAgent(responseAgent?.data?.data)
      }catch(error){
        dispatch.contentModel.updateContentLoading(false)
        console.log('error', error);
      }


    },
    createBot: async (payload: any) => {
      try{
        const response = await createBot(payload)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Bot created successfully',
          type: 'success',
        }) 
      }catch{
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To update bot',
          type: 'failed',
        }) 
      }
      
    },
    updateAgentValues: async (payload: any) => {
      try{
      const response = await updateAgentApi(payload)
      dispatch.contentModel.updateAgent(response?.data?.data)
      dispatch.commonModel.updateNotificationState({
        message: 'Success',
        description: 'Agent updated successfully',
        type: 'success',
      }) 
      }
      catch(error){
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To update agents',
          type: 'failed',
        })      }
    },
    deleteAgent: async (payload: any) => {
      try{
        const response = deleteAgent(payload)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Agent deleted successfully',
          type: 'success',
        }) 
      }catch(error) {
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To delete agents',
          type: 'failed',
        })
      }
      
    },
    deleteWidget: async (payload: any) => {
      try {
        const response = await deleteWidget(payload)
        if (response?.data?.status === 200) {
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Widget deleted succesfully',
          type: 'success',
        })}
        else{
          dispatch.commonModel.updateNotificationState({
            message: 'Failed',
            description: response.message,
            type: 'failed',
          })
        }
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed to delete widget',
          type: 'failed',
        })
      }
    },
    getWidgetDetails: async (payload: any) => {
      try {
        const response = await getWidgetDetails(payload)
        dispatch.contentModel.updateWidgetDetails(response?.data?.data)
        dispatch.commonModel.updateNotificationState({
          message: 'Success',
          description: 'Widget details fetched succesfully',
          type: 'success',
        })
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list widget types',
          type: 'failed',
        })
      }
    },
    getBotTestHistoryWebFormat: async ({ bot_id, id,headers }: any) => {
      console.log("headers",headers)
      try {
        dispatch.contentModel.updateChatBotHistoryLoader(true);

        const url = `${headers?.domain}/ai-bot/v1/bot/test_history/${bot_id}?conversation_id=${id}`;
        try {
          const response = await axios.get(url, {
            headers: {
    "Wizr-Access-Key": headers?.wizr_access_key,
            "Tenant-Id": headers?.tenant_id,
            "User-Id" : id,
            "User-Name" : id
            },
          });
    // const response = await getBotTestHistory(bot_id,id)

          
          // const data = response?.data?.data?.filter((item:any) => item.user_id === id);
          const data = response?.data?.data;
          console.log("data",data)  
          console.log("response",response)  

          if (data && data.length > 0) {
            data.map((item: any) => {
              const botInput = JSON.parse(item.bot_input);
              const botOutput = JSON.parse(item.bot_output);
              const sortedData = botOutput&& 
                botOutput?.docs_metadata 

                console.log("botOutput",botOutput?.docs_metadata)
              const array = {
                isSent: false,
                question: botInput?.user_query,
                text: botOutput?.agent_response
                  ? botOutput?.agent_response
                  : "No response",

                knowledgeBase: sortedData && sortedData.slice(0, 5),
                id: item?.history_id,
                history_id:item?.history_id

              };
              dispatch.contentModel.updateChatMessage({
                isSent: false,
                question: botInput?.user_query,
                text: botOutput?.agent_response
                  ? botOutput?.agent_response
                  : "No response",

                feedback: item?.feedback,
                knowledgeBase: sortedData && sortedData.slice(0, 5),
                id: item?.history_id,
                history_id:item?.history_id
              });
            });
          } else {
            console.log("No data found"); 
          }
          dispatch.contentModel.updateAppHistory(response?.data?.data);
        } catch (error) {
          console.error("Error in API call:");
          throw error;
        }
      } catch (err) {
        dispatch.commonModel.updateNotificationState({
          message: "Failed",
          description: "Error in API call",
          type: "failed",
        });
      }
      dispatch.contentModel.updateChatBotHistoryLoader(false);
    },
    saveFeedback: async ({payload,user_id,headers}:any) => {
      const url = `${headers?.domain}/ai-bot/v1/bot/feedback`;
      const response = await axios.post(url, payload, {
        headers: {
          "Wizr-Access-Key":headers?.wizr_access_key,
          "Tenant-Id": headers?.tenant_id,
          "User-Id" : user_id,
          "User-Name" : user_id
        },
        timeout: 600000,
      });
    },
    testApp: ({appPayload,headers}: any) => {
      dispatch.contentModel.updateCleanupResponse('')

      // const urlApp = `https://wizr-one-api-manager.azure-api.net/app-execute/${appPayload?.app_id}/execute`;
      const urlApp = `${headers?.domain}/app-execute/v1/${appPayload?.app_id}/execute`;
      axios
        .post(urlApp, appPayload, {
          headers: {
            "Wizr-Access-Key": headers?.wizr_access_key,
            "Tenant-Id": headers?.tenant_id,
            "User-Id":appPayload?.app_id,
            "User-name":appPayload?.app_id
            
          },
        })
        .then((response) => {
          if (response) {
            statusChecker(response.data.statusQueryGetUri)
              .then((result) => {
                const response = JSON.parse(result)
                dispatch.contentModel.updateCleanupResponse(result)

                const parsedResponse = JSON.parse(response)
                dispatch.contentModel.updateLearnMore(response)
                //learnMore = result;
              })
              .catch((error) => {
                console.error("Status Checker Error:", error);
              });
              dispatch.contentModel.updateTerminateUrl(response.data.terminatePostUri)
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      
      const statusChecker = async (url: string) => {
        const checkStatus = async (): Promise<any> => {
          const statusResponse = await fetch(url);
          const statusResponseJson = await statusResponse.json();
          if (
            statusResponseJson.runtimeStatus === "Running" ||
            statusResponseJson.runtimeStatus === "Pending"
          ) {
            await new Promise((resolve) => setTimeout(resolve, 5000));
            const value = await checkStatus();
            return value;
          } else {
            return statusResponseJson?.output;
          }
        };
        if (url) {
          const response = await checkStatus();
          return response;
        }
      };
    },
    getBotRunHistory: async (payload: any) => {
      try {

        dispatch.contentModel.updateContentLoading(true)
        const response = await getBotRunHistoryDetails(payload)
        dispatch.contentModel.updateAutosolveRunHistoryDetails(response?.data?.data)
        // dispatch.commonModel.updateNotificationState({
        //   message: 'Success',
        //   description: 'Conversation History fetched succesfully',
        //   type: 'success',
        // })
        dispatch.contentModel.updateContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list history',
          type: 'failed',
        })
      }
    },
    getBotRunHistoryofConversation: async (payload: any) => {
      try {
        const { bot_id, conversationId } = payload
        dispatch.contentModel.updateInnerContentLoading(true)
        const response = await getBotRunHistoryDetailsOfACoversation(bot_id, conversationId)
        dispatch.contentModel.updateAutosolveRunHistoryDetailsOfConversation(response?.data?.data)
        // dispatch.commonModel.updateNotificationState({
        //   message: 'Success',
        //   description: 'Conversation History fetched succesfully',
        //   type: 'success',
        // })
        dispatch.contentModel.updateInnerContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateInnerContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list history',
          type: 'failed',
        })
      }
    },
    getAgentConversationList: async (payload: any) => {
      try {
        dispatch.contentModel.updateInnerContentLoading(true)
        const response = await listAgentConversations(payload)
        dispatch.contentModel.updateAgentConversationList(response?.data?.data)
        // dispatch.commonModel.updateNotificationState({
        //   message: 'Success',
        //   description: 'Conversation History fetched succesfully',
        //   type: 'success',
        // })
        dispatch.contentModel.updateInnerContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateInnerContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list Agent Conversation history',
          type: 'failed',
        })
      }
    },
    getAgentAssistConversationDetail: async (payload: any) => {
      try {
        dispatch.contentModel.updateInnerContentLoading(true)
        const response = await getAgentConversationDetail(payload)
        dispatch.contentModel.updateAgentConversationDetail(response?.data?.data)
        // dispatch.commonModel.updateNotificationState({
        //   message: 'Success',
        //   description: 'Conversation History fetched succesfully',
        //   type: 'success',
        // })
        dispatch.contentModel.updateInnerContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateInnerContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list history',
          type: 'failed',
        })
      }
    },
    getAutosolveConversationDetail: async ({payload,filter}: any) => {
      try {
        dispatch.contentModel.updateInnerContentLoading(true)
        const response = await getAutosolveConversations(payload)
        if (filter){
          dispatch.contentModel.updateAutosolveConversationPagination(response?.data?.data?.conversation_list)
          dispatch.contentModel.updateTotalConversationCount(response?.data?.data?.total_count)
          dispatch.contentModel.updateTotalUpvoteCount(response?.data?.data?.upvote_total_count)
          dispatch.contentModel.updateTotalDownvoteCount(response?.data?.data?.downvote_total_count)
      }
        else{
          dispatch.contentModel.updateAutosolveConversation(response?.data?.data?.conversation_list)
          dispatch.contentModel.updateTotalConversationCount(response?.data?.data?.total_count)
          dispatch.contentModel.updateTotalUpvoteCount(response?.data?.data?.upvote_total_count)
          dispatch.contentModel.updateTotalDownvoteCount(response?.data?.data?.downvote_total_count)
      
        }
        // dispatch.commonModel.updateNotificationState({
        //   message: 'Success',
        //   description: 'Conversation History fetched succesfully',
        //   type: 'success',
        // })
        dispatch.contentModel.updateInnerContentLoading(false)
      } catch (error) {
        dispatch.contentModel.updateInnerContentLoading(false)
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To list history',
          type: 'failed',
        })
      }
    },
    generateCode:async (payload: any) => {
      try {
        dispatch.contentModel.updateContentLoading(true)
        const response = await generateCode(payload)
        dispatch.contentModel.updateGenerateCode(response?.data?.data)
        dispatch.contentModel.updateContentLoading(false)

      }
      catch (error){
        dispatch.commonModel.updateNotificationState({
          message: 'Failed',
          description: 'Failed To generate code',
          type: 'failed',
        })
      }
    },
    saveFeedbackAutoSolve: async (payload:any) => {
      // const domain = process.env.REACT_APP_API_AUTO_SOLVE_BOT_FEEDBACK;
      // const url:any = domain
      // const response = await axios.post(url, payload, {
      //   headers: {
      //     "Wizr-Access-Key":headers?.wizr_access_key,
      //     "Tenant-Id": headers?.tenant_id,
      //     "User-Id" : user_id,
      //     "User-Name" : user_id
      //   },
      //   timeout: 600000,
      // });
      const response = await saveFeedBackAutoSolve(payload)

    },
    downloadCsvReport: async (payload: any) => {
      const response = await downloadCsvReport(payload)
      const url = response?.data?.file_url
      console.log('url',url); 
      window.open(url)
    },
    getUserMappedRoles : async (role:any) => {
      try{
      dispatch.contentModel.updateContentLoading(true)
      dispatch.contentModel.updateUserMappedRoles('')
      const response = await getUserMappedRoles(role)
      dispatch.contentModel.updateUserMappedRoles(response?.data?.data?.role_count)
      dispatch.contentModel.updateContentLoading(false)

    }
    catch(error){
      dispatch.contentModel.updateContentLoading(false)

      dispatch.commonModel.updateNotificationState({
        message: 'Failed',
        description: 'Failed To get user roles',
        type: 'failed',
      })
    }
    }
  }),
})
