import { Dropdown } from 'antd'
import InfoIcon from '../../assets/icons/Info'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrInput from '../WizrInput'
import WizrText from '../WizrText'
import WizrTagInput from '../WizrTagInput'
import WizrButton from '../WizrButton'
import { useContentLoadingSelector, useappDataSelector } from '../../redux/selectors/content'
import { useEffect, useState } from 'react'
import {
  dispatchAppPublished,
  dispatchCreatePackage,
  dispatchPackageList,
  dispatchUpdatePackage,
} from '../../redux/actions/content'
interface IAddPackages {
    action?:any
    details?: any
    onCancel?:any
  }
  
  interface IAddPackagesContent {
    onEditAction: any
    onCopyAction: any
    onDeleteAction: any
    type:any
  }

const AddPackages = ({ action,details, onCancel }: IAddPackages): JSX.Element => {
  const loading = useContentLoadingSelector()
  const [packageName, setPackageName] = useState(details?.package_name?details?.package_name:"")
  const [appIds, setAppIds] = useState(details?.package_apps?details?.package_apps:[])


  const onSaveAction = async () => {
    
    const payload = {
      package_key: packageName?.replace(/\s+/g, '').toLowerCase(),
      package_name: packageName,
      package_description: '',
      package_apps: appIds,
      tenant_id: 'WIZR-BASIC-TENANT',
      status: 'active',
    }
    if (action === 'create') {
      onCancel()

      await dispatchCreatePackage(payload)
    } else {
        const { app_details,package_key, package_name, package_description, package_apps, tenant_id, status, ...restOfItem } = details;

        const payloadFinal = {...restOfItem,...payload}
        onCancel()

      await dispatchUpdatePackage(payloadFinal)
    }

    await dispatchPackageList()
  }
  const appData = useappDataSelector()

  // useEffect(() => {
  //   dispatchAppPublished()
  // }, [])
 
  const dropDownConverter = (value: string[]) => {
    const result =
      value?.length > 0 &&
      value?.map((item: any, key: number) => ({
        label: `${item?.app_name} -- ${item?.app_industry}`,
        value: item?.app_id,
      }))
    return result
  }

  return (
    <WizrFlexLayout background='white' style={{ padding: '10px 30px', gap: '20px' }}>
      <WizrFlexLayout background='white' style={{ gap: '15px' }}>
        <div style={{ gap: '5px', display: 'flex' }}>
          <WizrText type='body2'>Package Name</WizrText>
          <InfoIcon />
        </div>
        <WizrInput
          placeholder='Enter Packages '
          background={'#F2F4F9'}
          value={packageName}
          onChange={(value: any) => {
            setPackageName(value)
          }}
          disabled={action==="edit"}
        ></WizrInput>
      </WizrFlexLayout>
      <WizrFlexLayout background='white' style={{ gap: '15px' }}>
        <div style={{ gap: '5px', display: 'flex' }}>
          <WizrText type='body2'>Apps</WizrText>
          <InfoIcon />
        </div>
        <WizrTagInput
        placeHolder='Enter apps'
          value={appIds}
          onChange={(value: any) => {
            
            setAppIds(value)
          }}
          options={dropDownConverter(appData)}
        ></WizrTagInput>
      </WizrFlexLayout>

      <WizrFlexLayout justifyContent='flex-end' background='white' flexDirection='row'>
        <WizrButton
          type='text'
          text='Cancel'
          onClick={() => {
            onCancel()
          }}
          style={{borderRadius: '20px'}}
        />
        <WizrButton
          htmlType='submit'
          text='Save'
          onClick={() => {
            onSaveAction()
          }}
          disabled={loading}
          style={{borderRadius: '20px'}}
        />
      </WizrFlexLayout>
    </WizrFlexLayout>
  )
}
export default AddPackages
