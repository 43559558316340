import { Form } from 'antd'
import { useEffect, useState } from 'react'
import WizrInput from '../WizrInput'
import WizrTextArea from '../WizrTextArea'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'
import WizrFlexLayout from '../WizrFlexLayout'
import { styled } from 'styled-components'
import { useContentLoadingSelector } from '../../redux/selectors/content'

const StyledContainer = styled(WizrFlexLayout)`
  gap: 8px;
`

interface ICreateGroup {
  groupName?: string
  onFormSubmission: (params: { [key: string]: any }) => void
  onCancel: () => void
}

const CreateGroup = ({
  groupName,
  onFormSubmission,
  onCancel,
}: ICreateGroup): JSX.Element => {
  const [form] = Form.useForm()
const loading = useContentLoadingSelector()
  useEffect(() => {
    form.setFieldValue('currentGroupName', groupName)
  }, [groupName])
  return (
    <StyledContainer background='#FFF' justifyContent='space-evenly'>
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFormSubmission}
        onFinishFailed={() => null}
        autoComplete='off'
      >
        <Form.Item
          name='currentGroupName'
          rules={[{ required: true, message: 'Please Enter a Folder Name', whitespace: true }]}
        >
          <WizrInput
            placeholder='Enter Group Name'
            onChange={(value: string) => form.setFieldsValue({ currentGroupName: value })}
          />
        </Form.Item>
       
        <Form.Item>
          <WizrFlexSpacing
            justifyContent='flex-end'
            flexDirection='row'
            size='large'
            background='#FFF'
          >
            <WizrButton type='text' text='Cancel' onClick={onCancel} style={{ borderRadius: '20px' }}/>
            <WizrButton htmlType='submit' text={groupName ? 'Update' : 'Create'} disabled={loading} style={{ borderRadius: '20px' }}/>
          </WizrFlexSpacing>
        </Form.Item>
      </Form>
    </StyledContainer>
  )
}

export default CreateGroup
