import { styled } from 'styled-components'
import { useEffect, useState } from 'react'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrButton from '../WizrButton'
import WizrText from '../WizrText'
import { wizrAzureConnectorInstance } from '../../utils/wizrAzureConnector'
import { convertBytestoMB, addValueToLocalStorage, getValueFromLocalStorage } from '../../utils'
import { Progress } from 'antd'
import CloseIcon from '../../assets/icons/Close'
import RepeatIcon from '../../assets/icons/Repeat'
import DoneIcon from '../../assets/icons/Done'
import { dispatchListFiles, dispatchUploadFile, dispatchgenerateFileUpload } from '../../redux/actions/content'
import { useContentFilesSelector } from '../../redux/selectors/content'
import { dispatchNotificationState } from '../../redux/actions/common'
import jwt_decode from 'jwt-decode'


const StyledLayout = styled(WizrFlexLayout)`
  border-radius: 16px;
  padding: 5px;
  margin-bottom: 20px;
`

const StyledFileContainer = styled(WizrFlexLayout)`
  padding: 10px;
`
const TextContainer = styled(WizrFlexLayout)`
  width: 17vw;

  overflow: auto;
  white-space: normal;
`

interface IMultiUploadList {
  onCancel: () => void
  onDone: () => void
  filesList: Array<any>
  folderId: string
  withTags: boolean
  onFileDragged: () => void
  fileTags?: any
}

interface IFileContainer {
  file: any
  uploadStatus: any
}

const statusMapping: { [key: string]: any } = {
  error: <RepeatIcon />,
  uploading: <CloseIcon />,
  success: <DoneIcon />,
}

const FileContainer = ({ file, uploadStatus }: IFileContainer): JSX.Element => {
  return (
    <StyledFileContainer>
      <WizrFlexSpacing
        flexDirection='row'
        justifyContent='space-between'
        textAlign='center'
        size='large'
      >
        <TextContainer>
          <WizrText type='body2'>{file?.name}</WizrText>
        </TextContainer>
        <WizrText type='body2'>{convertBytestoMB(file?.size)}</WizrText>
        <WizrButton type='text' icon={statusMapping?.[file?.status]} text='' />
      </WizrFlexSpacing>
      <Progress percent={uploadStatus} showInfo={false} strokeColor='green' strokeWidth={3} />
    </StyledFileContainer>
  )
}

const MultiUploadList = ({
  filesList,
  fileTags = [],
  folderId,
  onDone,
  onCancel,
}: IMultiUploadList): JSX.Element | null => {
  const [filesToUpload, setFilesToUpload] = useState<Array<any>>([])
  const [filesUploadStatus, setFilesUploadStatus] = useState<any>({})
  const existingFilesData = useContentFilesSelector()
  const testFolderId = localStorage.getItem('folderId')

  const checkIfDuplicateFileExists = (filesToUpload: any[], existingFiles: any[]) => {
    const filecheckInfo: {
      duplicateFiles: string[]
      fileNamesToUpload: string[]
      filesToUpload: any[]
    } = {
      duplicateFiles: [],
      fileNamesToUpload: [],
      filesToUpload: [],
    }

    const existingFileNamesSet = new Set(
      existingFiles.map((existingItem) => existingItem.file_name),
    )

    filesToUpload.forEach((fileItem) => {
      if (existingFileNamesSet.has(fileItem.name)) {
        if (!filecheckInfo.duplicateFiles.includes(fileItem.name)) {
          filecheckInfo.duplicateFiles.push(fileItem.name)
        }
      } else {
        if (!filecheckInfo.fileNamesToUpload.includes(fileItem.name)) {
          filecheckInfo.filesToUpload.push(fileItem)
          filecheckInfo.fileNamesToUpload.push(fileItem.name)
        }
      }
    })

    return filecheckInfo
  }

  useEffect(() => {
    if (existingFilesData?.length) {
      const fileCheckResult = checkIfDuplicateFileExists(filesList, existingFilesData)
      if (fileCheckResult.duplicateFiles?.length) {
        setFilesToUpload([])
        dispatchNotificationState({
          message: 'Warning',
          description: `Duplicate File names ${fileCheckResult.duplicateFiles?.toString()} found.`,
          type: 'warning',
        })
      }
      if (fileCheckResult?.filesToUpload?.length) {
        setFilesToUpload(fileCheckResult.filesToUpload)
      }
      if (!fileCheckResult?.fileNamesToUpload?.length && fileCheckResult?.duplicateFiles?.length) {
        onCancel()
      }
    } else {
      setFilesToUpload(filesList)
    }
  }, [])

  const setUploadStartStatus = (fileList: any) => {
    const existingUploadMapping = getValueFromLocalStorage('filesToUpload')
    const currentFolderId: any = localStorage.getItem('folderId')
    const currentFolderUploadMap = existingUploadMapping?.[currentFolderId] || {}
    const fileMapping: { [key: string]: any } = { [currentFolderId]: currentFolderUploadMap }
    fileList.forEach(
      (fileInfo: any) => (fileMapping[currentFolderId][fileInfo?.name] = 'inprogress'),
    )
    addValueToLocalStorage('filesToUpload', { ...existingUploadMapping, ...fileMapping })
  }

  // useEffect(() => {
  //   const startAzureFileUpload = async () => {
  //     if (filesToUpload?.length) {
  //       setUploadStartStatus(filesToUpload)
  //       const onFileUploadProgress = (fileProgressDetail: any, fileName: any) => {
  //         setFilesUploadStatus((state: any) => ({
  //           ...state,
  //           [fileName]: fileProgressDetail,
  //         }))
  //       }

  //       // Iterate through the promises and await each one individually
  //       for (const file of filesToUpload) {
  //         try {
  //           await wizrAzureConnectorInstance.uploadFile(
  //             folderId || testFolderId,
  //             file,
  //             onFileUploadProgress,
  //             fileTags,
  //             'inbox',
  //           )
  //           if (testFolderId) {
  //             setTimeout(() => {
  //               dispatchListFiles(testFolderId)
  //             }, 3000)
  //           }
  //         } catch (error) {
  //           // Handle errors or rejections here
  //           console.error('An error occurred:', error)
  //         }
  //       }
  //       onCancel()
  //     }
  //   }
  //   startAzureFileUpload()
  // }, [filesToUpload])
  useEffect(()=>{
   const startAzureFileUpload = async () => {
      if (filesToUpload?.length) {
        setUploadStartStatus(filesToUpload)
        const onFileUploadProgress = (fileProgressDetail: any, fileName: any) => {
          setFilesUploadStatus((state: any) => ({
            ...state,
            [fileName]: fileProgressDetail,
          }))
        }

        // Iterate through the promises and await each one individually
        // for (const file of filesToUpload) {
          try {
            const timestamp = new Date().getTime()
            const payload = {
              container_id: 3,
              app_id: `${folderId || testFolderId}`,
              timestamp: timestamp,
              fileInfo: {fileList:filesToUpload}
            }

            const jwtDecodeData: { [key: string]: any } = jwt_decode(localStorage.id_token)
            const tagsAsString = fileTags.join(',')

            const metadata: any = {
              tags: tagsAsString,
              user_id: jwtDecodeData?.oid,
              user_name: jwtDecodeData?.name,
            }
      
            dispatchgenerateFileUpload(payload,metadata)
            if (testFolderId) {
              setTimeout(() => {
                dispatchListFiles(testFolderId)
              }, 3000)
            }
          } catch (error) {
            // Handle errors or rejections here
            console.error('An error occurred:', error)
          }
        
        onCancel()
      }
    }
    startAzureFileUpload()
  },[filesToUpload])

  const onFileDragged = (files: any) => setFilesToUpload([...filesToUpload, ...files.fileList])

  if (!filesList?.length) return null

  const onCancelModal = () => {
    setFilesToUpload([])
    onCancel()
  }

  const onDoneModal = () => {
    setFilesToUpload([])
    onDone()
  }

  return (
    <WizrFlexLayout background='#FFF'>
      {/* <WizrUploadContainer dragDropEnabled={true} onChange={onFileDragged}> */}
      <StyledLayout background='#F2F4F9' flex='none'>
        {filesToUpload?.map((fileInfo: any, key: number) => (
          <FileContainer
            key={key}
            file={fileInfo}
            uploadStatus={filesUploadStatus?.[fileInfo?.name]}
          />
        ))}
      </StyledLayout>
      {/* </WizrUploadContainer> */}
      <WizrFlexSpacing flexDirection='row' size='large' justifyContent='flex-end' background='#FFF'>
        <WizrButton type='text' text='Cancel' onClick={onCancelModal} style={{borderRadius: '20px'}}/>
        {/* <WizrButton text='Done' onClick={onDoneModal} /> */}
      </WizrFlexSpacing>
    </WizrFlexLayout>
  )
}

export default MultiUploadList
