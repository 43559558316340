import React from 'react'

interface test {
  fill?: string
}

const MoreIcon = ({ fill }: test): JSX.Element => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='More'>
        <g id='Vector'>
          <path
            id='Vector-11'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C6.53043 10 7.03914 10.2107 7.41421 10.5858C7.78929 10.9609 8 11.4696 8 12Z'
            stroke='#1D1F22'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            fill={fill}
          />
          <path
            id='Vector-12'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z'
            stroke='#1D1F22'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            fill={fill}
          />
          <path
            id='Vector-13'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20 12C20 13.1046 19.1046 14 18 14C16.8954 14 16 13.1046 16 12C16 10.8954 16.8954 10 18 10C19.1046 10 20 10.8954 20 12Z'
            stroke='#1D1F22'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}

export default MoreIcon
