import { styled } from 'styled-components'
import WizrText from '../WizrText'
import WizrButton from '../WizrButton'
import WizrFlexSpacing from '../WizrFlexSpacing'
import WizrFlexLayout from '../WizrFlexLayout'
import WizrTextArea from '../WizrTextArea'
import { useEffect, useState } from 'react'
import WizrDropDown from '../WizrDropDown'
import WizrInput from '../WizrInput'
import WizrTagInput from '../WizrTagInput'

interface IAddParameterForm {
  fileName?: string
  onSubmit?: any
  onCancel?: () => void
  parameterInput?: any
}

const StyledWizrFlexSpacing = styled(WizrFlexSpacing)`
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
`

const StyledWizrDropDown = styled(WizrDropDown)`
  height: 100px;
`
const InputContainer = styled(WizrFlexLayout)`
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-color: #fff;
`
const ModalContainer = styled(WizrFlexLayout)`
  gap: 10px;
`

const AddParameterForm = ({
  onCancel,
  onSubmit,
  parameterInput,
}: IAddParameterForm): JSX.Element => {
  const [type, setType] = useState<number>(0)
  const [parameterData, setParameterData] = useState<{
    parameterType: number
    name: string
    modalTags?: string[] | undefined
  }>({ parameterType: 0, name: '' })
  const parameterOptions = [
    {
      label: 'Short text',
      value: 1,
    },
    {
      label: 'Long Text',
      value: 2,
    },
    { label: 'File Upload', value: 3 },
    {
      label: 'Single select',
      value: 4,
    },
    {
      label: 'Multiselect',
      value: 5,
    },
  ]

  useEffect(() => {
    if (parameterInput) {
      setParameterData(parameterInput?.parameter)
      setType(parameterInput?.parameter?.parameterType)
    }
  }, [parameterInput])

  return (
    <ModalContainer
      flexDirection='coloumn'
      background='#FFF'
      justifyContent='space-evenly'
      alignItems='flex-start'
    >
      <InputContainer>
        <WizrText type='body2'>Parameter Type </WizrText>
        <StyledWizrDropDown
          onChange={(e: number) => {
            setType(e)
            setParameterData({ ...parameterData, parameterType: e })
          }}
          value={type !== 0 ? type : 'Select type'}
          options={parameterOptions}
          placeHolder='Select type'
        />
      </InputContainer>
      {type ? (
        <InputContainer>
          <WizrText type='body2'>Name </WizrText>
          <WizrInput
            value={parameterData.name}
            placeholder='Type Name here'
            onChange={(e: string) => setParameterData({ ...parameterData, name: e })}
          />
        </InputContainer>
      ) : (
        <></>
      )}
      {type > 3 ? (
        <InputContainer>
          <WizrText type='body2'>Select Options </WizrText>
          <WizrTagInput
            onChange={(e: string[]) => setParameterData({ ...parameterData, modalTags: e })}
            open={false}
            value={parameterData?.modalTags}
          />
        </InputContainer>
      ) : (
        <></>
      )}
      <StyledWizrFlexSpacing
        justifyContent='flex-end'
        flexDirection='row'
        size='large'
        background='#FFF'
      >
        <WizrButton type='text' text='Cancel' onClick={onCancel} style={{borderRadius: '20px'}}/>
        <WizrButton
          text={parameterInput ? 'Save' : 'Add'}
          onClick={() => onSubmit(parameterData)}
          style={{borderRadius: '20px'}}
        />
      </StyledWizrFlexSpacing>
    </ModalContainer>
  )
}

export default AddParameterForm
